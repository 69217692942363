/* main.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@media screen and (max-width: 370px) {
  .container {
    padding: 1;
  }
}

body {
  font-family: "Montserrat", sans-serif !important;
}

.pd-1 {
  padding: 10px;
}

.pd-2 {
  padding: 20px;
}

.pd-3 {
  padding: 30px;
}

.pd-4 {
  padding: 40px;
}

.car-info-ul li .content h6,
.car-info-ul li .content span {
  display: block;
}

.why-choose-area .choose-card {
  padding: 15px;
}

.address {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.address h5 {
  color: #00d264;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
}

.address img {
  height: 100%;
}

.section-title1 span {
  margin-bottom: 15px !important;
}

.section-title1 h2 {
  margin-bottom: 35px;
}

.why-choose-area {
  margin-bottom: 35px;
}

.login-form-flex {
  display: flex;
}

.login-image {
  padding: 10vw;
}

.faq-bottom-section {
  padding: 35px 10px;
}

.faq-bottom-section h4 {
  font-size: 20px;
  font-weight: 600;
}

.attachment-full {
  height: 650px !important;
}

.gallery-item img {
  height: 500px !important;
  width: 100% !important;
}

.background-ease-in {
  transition: transform 20000ms ease-out 0s, opacity 1000ms ease 0s;
  opacity: 1;
  transform: scale(1.2, 1.2);
  z-index: 2;
}

.register-form {
  display: flex;
}

/* .zoom-out-banner {
  -webkit-animation: zoomout 10s ease-in infinite;
  animation: zoomout 10s ease-in infinite;
  transition: all .5s ease-in-out;
  overflow: hidden;
} */

img.frame {
  box-sizing: border-box;
  position: relative;
  background: rgba(19, 20, 26, 0.9);
  background-image: linear-gradient(top right, #5d5d5b, #383838);
  padding: 12px;
  width: 220px;
  box-shadow: -1px 1px var(--blur) 1px rgba(0, 0, 0, 0.1),
    -2px 2px var(--blur) 1px rgba(0, 0, 0, 0.09),
    -3px 3px var(--blur) 1px rgba(0, 0, 0, 0.08),
    -4px 4px var(--blur) 1px rgba(0, 0, 0, 0.07),
    -5px 5px var(--blur) 1px rgba(0, 0, 0, 0.06),
    -6px 6px var(--blur) 1px rgba(0, 0, 0, 0.05),
    -7px 7px var(--blur) 1px rgba(0, 0, 0, 0.04),
    -8px 8px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -9px 9px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -10px 10px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -11px 11px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -12px 12px var(--blur) 1px rgba(0, 0, 0, 0.02),
    -13px 13px var(--blur) 1px rgba(0, 0, 0, 0.02),
    -14px 14px var(--blur) 1px rgba(0, 0, 0, 0.01),
    -15px 15px var(--blur) 1px rgba(0, 0, 0, 0.01),
    -16px 16px var(--blur) 1px rgba(0, 0, 0, 0.01);
}
.img.frame:before {
  content: " ";
  display: block;
  padding-bottom: 140%;
}

.custom-select {
  appearance: none; /* Hide default select styles */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  width: 200px;
}

.custom-option {
  display: flex;
  align-items: center;
}

.select-box {
  padding: 3rem;
  display: inline-block;
  background-color: #3a9c7d;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 3px 2px 5px #3a9c7d;
}

.fa {
  padding: 1rem 2rem;
  color: #000;
  font-weight: 500;
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.bodytype-heading {
  border: 1px solid;
  padding: 6px;
  border-radius: 4px;
  background: white;
  color: black;
}

.phone-input-container {
  display: flex;
  align-items: center;
}
.phone-input-container span {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-right: none;
  padding: 6px;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.phone-input-container input {
  border: 1px solid #ccc;
  padding: 6px;
  background: white;
  font-size: 16px;
  width: 100%;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px !important;
}

@media all and (max-width: 500px) {
  img.frame {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .heading-caption{
    width:70%
  }
  .banner-main-content-wrap {
    padding: 25px 0px !important;
  }

  .bodytype-car {
    width: 100% !important;
  }

  .register-form {
    display: block;
  }

  .home-inventory {
    margin-top: -100px !important;
  }

  .product-card .product-content .features {
    justify-content: space-between;
  }

  .product-search-area form {
    margin-top: 40px;
    border: 1px solid silver !important;
  }

  .table th:first-child {
    background-color: #fff !important;
    border-radius: 0px !important;
    position: relative!important;
  }


  .mobile-user-avatar {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .overflow-on-mobile {
    overflow-x: hidden !important;
  }

  .login-form-flex {
    display: block;
  }

  .login-image {
    padding: 20vw;
  }

  .vehicle-condition-tabs {
    display: block;
  }

  .why-choose-area .choose-card {
    margin-bottom: 15px;
  }

  .mobile-margin-1 {
    margin-top: 30px;
  }

  .mobile-margin-2 {
    margin-top: 20px;
  }

  footer .footer-widget .menu-container ul li a {
    padding: 0.15rem 0.15rem 0.15rem 0rem;
  }

  footer .footer-widget .widget-title {
    margin-bottom: 10px;
  }

  .home6-brand-section .our-activetis .single-activiti {
    margin-left: 17px;
  }
}

.inner-page-banner .banner-wrapper {
  padding: 0px 2%;
}

.inner-page-banner {
  background-image: none !important;
}

.product-card2 {
  margin: 5px;
}

.home2-testimonial-section .feedback-card {
  margin: 5px;
}

.menu-main-menu-container .active {
  /* background-color: #f0f0f0; */
  color: #d7b65d !important;
  font-weight: bold;
}

.car-category img {
  width: 50px !important;
  height: 50px !important;
}

.modal-single-car-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 7vw;
  overflow-x: auto;
}

.modal-single-car-height {
  height: 90vh;
  overflow-x: hidden !important;
}

.input:focus {
  outline: 2px solid #000 !important;
}

.form-control:focus {
  border-color: #000 !important;
  box-shadow: 0 0 0 0.1rem #000;
}

.add-product-form input {
  background: white;
  color: #000;
  border: 1px solid black;
  /* border-radius: 5px !important; */
}

.bodytype-car h1 {
  font-size: 14px;
  font-weight: 500;
}

.slider-arrow .slick-prev:before, .slick-next:before {
  color: #D7B65D !important;
}

.sticky-header {
  background-color: #000;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.header-bottom-margin {
  margin-top: 82px;
}

@media only screen and (max-width: 600px) {
  .header-bottom-margin {
    margin-top: 0px;
  }
}

.black-color-btn {
  color: #000 !important;
}

.bodytype-heading {
  font-size: 16px;
  font-weight: 500;
}

.add-product-form textarea {
  background: white;
  color: #000;
  border: 1px solid black;
  border-radius: 5px !important;
}

.add-product-form select {
  border: 1px solid black !important;
  border-radius: 5px !important;
}

.form-shadow {
  box-shadow: 2px 8px 15px 2px rgba(0, 24, 64, 0.12);
  border-radius: 8px;
}

.contact-us-page input {
  border: 1px solid grey;
  border-radius: 8px;
}

.contact-us-page {
  padding: 40px;
}

.contact-us-page h2 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.modal-single-car h2 {
  font-size: 20px;
  color: #fff;

  padding: 10px;
}

.modal-single-car {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.bodytype-car {
  background-color: white;
  border-radius: 5px;
  width: 30%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-single-car input {
  border: 1px solid #ced4da !important;
}

.StepButton-d2-0-2-8.active {
  background-color: #d7b65d !important;
}

.StepButton-d2-0-2-8.completed {
  background-color: black !important;
}

.StepButton-d6-0-2-18.active {
  background-color: #d7b65d !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  background-color: #d7b65d;
}

.modal-header button {
  background-color: #000;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
}

.modal button {
  margin-top: 10px;
}

.previous-btn {
  color: #fff;
  background-color: black;
  border: 1px solid #000;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 8px;
  margin-top: 20px;
}

.next-btn {
  color: #fff;
  background-color: black;
  border: 1px solid #000;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 8px;
  margin-top: 20px;
}

.save-btn {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  padding: 10px 40px;
  font-size: 18px;
  border-radius: 8px;
  margin-left: 20px;
  margin-top: 20px;
}

.vehicle-condition-section h4 {
  margin-bottom: 10px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.vehicle-condition-section {
  padding: 10px;
}

.vehicle-condition-tabs {
  display: flex;
  gap: 80px;
}

.condition-1 {
  display: flex;
  gap: 10px;
}

.filter-chips {
  overflow-x: scroll;
  max-width: 100%;
}

.filter-chips::-webkit-scrollbar {
  width: 0px;
}

.swiper-slide {
  /* padding: 0px 10px */
}

/* For all other screen sizes, display 3 items */
.slide {
  min-width: 33% !important;
}

/* For screens with width < 600px, display 2 items */
@media only screen and (max-width: 600px) {
  .slide {
    min-width: 50% !important;
  }

  .vehicle-condition-tabs {
    display: block;
  }
}

.single-banner-image {
  height: 300px;
  width: 100vw;
  object-fit: contain;
}

.single-banner-slide {
  height: 60vh;
  width: 100vw;
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  position: relative;
  z-index: 1;
}

.single-banner-slide span {
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
}

.single-banner-slide h2 {
  color: #fff;
  text-transform: capitalize;
  font-size: 40px;
}

.single-banner-slide::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
  width: 100%;
  height: 100%;
}

.single-banner-slide p {
  color: #fff;
  font-size: 15px;
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
}

.single-banner-slide a {
  color: #000;
  background: #fff;
  padding: 10px 45px;
  border-radius: 100px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 30px;
  transition: 0.3s;
}

.single-banner-slide a:hover {
  color: #fff;
  background: #000;
}

.owl-dots {
  display: none !important;
}

.all-banner-slide {
  display: inline-flex;
}

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

/* menu toggle */

.menu-toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-toggle::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 4px;
  background: #000;
  box-shadow: 0 8px 0 #000, 0 -8px 0 #000;
}

/* profile menu */

.profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  text-align: end;
}

.profile h3 {
  text-align: end;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 600;
}

.profile p {
  line-height: 1;
  font-size: 14px;
  opacity: 0.6;
}

.profile .img-box {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.thumbnail-slider-wrap .slick-track {
  display: inline-flex;
}

.profile .img-box img {
  position: absolute;
  top: 0;
  left: 0;
  right: 10;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* menu (the right one) */

.menu1 {
  /* position: absolute; */
  /* top: calc(100% + 24px);
    right: 16px;
    z-index: 20000000000000; */
  width: 190px;
  /* min-height: 100px;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
    /* opacity: 0; */
  /* transform: translateY(-10px); */
  /* visibility: hidden; */
  transition: 300ms;
}

.menu1::before {
  content: "";
  position: absolute;
  top: 10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.menu1.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

/* menu links */

.menu1 ul {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: #fff;
}

.menu1 ul li {
  list-style: none;
  width: 100% !important;
}

.menu1 ul li:hover {
  background: #eee;
}

.menu1 ul li a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  gap: 6px;
}

.menu1 ul li a i {
  font-size: 1.2em;
}

.prod-card .product-price {
  position: absolute;
  right: -1px;
  bottom: 0;
  z-index: 2;
  line-height: 1;
  display: flex;
  transition: 0.35s;
}

.prod-card .product-price span {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  background-color: var(--white-color);
  padding: 4px 23px;
  transition: 0.35s;
}

.prod-card .product-price span del {
  font-size: 80%;
  opacity: 0.5;
}

.drawer-mobile {
  background-color: rgb(42, 43, 48);
}

.EZDrawer .EZDrawer__container {
  background-color: rgb(42, 43, 48) !important;
}

.outlined-btn {
  position: relative;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: var(--primary-color1);
  padding: 9px 26px; /* Adjusted padding for border thickness */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  transition: 0.5s;
  overflow: hidden;
  z-index: 1;
}

.EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container {
  visibility: hidden !important;
}

/* Hover effect for outlined button */
.outlined-btn:hover {
  background-color: var(--primary-color1);
  color: rgba(19, 20, 26, 0.9);
  cursor: pointer;
}
/*===========================
     01.COMMON CSS 
===========================*/
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

:root {
  --font-montserrat: "Montserrat", sans-serif;
  --font-open-sans: "Open Sans", sans-serif;
  --white-color: #fff;
  --black-color: #000;
  --black-color2: #1D1D1D;
  --title-color: rgba(19,20,26,0.9);
  --text-color: #5E5E5E;
  --primary-color1: #D7B65D;
}

/*================================================
1. Mixins Css
=================================================*/
body.logged-in.admin-bar header.sticky {
  top: 32px;
}

@media only screen and (max-width: 780px) {
  body.logged-in.admin-bar header.sticky {
    top: 32px;
  }

  .header-bottom-margin {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 575px) {
  body.logged-in.admin-bar header.sticky {
    top: 0;
  }
}
.elementor-control-unit-2 {
  width: 100px;
}

/*================================================
2. Global Css
=================================================*/
html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: var(--font-montserrat);
  overflow-x: hidden;
  color: var(--title-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
body.home-5 {
  padding: 0px 6.3%;
}
@media (max-width: 1699px) {
  body.home-5 {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media (max-width: 1399px) {
  body.home-5 {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media (max-width: 576px) {
  body.home-5 {
    padding-left: 0%;
    padding-right: 0%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  line-height: 1.4;
  color: var(--title-color);
}

input {
  border: none;
  outline: none;
}

button {
  outline: none;
  border: none;
}

i.bx {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

p {
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.sec-mar {
  margin: 100px 0;
}
@media (max-width: 991px) {
  .sec-mar {
    margin: 8ch 0;
  }
}

.sec-pad {
  padding: 100px 0;
}
@media (max-width: 991px) {
  .sec-pad {
    padding: 80px;
  }
}

.pt-120 {
  padding-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 100px;
  }
}
@media (max-width: 991px) {
  .pt-120 {
    padding-top: 90px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

.pt-100 {
  padding-top: 100px;
}
@media (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

.pt-90 {
  padding-top: 90px;
}
@media (max-width: 991px) {
  .pt-90 {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .pt-90 {
    padding-top: 70px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}
@media (max-width: 991px) {
  .pb-90 {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .pb-90 {
    padding-bottom: 70px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-80 {
    padding-bottom: 60px;
  }
}

.pb-65 {
  padding-bottom: 65px;
}

.mt-120 {
  margin-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mt-120 {
    margin-top: 100px;
  }
}
@media (max-width: 991px) {
  .mt-120 {
    margin-top: 90px;
  }
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-100 {
  margin-bottom: 100px;
}
@media (max-width: 991px) {
  .mb-100 {
    margin-bottom: 80px;
  }
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 991px) {
  .mt-100 {
    margin-top: 80px !important;
  }
}

.mb-90 {
  margin-bottom: 90px;
}
@media (max-width: 991px) {
  .mb-90 {
    margin-bottom: 70px;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pe-80 {
  padding-right: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-110 {
  padding-left: 110px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .pl-110 {
    padding-left: 70px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .pl-110 {
    padding-left: 40px;
  }
}
@media (max-width: 1199px) {
  .pl-110 {
    padding-left: unset;
  }
}

.mb-60 {
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .mb-60 {
    margin-bottom: 40px;
  }
}

.mb-70 {
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  .mb-70 {
    margin-bottom: 40px;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-44 {
  margin-bottom: 44px;
}
@media (max-width: 991px) {
  .mb-44 {
    margin-bottom: 0px;
  }
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-60 {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .mt-60 {
    margin-top: 40px;
  }
}

.mt-70 {
  margin-top: 70px;
}
@media (max-width: 767px) {
  .mt-70 {
    margin-top: 40px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: unset;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.section-title1 span {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.2em;
  color: var(--primary-color1);
  display: inline-block;
  margin-bottom: 5px;
}
@media (max-width: 576px) {
  .section-title1 span {
    font-size: 16px;
  }
}
.section-title1 h2 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 40px;
  line-height: 1.3;
  color: var(--title-color);
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .section-title1 h2 {
    font-size: 38px;
  }
}
@media (max-width: 1399px) {
  .section-title1 h2 {
    font-size: 38px;
  }
}
@media (max-width: 576px) {
  .section-title1 h2 {
    font-size: 30px;
  }
}

.section-title-2 h2 {
  color: var(--title-color);
  font-size: 40px;
  font-family: var(--font-montserrat);
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 1.3;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .section-title-2 h2 {
    font-size: 38px;
  }
}
@media (max-width: 1399px) {
  .section-title-2 h2 {
    font-size: 38px;
  }
}
@media (max-width: 576px) {
  .section-title-2 h2 {
    font-size: 30px;
  }
}
.section-title-2 p {
  color: #5E5E5E;
  margin-bottom: 0;
}

.primary-btn1 {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  padding: 12px 20px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.primary-btn1 svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.primary-btn1::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn1:hover {
  color: var(--white-color);
}
.primary-btn1:hover svg {
  fill: var(--white-color);
}
.primary-btn1:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.primary-btn2 {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  white-space: nowrap;
  padding: 10px 20px;
  display: inline-flex;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.primary-btn2 svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.primary-btn2::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn2:hover {
  color: var(--white-color);
}
.primary-btn2:hover svg {
  fill: var(--white-color);
}
.primary-btn2:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.primary-btn3 {
  position: relative;
  border-radius: 5px;
  background-color: var(--primary-color1)!important;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  padding: 11px 28px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  transition: 0.5s;
  overflow: hidden;
  z-index: 1;
}
.primary-btn3 svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.primary-btn3::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #D7B65D;
  opacity: 0.7;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn3.two {
  width: 100%;
  justify-content: center;
}
.primary-btn3.two svg path {
  transition: 0.5s;
}
.primary-btn3.two svg path:first-child {
  transition: 0.5s;
  fill: none;
  stroke: var(--title-color);
}
.primary-btn3:hover {
  color: var(--white-color);
}
.primary-btn3:hover svg {
  fill: var(--white-color);
}
.primary-btn3:hover.two svg path:first-child {
  fill: none;
  stroke: var(--white-color);
}
.primary-btn3:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.primary-btn6 {
  border-radius: 5px;
  background-color: var(--white-color);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  padding: 11px 28px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid rgba(29, 29, 29, 0.3);
}
.primary-btn6 svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.primary-btn6::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn6:hover {
  color: var(--white-color);
}
.primary-btn6:hover svg {
  fill: var(--white-color);
}
.primary-btn6:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.primary-btn7 {
  border-radius: 5px;
  background-color: var(--title-color);
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--white-color);
  padding: 8px 28px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid rgba(29, 29, 29, 0.3);
}
.primary-btn7 svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.primary-btn7::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--white-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn7:hover {
  border-color: var(--title-color);
  color: var(--title-color);
}
.primary-btn7:hover svg {
  fill: var(--title-color);
}
.primary-btn7:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

.view-btn {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  position: relative;
  display: inline-block;
  padding: 3px 12px;
  transition: 0.5s;
}
.view-btn::after {
  content: "";
  border: 1px solid var(--primary-color1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(-5deg);
  transition: 0.5s;
}
.view-btn:hover {
  color: var(--primary-color1);
}
.view-btn:hover::after {
  content: "";
  transform: rotate(0deg);
}

.select {
  margin-bottom: 25px;
}

.nice-select {
  width: 100%;
  background: #F3F3F3;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0px 25px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #F3F3F3;
}
.nice-select::after {
  height: 8px;
  width: 8px;
  border-bottom: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  right: 28px;
  margin-top: -5px;
}
.nice-select .current {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
}
.nice-select:hover {
  border-color: #F3F3F3;
  background: #F3F3F3;
}
.nice-select:focus {
  border-color: var(--primary-color1);
  background-color: var(--white-color);
}
.nice-select .list {
  border: none;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}
.nice-select .list::-webkit-scrollbar {
  width: 5px;
}
.nice-select .list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.nice-select .list::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
.nice-select .list .option {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nice-select .list .option:hover {
  color: var(--title-color);
  background-color: transparent;
  font-weight: 500;
}
.nice-select .list .option.selected {
  font-weight: 600;
}

.preloader-close-btn {
  position: absolute;
  right: 20px;
  top: 30px;
  font-size: 16px;
  transition: ease-in-out 0.5s linear;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
}

.egns-preloader {
  min-width: 100%;
  min-height: 100%;
  position: fixed !important;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
.egns-preloader.close {
  display: none;
  visibility: hidden;
}

.egns-preloader::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: -1;
}

.circle-border {
  width: 100%;
  max-width: 140px;
  height: 100%;
  max-height: 140px;
  border: 0 solid var(--primary-color1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  border-radius: 50%;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
}

.circle-border svg {
  fill: var(--primary-color1);
}

.circle-border svg path {
  stroke: var(--primary-color1);
}

.circle-border .moving-circle {
  width: 10px;
  height: 10px;
  background: var(--primary-color1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 2s linear infinite;
}

.circle-border .moving-circle:nth-child(2) {
  width: 10px;
  height: 10px;
  background: var(--primary-color1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 2.25s linear infinite;
}

.circle-border .moving-circle:nth-child(3) {
  width: 10px;
  height: 10px;
  background: var(--primary-color1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 2.5s linear infinite;
}

@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-70px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-70px) rotate(-360deg);
  }
}
#outline {
  stroke-dasharray: 2.42777px, 242.77666px;
  stroke-dashoffset: 0;
  animation: anim 1.6s linear infinite;
}

@keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
/*=====================================
     3. Topbar
========================================*/
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background-color: rgba(19,20,26,0.9);
  color:white;
  padding: 15px 11%;
  border-bottom: 1px solid #eee;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-bar {
    padding: 15px 3%;
  }
}
@media (max-width: 1399px) {
  .top-bar {
    padding: 15px 3%;
  }
}
@media (max-width: 991px) {
  .top-bar {
    display: none;
    visibility: hidden;
  }
}
.top-bar .search-area {
  max-width: 690px;
  width: 100%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-bar .search-area {
    max-width: 500px;
  }
}
@media (max-width: 1399px) {
  .top-bar .search-area {
    max-width: 500px;
  }
}
@media (max-width: 1199px) {
  .top-bar .search-area {
    max-width: 350px;
  }
}
.top-bar .search-area form {
  width: 100%;
}
.top-bar .search-area form .form-inner {
  display: flex;
  align-items: center;
  max-width: 690px;
  width: 100%;
}
.top-bar .search-area form .form-inner input {
  background: #F6F4Fa;
  border: 1px solid #EEEEEE;
  border-radius: 5px 0px 0px 5px;
  width: 100%;
  height: 52px;
  padding: 10px 30px;
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
}
.top-bar .search-area form .form-inner input:focus {
  background-color: var(--white-color);
  border-color: var(--primary-color1);
}
.top-bar .search-area form .form-inner button {
  width: 68px;
  height: 52px;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--title-color);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.top-bar .search-area form .form-inner button i {
  color: var(--white-color);
}
.top-bar .search-area form .form-inner button::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--primary-color1);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.top-bar .search-area form .form-inner button:hover {
  color: var(--white-color);
}
.top-bar .search-area form .form-inner button:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.top-bar .topbar-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  /* gap: 30px; */
}
.top-bar .topbar-right ul li {
  line-height: 1;
}
.top-bar .topbar-right ul li .sell-btn {
  -webkit-text-wrap: wrap; /* Safari */
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  background-color: transparent;
  transition: 0.35s;
  white-space: normal;
}
.top-bar .topbar-right ul li .sell-btn svg {
  fill: var(--primary-color1);
  transition: 0.35s;
}
.top-bar .topbar-right ul li .sell-btn.primary-btn1 svg {
  fill: var(--title-color);
}
.top-bar .topbar-right ul li .sell-btn:hover {
  color: var(--primary-color1);
}
.top-bar .topbar-right ul li .sell-btn:hover svg {
  fill: var(--primary-color1);
}
.top-bar .topbar-right ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  transition: 0.35s;
  text-transform: uppercase;
}
.top-bar .topbar-right ul li a svg {
  fill: var(--primary-color1);
  transition: 0.35s;
}
.top-bar .topbar-right ul li a.primary-btn1 svg {
  fill: var(--title-color);
}
.top-bar .topbar-right ul li a:hover {
  color: var(--primary-color1);
}
.top-bar .topbar-right ul li a:hover svg {
  fill: var(--primary-color1);
}
.top-bar.style-2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media (max-width: 1199px) {
  .top-bar.style-2 .top-bar-items {
    display: none;
    visibility: hidden;
  }
}
.top-bar.style-2 .top-bar-items ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
.top-bar.style-2 .top-bar-items ul li a {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--white-color);
  transition: 0.35s;
}
.top-bar.style-2 .top-bar-items ul li a:hover {
  color: var(--primary-color1);
}
.top-bar.style-2 .search-area {
  max-width: 401px;
  width: 100%;
}
.top-bar.style-2 .search-area .form-inner {
  max-width: 401px;
  width: 100%;
}
.top-bar.style-2 .search-area .form-inner input {
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.16);
  color: #FFFFFF;
  padding: 10px 20px;
}
.top-bar.style-2 .search-area .form-inner input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.top-bar.style-2 .search-area .form-inner input::placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.top-bar.style-2 .search-area .form-inner input:focus {
  background-color: transparent;
  border-color: var(--primary-color1);
}
.top-bar.style-2 .search-area .form-inner button {
  background-color: var(--primary-color1);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.top-bar.style-2 .search-area .form-inner button i {
  color: var(--title-color);
}
.top-bar.style-2 .search-area .form-inner button::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--black-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.top-bar.style-2 .search-area .form-inner button:hover {
  color: var(--white-color);
}
.top-bar.style-2 .search-area .form-inner button:hover i {
  color: var(--white-color);
}
.top-bar.style-2 .search-area .form-inner button:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@media (max-width: 1499px) {
  .top-bar.style-2 .topbar-right {
    display: none;
    visibility: hidden;
  }
}
.top-bar.style-2 .topbar-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.top-bar.style-2 .topbar-right .hotline-area .icon svg {
  fill: var(--white-color);
}
.top-bar.style-2 .topbar-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
.top-bar.style-2 .topbar-right .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5px;
  display: inline-block;
}
.top-bar.style-2 .topbar-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.top-bar.style-2 .topbar-right .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--white-color);
  transition: 0.5s;
}
.top-bar.style-2 .topbar-right .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
.top-bar.style-2 .topbar-right .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.top-bar.style-5 {
  padding: 15px 8%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-bar.style-5 {
    padding: 15px 3%;
  }
}
@media (max-width: 1399px) {
  .top-bar.style-5 {
    padding: 15px 3%;
  }
}
.top-bar.style-5 .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.top-bar.style-5 .hotline-area .content {
  position: relative;
  line-height: 1;
}
.top-bar.style-5 .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.top-bar.style-5 .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
.top-bar.style-5 .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.top-bar.style-5 .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
.top-bar.style-5 .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
.top-bar.style-5 .topbar-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
.top-bar.style-5 .topbar-right ul li {
  line-height: 1;
}
.top-bar.style-5 .topbar-right ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
}
.top-bar.style-5 .topbar-right ul li a svg {
  fill: var(--primary-color1);
}
.top-bar.style-5 .topbar-right ul li a.primary-btn1 svg {
  fill: var(--title-color);
}
.top-bar.style-5 .topbar-right ul li a:hover {
  color: var(--primary-color1);
}
.top-bar.style-5 .topbar-right ul li a:hover svg {
  fill: var(--primary-color1);
}
.top-bar.style-5 .topbar-right ul li button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
}
.top-bar.style-5 .topbar-right ul li button svg {
  fill: var(--primary-color1);
}
.top-bar.style-5 .topbar-right ul li button.primary-btn1 svg {
  fill: var(--title-color);
}
.top-bar.style-5 .topbar-right ul li button:hover {
  color: var(--primary-color1);
}
.top-bar.style-5 .topbar-right ul li button:hover svg {
  fill: var(--primary-color1);
}
.top-bar.style-5 .logo-and-menu {
  display: flex;
  align-items: center;
  gap: 70px;
}
@media (max-width: 1399px) {
  .top-bar.style-5 .logo-and-menu {
    gap: 30px;
  }
}
.top-bar.style-5 .logo-and-menu .menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1399px) {
  .top-bar.style-5 .logo-and-menu .menu ul {
    gap: 20px;
  }
}
.top-bar.style-5 .logo-and-menu .menu ul li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  transition: 0.35s;
}
.top-bar.style-5 .logo-and-menu .menu ul li a:hover {
  color: var(--primary-color1);
}
.top-bar.style-6 {
  padding: 15px 8%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-bar.style-6 {
    padding: 15px 3%;
  }
}
@media (max-width: 1399px) {
  .top-bar.style-6 {
    padding: 15px 3%;
  }
}
.top-bar.style-6 .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.top-bar.style-6 .hotline-area .content {
  position: relative;
  line-height: 1;
}
.top-bar.style-6 .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.top-bar.style-6 .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
.top-bar.style-6 .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.top-bar.style-6 .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
.top-bar.style-6 .topbar-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
.top-bar.style-6 .topbar-right ul li {
  line-height: 1;
}
.top-bar.style-6 .topbar-right ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
}
.top-bar.style-6 .topbar-right ul li a svg {
  fill: var(--title-color);
}
.top-bar.style-6 .topbar-right ul li a.primary-btn1 svg {
  fill: var(--title-color);
}
.top-bar.style-6 .topbar-right ul li button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
}
.top-bar.style-6 .topbar-right ul li button svg {
  fill: var(--title-color);
}
.top-bar.style-6 .topbar-right ul li button.primary-btn1 svg {
  fill: var(--title-color);
}
.top-bar.style-6 .logo-and-menu {
  display: flex;
  align-items: center;
  gap: 70px;
}
@media (max-width: 1399px) {
  .top-bar.style-6 .logo-and-menu {
    gap: 30px;
  }
}
.top-bar.style-6 .logo-and-menu .menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1399px) {
  .top-bar.style-6 .logo-and-menu .menu ul {
    gap: 20px;
  }
}
.top-bar.style-6 .logo-and-menu .menu ul li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  transition: 0.35s;
}
.top-bar.style-6 .logo-and-menu .menu ul li a:hover {
  color: var(--title-color);
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: 0;
  }
}

.signUp-modal .modal-dialog {
  max-width: 670px;
  width: 100%;
}
.signUp-modal .modal-dialog .modal-content {
  border-radius: 10px;
  border: none;
  padding: 70px 35px;
  background-color: var(--white-color);
  position: relative;
}
@media (max-width: 576px) {
  .signUp-modal .modal-dialog .modal-content {
    padding: 70px 20px;
  }
}
.signUp-modal .modal-dialog .modal-content .modal-header {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 35px;
}
.signUp-modal .modal-dialog .modal-content .modal-header .btn-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 72, 32, 0.14);
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  margin: 0;
  opacity: 1;
  background-image: none;
  line-height: 1;
  transition: 0.35s;
}
.signUp-modal .modal-dialog .modal-content .modal-header .btn-close i {
  color: #FF4820;
  font-size: 24px;
  line-height: 1;
  transition: 0.35s;
}
.signUp-modal .modal-dialog .modal-content .modal-header .btn-close:hover {
  background-color: #FF4820;
}
.signUp-modal .modal-dialog .modal-content .modal-header .btn-close:hover i {
  color: #fff;
}
.signUp-modal .modal-dialog .modal-content .modal-header h4 {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 700;
}
.signUp-modal .modal-dialog .modal-content .modal-header p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
}
.signUp-modal .modal-dialog .modal-content .modal-header p button {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
}
.signUp-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.signUp-modal .modal-dialog .modal-content .form-inner {
  position: relative;
}
.signUp-modal .modal-dialog .modal-content .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.signUp-modal .modal-dialog .modal-content .form-inner input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  width: 100%;
}
.signUp-modal .modal-dialog .modal-content .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.signUp-modal .modal-dialog .modal-content .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.signUp-modal .modal-dialog .modal-content .form-inner input:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.signUp-modal .modal-dialog .modal-content .form-inner i {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: var(--primary-color1);
  cursor: pointer;
}
.signUp-modal .modal-dialog .modal-content .form-inner .primary-btn2 {
  width: 100%;
  justify-content: center;
  text-align: center;
}
.signUp-modal .modal-dialog .modal-content .form-agreement input[type=checkbox] {
  display: none;
  visibility: hidden;
}
.signUp-modal .modal-dialog .modal-content .form-agreement label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.signUp-modal .modal-dialog .modal-content .form-agreement input[type=checkbox] + label:before {
  position: relative;
  content: "";
  -webkit-appearance: none;
  background: #FFFFFF;
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 5px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.signUp-modal .modal-dialog .modal-content .form-agreement input[type=checkbox]:checked + label:after {
  position: absolute;
  content: "";
  display: block;
  top: 11px;
  left: 4px;
  width: 4px;
  height: 8px;
  border: 1px solid var(--title-color);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.signUp-modal .modal-dialog .modal-content .form-agreement .forgot-pass {
  color: #2A64F8;
  font-family: var(--font-montserrat);
  font-size: 13px;
  font-weight: 600;
  transition: 0.35s;
}
.signUp-modal .modal-dialog .modal-content .form-agreement .forgot-pass:hover {
  color: var(--primary-color1);
}
.signUp-modal .modal-dialog .modal-content .terms-conditon {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding-top: 15px;
  margin-bottom: 50px;
}
.signUp-modal .modal-dialog .modal-content .terms-conditon a {
  font-weight: 600;
  color: var(--text-color);
  transform: 0.35s;
}
.signUp-modal .modal-dialog .modal-content .terms-conditon a:hover {
  color: var(--primary-color1);
}
.signUp-modal .modal-dialog .modal-content .terms-conditon.two {
  margin-bottom: 0;
}
.signUp-modal .modal-dialog .modal-content .terms-conditon.two p {
  margin-bottom: 0;
}
.signUp-modal .modal-dialog .modal-content .social-icon {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
.signUp-modal .modal-dialog .modal-content .social-icon li a {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  transition: 0.35s;
}
.signUp-modal .modal-dialog .modal-content .social-icon li a:hover {
  border-color: var(--primary-color1);
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .modal-header {
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0;
  border-bottom: none;
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .modal-header h4 {
  text-align: start;
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .form-inner {
  position: relative;
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .form-inner textarea {
  border-radius: 5px;
  border: 1px solid #EEE;
  outline: none;
  background: #FBF7FB;
  min-height: 100px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .form-inner textarea:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.signUp-modal.sell-with-us .modal-dialog .modal-content .form-inner .primary-btn2 {
  width: unset;
  justify-content: center;
  text-align: center;
  padding: 10px 36px;
}

.adSearch-modal .modal-dialog .modal-content {
  border-radius: 10px;
  border: none;
  padding: 70px 35px;
  background-color: var(--white-color);
  position: relative;
}
@media (max-width: 576px) {
  .adSearch-modal .modal-dialog .modal-content {
    padding: 70px 20px;
  }
}
.adSearch-modal .modal-dialog .modal-content .main-title {
  margin-bottom: 25px;
}
.adSearch-modal .modal-dialog .modal-content .btn-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 72, 32, 0.14);
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  margin: 0;
  opacity: 1;
  background-image: none;
  line-height: 1;
  transition: 0.35s;
}
.adSearch-modal .modal-dialog .modal-content .btn-close i {
  color: #FF4820;
  font-size: 24px;
  line-height: 1;
  transition: 0.35s;
}
.adSearch-modal .modal-dialog .modal-content .btn-close:hover {
  background-color: #FF4820;
}
.adSearch-modal .modal-dialog .modal-content .btn-close:hover i {
  color: #fff;
}
.adSearch-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.adSearch-modal .modal-dialog .modal-content .form-inner {
  position: relative;
}
.adSearch-modal .modal-dialog .modal-content .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.adSearch-modal .modal-dialog .modal-content .form-inner input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
}
.adSearch-modal .modal-dialog .modal-content .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.adSearch-modal .modal-dialog .modal-content .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.adSearch-modal .modal-dialog .modal-content .form-inner input:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.adSearch-modal .modal-dialog .modal-content .form-inner textarea {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
  min-height: 100px;
}
.adSearch-modal .modal-dialog .modal-content .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.adSearch-modal .modal-dialog .modal-content .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.adSearch-modal .modal-dialog .modal-content .form-inner textarea:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.adSearch-modal .modal-dialog .modal-content .form-inner i {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: var(--primary-color1);
  cursor: pointer;
}
.adSearch-modal .modal-dialog .modal-content .form-inner .primary-btn2 {
  width: 100%;
  justify-content: center;
  text-align: center;
}
.adSearch-modal .modal-dialog .modal-content .form-inner .primary-btn3 {
  padding: 10px 36px;
}
.adSearch-modal .modal-dialog .modal-content h5 {
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
}
.adSearch-modal .modal-dialog .modal-content .apply-btn {
  border-top: 1px solid #eee;
}
.adSearch-modal .modal-dialog .modal-content .apply-btn .primary-btn2 {
  padding: 10px 34px;
}

/*=====================================
     4. Header
========================================*/
header.style-1 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 11%;
  transition: all 0.8s ease-out 0s;
  border-bottom: 1px solid #eee;
  background-color: var(--white-color);
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-1 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-1 {
    padding: 0px 3%;
  }
}
header.style-1.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--white-color);
  z-index: 995;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-1 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-1 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
header.style-1 .menu-close-btn i {
  color: var(--white-color);
}
header.style-1.style-2 {
  background-color: transparent;
}
header.style-1.style-2 .menu-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
header.style-1.style-2 .menu-button svg {
  fill: var(--white-color);
}
header.style-1.style-2 .menu-button span {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--white-color);
}
header.style-1.style-2 .main-menu > ul li:hover i {
  color: var(--primary-color1);
  font-size: 20px;
}
header.style-1.style-2 .main-menu > ul li a {
  color: var(--white-color);
}
header.style-1.style-2 .main-menu > ul li.menu-item-has-children::after {
  color: var(--white-color);
}
header.style-1.style-2 .nav-right .modal-btn {
  color: var(--white-color);
  transition: 0.35s;
}
header.style-1.style-2 .nav-right .modal-btn:hover {
  color: var(--primary-color1);
}
header.style-1.style-2 .nav-right .header-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
header.style-1.style-2 .nav-right .header-right ul li {
  line-height: 1;
}
header.style-1.style-2 .nav-right .header-right ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--white-color);
  transition: 0.35s;
}
header.style-1.style-2 .nav-right .header-right ul li a:hover {
  color: var(--primary-color1);
}
header.style-1.style-2 .nav-right .header-right ul li a svg {
  fill: var(--primary-color1);
}
header.style-1.style-2 .nav-right .header-right ul li .primary-btn1 {
  color: var(--title-color);
}
header.style-1.style-2 .nav-right .header-right ul li .primary-btn1:hover {
  color: unset;
}
header.style-1.style-2 .nav-right .header-right ul li .primary-btn1 svg {
  fill: var(--title-color);
}
header.style-1 .nav-right {
  gap: 45px;
}
@media (max-width: 991px) {
  header.style-1 .nav-right {
    gap: 0px;
  }
}
header.style-1 .nav-right .modal-btn {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
  text-transform: uppercase;
}
header.style-1 .nav-right .modal-btn i {
  line-height: 1;
  transition: 0.35s;
}
header.style-1 .nav-right .modal-btn:hover {
  color: var(--primary-color1);
}
header.style-1 .nav-right .modal-btn:hover i {
  color: var(--primary-color1);
}
header.style-1 .nav-right .dropdown .cart-menu {
  min-width: 220px;
  background-color: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  border-radius: 0 0 10px 10px;
  position: absolute;
  right: 0;
  top: 45px;
  padding: 20px;
  z-index: 99;
  display: none;
  transform: scaleY(0);
  transform-origin: top;
  margin-right: -2.5rem;
}
header.style-1 .nav-right .dropdown .cart-menu.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
header.style-1 .nav-right .dropdown .cart-body {
  padding: 0px 0 20px;
}
header.style-1 .nav-right .dropdown .cart-body p.woocommerce-mini-cart__total.total {
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
}
header.style-1 .nav-right .dropdown .cart-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 215px;
  overflow-y: auto;
}
header.style-1 .nav-right .dropdown .cart-body ul::-webkit-scrollbar {
  width: 5px;
}
header.style-1 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
header.style-1 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.5s;
  font-weight: normal;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  border: 0;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn:hover {
  color: var(--primary-color1) !important;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
  display: inline-flex;
  gap: 15px;
  min-width: 290px;
  width: 100%;
  align-items: center;
}
@media (max-width: 767px) {
  header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
}
@media (max-width: 767px) {
  header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-1 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-1 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-1 .nav-right .dropdown .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-1 .nav-right .dropdown .cart-footer .pricing-area {
  width: 100%;
}
header.style-1 .nav-right .dropdown .cart-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 10px;
}
header.style-1 .nav-right .dropdown .cart-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-1 .nav-right .dropdown .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button {
  padding-top: 20px;
  width: 100%;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li {
  width: 100%;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  border: 1px solid transparent;
  padding: 8px 20px;
  display: flex;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li a svg {
  fill: var(--title-color);
  transition: 0.5s;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li a::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li a:hover {
  color: var(--white-color);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li a:hover svg {
  fill: var(--white-color);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a::after {
  background-color: var(--white-color);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover {
  color: var(--title-color);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover svg {
  fill: var(--title-color);
}
header.style-1 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-1 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-1 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-1 .nav-right .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-1 .nav-right .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: white;
  margin-bottom: 5px;
  display: inline-block;
}
header.style-1 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-1 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: silver;
  transition: 0.5s;
}
header.style-1 .nav-right .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
header.style-1 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
.mobile-searchbar {
  display: none;
}

.search-icon {
  display: none;
}
@media (max-width: 991px) {
  header.style-1 .nav-right .mobile-menu-btn {
    margin-top: 8px;
    width: 50px; 
    display: flex;
    margin-left: 15px;
    padding: 10px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
    margin-right: 10px;
  }



  .search-icon {
    display: block;
  }

  .search-icon .fa-search {
    padding: 0px !important;
    margin-top: 10px;
  }

  .mobile-searchbar {
    padding: 10px;
    background: rgba(19, 20, 26, 0.9);
    display: block;
    width: 100%;
  }
  header.style-1 .nav-right .mobile-menu-btn span {
    height: 2px;
    width: 25px;
    background-color: var(--white-color);
    display: flex;
    transition: transform 0.5s ease-in;
    position: absolute;
    top: 25px;
    right: 5px;
  }
  header.style-1 .nav-right .mobile-menu-btn span::before {
    transition-duration: 0.5s;
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: var(--white-color);
    content: "";
    top: -10px;
    right: 0;
  }
  header.style-1 .nav-right .mobile-menu-btn span::after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 2px;
    right: 0;
    background-color: var(--white-color);
    content: "";
    top: 10px;
  }
  header.style-1 .nav-right .mobile-menu-btn.active span {
    background-color: transparent;
  }
  header.style-1 .nav-right .mobile-menu-btn.active span::before {
    transform: rotateZ(45deg) translate(8px, 6px);
    width: 35px;
  }
  header.style-1 .nav-right .mobile-menu-btn.active span::after {
    transform: rotateZ(-45deg) translate(8px, -6px);
    width: 35px;
  }
}
header.style-1 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-1 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-1 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-1 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
header.style-1 .main-menu ul > li.current-menu-parent a, header.style-1 .main-menu ul > li.current-menu-item a, header.style-1 .main-menu ul > li.current-menu-ancestor a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-1 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li.position-inherit > a::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-1 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-1 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-1 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-1 .main-menu ul > li a {
  color: white;
  display: block;
  padding: 15px 20px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-1 .main-menu ul > li i {
  display: none;
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-1 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-1 .main-menu ul > li.menu-item-has-children i {
  display: block;
}
header.style-1 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-1 .main-menu ul > li ul.sub-menu > li i {
  display: none;
  position: absolute;
  color: var(--title-color);
  opacity: 1;
  top: 8px;
  font-size: 17px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li i {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-1 .main-menu ul > li ul.sub-menu > li.menu-item-has-children::after {
  display: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 15px;
  color: var(--text-color1);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, var(--primary-color1), #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-1 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #06D889, #ddd);
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children::after {
  display: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-1 .main-menu ul > li .mega-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  min-width: 1040px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  padding: 45px 40px;
  border-radius: 0 0 10px 10px;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li .mega-menu {
    min-width: 935px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li .mega-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding: 0;
    padding-left: 10px;
  }
}
header.style-1 .main-menu ul > li .mega-menu .menu-row {
  display: flex;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row {
    display: block;
  }
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
  display: block;
  padding: 0;
  padding-right: 70px;
  position: relative;
  flex-basis: 33.33%;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 55px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 0px;
    margin-bottom: 15px;
    flex-basis: unset;
  }
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
    margin-bottom: 0;
  }
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #eee;
  position: absolute;
  right: 27px;
  top: 0;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    right: 18px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    display: none;
    visibility: hidden;
  }
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  padding-right: 0;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child::after {
  display: none;
  visibility: hidden;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
    color: #b5b5b5;
    position: relative;
    padding-left: 15px;
  }
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6::before {
    height: 8px;
    width: 8px;
    background-color: #b5b5b5;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li {
  display: block;
  line-height: 1;
  padding: 0;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 1;
  padding: 12px 0;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a:hover {
  color: var(--primary-color1);
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgba(233, 228, 228, 0.5);
  }
  header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a img {
    display: none;
    visibility: hidden;
  }
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn {
  padding-top: 20px;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 7px;
  border-bottom: none;
  font-weight: 600;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: relative;
  right: unset;
  top: unset;
  cursor: pointer;
  display: flex;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover i {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 370px) {
  .elementor-section-wrap {
    padding-left: 10px;
  }
}
@media (min-width: 992px) {
  header.style-1 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}

@media (max-width: 1018px) {
  .login-form-flex {
    max-width: 40rem !important;
  }
  .login-image {
    padding: 8rem !important;
  }
  /* .inner-page-banner .banner-wrapper {
    padding: 0px 0% !important;
  } */
  /* .inner-page-banner .banner-wrapper {
    padding: 30px 2% !important;
  } */

  .product-search-area form {
    margin-top: 40px !important;
  }
  .avialable-cars {
    margin-top: -30px !important;
  }
}

.avialable-cars {
  margin-top: -50px;
}


@media (min-width: 992px) {
  header.style-1 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-1 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
  padding-left: 10px !important;
}
header.style-1 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-1 .main-menu .topbar-right {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-1 .main-menu .topbar-right a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
}
header.style-1 .main-menu .topbar-right a:last-child {
  margin-bottom: 0;
}
header.style-1 .main-menu .topbar-right a svg {
  fill: var(--primary-color1);
}
header.style-1 .main-menu .topbar-right a.primary-btn3 {
  justify-content: center;
}
header.style-1 .main-menu .topbar-right a.primary-btn3 svg {
  fill: var(--title-color);
}
header.style-1 .main-menu .topbar-right a:hover {
  color: var(--primary-color1);
}
header.style-1 .main-menu .topbar-right a:hover svg {
  fill: var(--primary-color1);
}
header.style-1 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}
header.style-1 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-1 .main-menu .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-1 .main-menu .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-1 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-1 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
header.style-1 .main-menu .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
@media only screen and (max-width: 991px) {
  header.style-1 .mobile-logo-area {
    justify-content: center !important;
  }
  header.style-1 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-1 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 280px; */
    padding: 10px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: none !important;
  }
  /* header.style-1 .main-menu.show-menu {
    transform: translateX(0);
  } */
  header.style-1 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-1 .main-menu .menu-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header.style-1 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 5px 0;
  }
  header.style-1 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-1 .main-menu ul li i {
    display: block;
  }
  header.style-1 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }

  .header.style-1 .main-menu ul li a:active {
    padding-left: 10px !important;
  }
  header.style-1 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-1 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-1 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

header.style-2 {
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 11%;
  transition: all 0.8s ease-out 0s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background-color: transparent;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-2 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-2 {
    padding: 0px 3%;
  }
}
@media (max-width: 1199px) {
  header.style-2 {
    padding: 13px 3%;
  }
}
header.style-2.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--title-color);
  z-index: 999;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-2 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-2 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
@media (max-width: 1199px) {
  header.style-2 .header-right {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .header-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
header.style-2 .header-right ul li {
  line-height: 1;
}
header.style-2 .header-right ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--white-color);
  transition: 0.35s;
  text-transform: uppercase;
}
header.style-2 .header-right ul li a:hover {
  color: var(--primary-color1);
}
header.style-2 .header-right ul li a svg {
  fill: var(--primary-color1);
}
header.style-2 .header-right ul li .primary-btn1 {
  color: var(--title-color);
}
header.style-2 .header-right ul li .primary-btn1 svg {
  fill: var(--title-color);
}
header.style-2 .header-right ul li .primary-btn1:hover {
  color: var(--white-color);
}
header.style-2 .header-right ul li .primary-btn1:hover svg {
  fill: var(--white-color);
}
header.style-2 .menu-close-btn i {
  color: var(--white-color);
}
header.style-2 .menu-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: 0.35s;
}
header.style-2 .menu-button svg {
  fill: var(--white-color);
  transition: 0.35s;
}
header.style-2 .menu-button span {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--white-color);
  transition: 0.35s;
}
header.style-2 .menu-button:hover svg {
  fill: var(--primary-color1);
}
header.style-2 .menu-button:hover span {
  color: var(--primary-color1);
}
header.style-2 .nav-right {
  gap: 45px;
}
@media (max-width: 1199px) {
  header.style-2 .nav-right {
    gap: 30px;
  }
}
header.style-2 .nav-right .modal-btn {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--white-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-2 .nav-right .modal-btn i {
  line-height: 1;
  transition: 0.35s;
}
header.style-2 .nav-right .modal-btn:hover {
  color: var(--primary-color1);
}
header.style-2 .nav-right .modal-btn:hover i {
  color: var(--primary-color1);
}
header.style-2 .nav-right .dropdown .cart-menu {
  min-height: 10rem;
  background-color: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  border-radius: 0 0 10px 10px;
  position: absolute;
  right: 0;
  top: 45px;
  padding: 20px;
  z-index: 99;
  display: none;
  min-width: 220px;
  transform: scaleY(0);
  transform-origin: top;
  margin-right: -2.5rem;
}
header.style-2 .nav-right .dropdown .cart-menu.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
header.style-2 .nav-right .dropdown .cart-body {
  padding: 0px 0 20px;
}
header.style-2 .nav-right .dropdown .cart-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 221px;
  overflow-y: auto;
}
header.style-2 .nav-right .dropdown .cart-body ul::-webkit-scrollbar {
  width: 5px;
}
header.style-2 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
header.style-2 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.5s;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
  display: inline-flex;
  gap: 15px;
  min-width: 290px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content .close-btn {
  background: transparent;
  transition: 0.5s;
  font-weight: normal;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  border: 0;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content .close-btn:hover {
  color: var(--primary-color1) !important;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-2 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-2 .nav-right .dropdown .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-2 .nav-right .dropdown .cart-footer .pricing-area {
  width: 100%;
}
header.style-2 .nav-right .dropdown .cart-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 10px;
}
header.style-2 .nav-right .dropdown .cart-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-2 .nav-right .dropdown .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button {
  padding-top: 20px;
  width: 100%;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li {
  width: 100%;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  border: 1px solid transparent;
  padding: 8px 20px;
  display: flex;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li a svg {
  fill: var(--title-color);
  transition: 0.5s;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li a::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li a:hover {
  color: var(--white-color);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li a:hover svg {
  fill: var(--white-color);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a::after {
  background-color: var(--white-color);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover {
  color: var(--title-color);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover svg {
  fill: var(--title-color);
}
header.style-2 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-2 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-2 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-2 .nav-right .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-2 .nav-right .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-2 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-2 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
header.style-2 .nav-right .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
header.style-2 .nav-right .modal-dialog {
  min-height: 100vh;
  max-width: 620px;
  margin: 0 0 0 auto;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog {
    max-width: unset;
    width: 100%;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content {
  padding: 65px 60px;
  min-height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog .modal-content {
    padding: 50px 20px;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-header {
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-header .modal-title {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 0;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-header .close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgba(255, 72, 32, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-header .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover {
  background: rgb(255, 72, 32);
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover i {
  color: var(--white-color);
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body {
  padding: 40px 0;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 500px;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
    gap: 10px;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn {
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  background: rgba(255, 72, 32, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover {
  background: rgb(255, 72, 32);
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover i {
  color: var(--white-color);
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 350px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
    gap: 12px;
    min-width: 100%;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
  background: #F3F3F3;
  border-radius: 5px;
  min-width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
    height: 60px;
    min-width: 55px;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  width: 55px;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  padding-right: 40px;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  color: var(--title-color);
  display: inline-block;
  margin-bottom: 3px;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area {
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 15px;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .footer-button {
  padding-top: 40px;
  width: 100%;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
@media (max-width: 576px) {
  header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
    flex-wrap: wrap;
    gap: 15px;
  }
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li {
  width: 100%;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
}
header.style-2 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
}
header.style-2 .main-menu {
  display: inline-block;
  position: relative;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-2 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-2 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
header.style-2 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-2 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: 14px;
  color: var(--white-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li.position-inherit > a::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-2 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-2 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-2 .main-menu ul > li a {
  color: var(--white-color);
  display: block;
  text-transform: capitalize;
  padding: 15px 20px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-2 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-2 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-2 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-2 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
  display: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-2 .main-menu ul > li ul.sub-menu > li.menu-item-has-children::after {
  display: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 7px;
  font-size: 14px;
  right: 10px;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
    right: 0;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 25px;
  color: var(--text-color1);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, #06D889, #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-2 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #06D889, #ddd);
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 910px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu {
    margin-left: 10px;
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children::after {
  display: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-2 .main-menu ul > li .mega-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  min-width: 1040px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  padding: 45px 40px;
  border-radius: 0 0 10px 10px;
  transform-origin: top;
}
@media (max-width: 1699px) {
  header.style-2 .main-menu ul > li .mega-menu {
    margin-left: -6rem;
  }
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu {
    min-width: 935px;
  }
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding: 0;
    padding-left: 10px;
  }
}
header.style-2 .main-menu ul > li .mega-menu .menu-row {
  display: flex;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu .menu-row {
    display: block;
  }
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
  display: block;
  padding: 0;
  padding-right: 60px;
  position: relative;
  flex-basis: 33.33%;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 0px;
    margin-bottom: 15px;
  }
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
    margin-bottom: 0;
  }
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #eee;
  position: absolute;
  right: 27px;
  top: 0;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    right: 18px;
  }
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  padding-right: 0;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child::after {
  display: none;
  visibility: hidden;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
    color: #b5b5b5;
    position: relative;
    padding-left: 15px;
  }
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6::before {
    height: 8px;
    width: 8px;
    background-color: #b5b5b5;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li {
  display: block;
  line-height: 1;
  padding: 0;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 1;
  padding: 12px 0;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a:hover {
  color: var(--primary-color1);
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgba(233, 228, 228, 0.5);
  }
  header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a img {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn {
  padding-top: 20px;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--title-color);
  border-bottom: none;
  font-weight: 600;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: relative;
  right: unset;
  top: unset;
  cursor: pointer;
  display: flex;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover i {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 1200px) {
  header.style-2 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-up 0.45s linear;
  }
}
@media (min-width: 1200px) {
  header.style-2 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-2 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
@media only screen and (max-width: 991px) {
  header.style-2 .mobile-logo-area {
    justify-content: center !important;
  }
  header.style-2 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-2 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-2 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-2 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-2 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  header.style-2 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-2 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-2 .main-menu ul li i {
    display: block;
  }
  header.style-2 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-2 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
  }
  header.style-2 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-2 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-2 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

header.style-3 {
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10%;
  transition: all 0.8s ease-out 0s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background-color: transparent;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-3 {
    padding: 0px 3%;
  }
}
@media (max-width: 1199px) {
  header.style-3 {
    padding: 0px 2%;
  }
}
header.style-3.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--title-color);
  z-index: 999;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-3 .menu-close-btn i {
  color: var(--white-color);
}
@media (max-width: 991px) {
  header.style-3 .header-logo {
    padding: 20px 0px;
  }
}
header.style-3 .nav-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1399px) {
  header.style-3 .nav-right ul {
    gap: 15px;
  }
}
header.style-3 .nav-right ul li {
  line-height: 1;
}
header.style-3 .nav-right ul li .search-btn span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--white-color);
  cursor: pointer;
}
header.style-3 .nav-right ul li .search-btn span svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right ul li .search-btn span.primary-btn1 {
  color: var(--title-color);
  border-radius: 20px;
}
header.style-3 .nav-right ul li .search-btn span.primary-btn1 svg {
  fill: var(--title-color);
}
header.style-3 .nav-right ul li .search-btn span:hover {
  color: var(--primary-color1);
}
header.style-3 .nav-right ul li .search-btn span:hover svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
}
header.style-3 .nav-right ul li a svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right ul li a.primary-btn1 {
  color: var(--title-color);
  border-radius: 20px;
}
header.style-3 .nav-right ul li a.primary-btn1 svg {
  fill: var(--title-color);
}
header.style-3 .nav-right ul li a:hover {
  color: var(--primary-color1);
}
header.style-3 .nav-right ul li a:hover svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right ul li button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--white-color);
  background-color: transparent;
}
header.style-3 .nav-right ul li button svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right ul li button.primary-btn1 {
  color: var(--title-color);
  background-color: var(--primary-color1);
  border-radius: 20px;
}
header.style-3 .nav-right ul li button.primary-btn1 svg {
  fill: var(--title-color);
}
header.style-3 .nav-right ul li button:hover {
  color: var(--primary-color1);
}
header.style-3 .nav-right ul li button:hover svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-3 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 50px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
  header.style-3 .nav-right .mobile-menu-btn span {
    height: 2px;
    width: 25px;
    background-color: var(--white-color);
    display: flex;
    transition: transform 0.5s ease-in;
    position: absolute;
    top: 0px;
  }
  header.style-3 .nav-right .mobile-menu-btn span::before {
    transition-duration: 0.5s;
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: var(--white-color);
    content: "";
    top: -10px;
    right: 0;
  }
  header.style-3 .nav-right .mobile-menu-btn span::after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 2px;
    right: 0;
    background-color: var(--white-color);
    content: "";
    top: 10px;
  }
  header.style-3 .nav-right .mobile-menu-btn.active span {
    background-color: transparent;
  }
  header.style-3 .nav-right .mobile-menu-btn.active span::before {
    transform: rotateZ(45deg) translate(8px, 6px);
    width: 35px;
  }
  header.style-3 .nav-right .mobile-menu-btn.active span::after {
    transform: rotateZ(-45deg) translate(8px, -6px);
    width: 35px;
  }
}
header.style-3 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-3 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-3 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-3 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
header.style-3 .main-menu ul > li.current-menu-parent a, header.style-3 .main-menu ul > li.current-menu-item a, header.style-3 .main-menu ul > li.current-menu-ancestor a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-3 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 24px;
  right: 2px;
  font-size: 14px;
  color: var(--white-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1399px) {
  header.style-3 .main-menu ul > li.position-inherit > a::after {
    right: -5px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-3 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-3 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-3 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-3 .main-menu ul > li a {
  color: var(--white-color);
  display: block;
  text-transform: capitalize;
  padding: 24px 20px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (max-width: 1399px) {
  header.style-3 .main-menu ul > li a {
    padding: 24px 12px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li a {
    padding: 32px 9px;
    color: var(--title-color);
  }
}
header.style-3 .main-menu ul > li i {
  display: none;
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-3 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-3 .main-menu ul > li.menu-item-has-children i {
  display: block;
}
header.style-3 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-3 .main-menu ul > li ul.sub-menu > li i {
  display: none;
  position: absolute;
  color: var(--title-color);
  opacity: 1;
  top: 8px;
  font-size: 17px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li i {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-3 .main-menu ul > li ul.sub-menu > li.menu-item-has-children:after {
  display: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 15px;
  color: var(--text-color1);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, #06D889, #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-3 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #06D889, #ddd);
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu {
    margin-left: 10px;
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children:after {
  display: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-3 .main-menu ul > li .mega-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  min-width: 1040px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  padding: 45px 40px;
  border-radius: 0 0 10px 10px;
  transform-origin: top;
}
@media (max-width: 1699px) {
  header.style-3 .main-menu ul > li .mega-menu {
    margin-left: -5rem;
  }
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li .mega-menu {
    min-width: 935px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li .mega-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding: 0;
    padding-left: 10px;
    margin-left: 0;
  }
}
header.style-3 .main-menu ul > li .mega-menu .menu-row {
  display: flex;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row {
    display: block;
  }
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
  display: block;
  padding: 0;
  padding-right: 70px;
  position: relative;
  flex-basis: 33.33%;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 55px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 0px;
    margin-bottom: 15px;
    flex-basis: unset;
  }
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
    margin-bottom: 0;
  }
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #eee;
  position: absolute;
  right: 27px;
  top: 0;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    right: 18px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    display: none;
    visibility: hidden;
  }
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  padding-right: 0;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child::after {
  display: none;
  visibility: hidden;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
    color: #b5b5b5;
    position: relative;
    padding-left: 15px;
  }
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6::before {
    height: 8px;
    width: 8px;
    background-color: #b5b5b5;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li {
  display: block;
  line-height: 1;
  padding: 0;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 1;
  padding: 12px 0;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a:hover {
  color: var(--primary-color1);
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgba(233, 228, 228, 0.5);
  }
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a img {
    display: none;
    visibility: hidden;
  }
  header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a:hover {
    padding-left: 10px;
  }
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn {
  padding-top: 20px;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--title-color);
  border-bottom: none;
  font-weight: 600;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a i {
  font-size: 20px;
  text-align: center;
  color: var(--title-color);
  font-style: normal;
  position: relative;
  right: unset;
  top: unset;
  cursor: pointer;
  display: flex;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover i {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 24px;
  right: 10px;
  font-size: 14px;
  color: var(--white-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1399px) {
  header.style-3 .main-menu ul > li.menu-item-has-children::after {
    right: 3px;
  }
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  header.style-3 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@media (min-width: 992px) {
  header.style-3 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
header.style-3 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-3 .main-menu .topbar-right {
  padding-top: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-3 .main-menu .topbar-right .primary-btn3 {
  justify-content: center;
  width: 100%;
}
header.style-3 .main-menu .topbar-right .primary-btn3 svg {
  fill: var(--title-color);
}
header.style-3 .main-menu .topbar-right a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
  text-transform: uppercase;
}
header.style-3 .main-menu .topbar-right a:last-child {
  margin-bottom: 0;
}
header.style-3 .main-menu .topbar-right a svg {
  fill: var(--primary-color1);
}
header.style-3 .main-menu .topbar-right a:hover {
  color: var(--primary-color1);
}
header.style-3 .main-menu .topbar-right a:hover svg {
  fill: var(--primary-color1);
}
header.style-3 .main-menu .topbar-right .sell-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
  background-color: transparent;
}
header.style-3 .main-menu .topbar-right .sell-btn:last-child {
  margin-bottom: 0;
}
header.style-3 .main-menu .topbar-right .sell-btn svg {
  fill: var(--primary-color1);
}
header.style-3 .main-menu .topbar-right .sell-btn.primary-btn3 {
  justify-content: center;
}
header.style-3 .main-menu .topbar-right .sell-btn.primary-btn3 svg {
  fill: var(--title-color);
}
header.style-3 .main-menu .topbar-right .sell-btn:hover {
  color: var(--primary-color1);
}
header.style-3 .main-menu .topbar-right .sell-btn:hover svg {
  fill: var(--primary-color1);
}
header.style-3 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}
header.style-3 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-3 .main-menu .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-3 .main-menu .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-3 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-3 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
header.style-3 .main-menu .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
@media only screen and (max-width: 991px) {
  header.style-3 .mobile-logo-area {
    justify-content: center !important;
  }
  header.style-3 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-3 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-3 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-3 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-3 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  header.style-3 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-3 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-3 .main-menu ul li i {
    display: block;
  }
  header.style-3 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-3 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
  }
  header.style-3 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-3 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-3 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

header.style-4 {
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 11%;
  transition: all 0.8s ease-out 0s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background-color: transparent;
  position: absolute;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-4 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-4 {
    padding: 0px 3%;
  }
}
header.style-4.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--title-color);
  z-index: 888;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-4 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-4 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
header.style-4 .header-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1199px) {
  header.style-4 .header-right ul {
    display: none;
    visibility: hidden;
  }
}
header.style-4 .header-right ul li {
  line-height: 1;
}
header.style-4 .header-right ul li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #1A151A;
  gap: 5px;
  transition: 0.35s;
  cursor: pointer;
}
header.style-4 .header-right ul li > a svg {
  fill: var(--primary-color1);
}
header.style-4 .header-right ul li > a:hover {
  background-color: var(--primary-color1);
}
header.style-4 .header-right ul li > a:hover svg {
  fill: var(--title-color);
}
header.style-4 .header-right ul li .search-btn > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #1A151A;
  gap: 5px;
  transition: 0.35s;
  cursor: pointer;
}
header.style-4 .header-right ul li .search-btn > a svg {
  fill: var(--primary-color1);
}
header.style-4 .header-right ul li .search-btn > a:hover {
  background-color: var(--primary-color1);
}
header.style-4 .header-right ul li .search-btn > a:hover svg {
  fill: var(--title-color);
}
header.style-4 .header-right ul li .primary-btn1 {
  color: var(--title-color);
}
header.style-4 .header-right ul li .primary-btn1:hover {
  color: unset;
}
header.style-4 .header-right ul li .primary-btn1 svg {
  fill: var(--title-color);
}
header.style-4 .menu-close-btn i {
  color: var(--white-color);
}
header.style-4 .menu-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: 0.35s;
}
header.style-4 .menu-button svg {
  fill: var(--white-color);
  transition: 0.35s;
}
header.style-4 .menu-button span {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--white-color);
  transition: 0.35s;
}
header.style-4 .menu-button:hover svg {
  fill: var(--primary-color1);
}
header.style-4 .menu-button:hover span {
  color: var(--primary-color1);
}
header.style-4 .nav-right {
  gap: 45px;
}
@media (max-width: 1199px) {
  header.style-4 .nav-right {
    gap: 30px;
  }
}
header.style-4 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-4 .nav-right .hotline-area svg {
  fill: var(--white-color);
}
header.style-4 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-4 .nav-right .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-4 .nav-right .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-4 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-4 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--white-color);
  transition: 0.5s;
}
header.style-4 .nav-right .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
header.style-4 .nav-right .modal-dialog {
  min-height: 100vh;
  max-width: 620px;
  margin: 0 0 0 auto;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog {
    max-width: unset;
    width: 100%;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content {
  padding: 65px 60px;
  min-height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog .modal-content {
    padding: 50px 20px;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-header {
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-header .modal-title {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 0;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-header .close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgba(255, 72, 32, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-header .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover {
  background: rgb(255, 72, 32);
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover i {
  color: var(--white-color);
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body {
  padding: 40px 0;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 500px;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
    gap: 10px;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn {
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  background: rgba(255, 72, 32, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover {
  background: rgb(255, 72, 32);
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover i {
  color: var(--white-color);
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 350px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
    gap: 12px;
    min-width: 100%;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
  background: #F3F3F3;
  border-radius: 5px;
  min-width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
    height: 60px;
    min-width: 55px;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  width: 55px;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  padding-right: 40px;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  color: var(--title-color);
  display: inline-block;
  margin-bottom: 3px;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area {
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 15px;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .footer-button {
  padding-top: 40px;
  width: 100%;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
@media (max-width: 576px) {
  header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
    flex-wrap: wrap;
    gap: 15px;
  }
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li {
  width: 100%;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
}
header.style-4 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
}
header.style-4 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-4 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 50px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
  header.style-4 .nav-right .mobile-menu-btn span {
    height: 2px;
    width: 25px;
    background-color: var(--title-color);
    display: flex;
    transition: transform 0.5s ease-in;
    position: absolute;
    top: 0px;
  }
  header.style-4 .nav-right .mobile-menu-btn span::before {
    transition-duration: 0.5s;
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: var(--title-color);
    content: "";
    top: -10px;
    right: 0;
  }
  header.style-4 .nav-right .mobile-menu-btn span::after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 2px;
    right: 0;
    background-color: var(--title-color);
    content: "";
    top: 10px;
  }
  header.style-4 .nav-right .mobile-menu-btn.active span {
    background-color: transparent;
  }
  header.style-4 .nav-right .mobile-menu-btn.active span::before {
    transform: rotateZ(45deg) translate(8px, 6px);
    width: 35px;
  }
  header.style-4 .nav-right .mobile-menu-btn.active span::after {
    transform: rotateZ(-45deg) translate(8px, -6px);
    width: 35px;
  }
}
header.style-4 .main-menu {
  display: inline-block;
}
header.style-4 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-4 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-4 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
@media (max-width: 1199px) {
  header.style-4 .main-menu ul > li {
    padding: 0px;
  }
}
header.style-4 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-4 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-4 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-4 .main-menu ul > li a {
  color: var(--white-color);
  display: block;
  text-transform: capitalize;
  padding: 15px 20px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (max-width: 991px) {
  header.style-4 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-4 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--white-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-4 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-4 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-4 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
}
@media (max-width: 1199px) {
  header.style-4 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-4 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-4 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}
header.style-4 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 14px;
  font-size: 14px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-4 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}
header.style-4 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 25px;
  color: var(--title-color);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid #ddd;
}
header.style-4 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, #06D889, #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-4 .main-menu ul > li ul.sub-menu > li a {
    border-color: #ddd;
  }
  header.style-4 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #06D889, #ddd);
  }
}
header.style-4 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-4 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-4 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-4 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-4 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-4 .main-menu ul > li ul.sub-menu > li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-4 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: block;
}
header.style-4 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-4 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 0;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
  color: var(--white-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-4 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-4 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  header.style-4 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-up 0.45s linear;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
  }
}
header.style-4 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-4 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
@media only screen and (max-width: 991px) {
  header.style-4 .mobile-logo-area {
    justify-content: center !important;
  }
  header.style-4 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-4 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-4 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-4 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-4 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  header.style-4 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-4 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-4 .main-menu ul li i {
    display: block;
  }
  header.style-4 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-4 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
  }
  header.style-4 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-4 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-4 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-4 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-4 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-4 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

header.style-5 {
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8%;
  transition: all 0.8s ease-out 0s;
  border-bottom: 1px solid transparent;
  background-color: var(--white-color);
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-5 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-5 {
    padding: 0px 3%;
  }
}
header.style-5.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  background-color: var(--white-color);
  z-index: 999;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-5 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-5 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
header.style-5 .menu-close-btn i {
  color: var(--white-color);
}
header.style-5 .nav-right {
  gap: 45px;
}
@media (max-width: 991px) {
  header.style-5 .nav-right {
    gap: 0px;
  }
}
header.style-5 .nav-right .modal-btn {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-5 .nav-right .modal-btn i {
  line-height: 1;
  transition: 0.35s;
}
header.style-5 .nav-right .modal-btn:hover {
  color: var(--primary-color1);
}
header.style-5 .nav-right .modal-btn:hover i {
  color: var(--primary-color1);
}
header.style-5 .nav-right .dropdown .cart-menu {
  min-height: 10rem;
  background-color: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  border-radius: 0 0 10px 10px;
  position: absolute;
  right: 0;
  top: 45px;
  padding: 20px;
  z-index: 99;
  display: none;
  transform: scaleY(0);
  transform-origin: top;
  margin-right: -2.5rem;
  min-width: 220px;
}
header.style-5 .nav-right .dropdown .cart-menu.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
header.style-5 .nav-right .dropdown .cart-body {
  padding: 0px 0 20px;
}
header.style-5 .nav-right .dropdown .cart-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 221px;
  overflow-y: auto;
}
header.style-5 .nav-right .dropdown .cart-body ul::-webkit-scrollbar {
  width: 5px;
}
header.style-5 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
header.style-5 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.5s;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
  display: inline-flex;
  gap: 15px;
  min-width: 290px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content .close-btn {
  background: transparent;
  transition: 0.5s;
  font-weight: normal;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  border: 0;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content .close-btn:hover {
  color: var(--primary-color1) !important;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-5 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-5 .nav-right .dropdown .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-5 .nav-right .dropdown .cart-footer .pricing-area {
  width: 100%;
}
header.style-5 .nav-right .dropdown .cart-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 10px;
}
header.style-5 .nav-right .dropdown .cart-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-5 .nav-right .dropdown .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button {
  padding-top: 20px;
  width: 100%;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li {
  width: 100%;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  border: 1px solid transparent;
  padding: 8px 20px;
  display: flex;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li a svg {
  fill: var(--title-color);
  transition: 0.5s;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li a::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li a:hover {
  color: var(--white-color);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li a:hover svg {
  fill: var(--white-color);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a::after {
  background-color: var(--white-color);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover {
  color: var(--title-color);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover svg {
  fill: var(--title-color);
}
header.style-5 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-5 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-5 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-5 .nav-right .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-5 .nav-right .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-5 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-5 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
header.style-5 .nav-right .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
header.style-5 .nav-right .primary-btn1 {
  font-family: var(--font-montserrat);
  padding: 5px 16px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  header.style-5 .nav-right .primary-btn1 {
    display: none;
    visibility: hidden;
  }
}
header.style-5 .nav-right .modal-dialog {
  min-height: 100vh;
  max-width: 620px;
  margin: 0 0 0 auto;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog {
    max-width: unset;
    width: 100%;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content {
  padding: 65px 60px;
  min-height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog .modal-content {
    padding: 50px 20px;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-header {
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-header .modal-title {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 0;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-header .close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgba(255, 72, 32, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-header .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover {
  background: rgb(255, 72, 32);
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover i {
  color: var(--white-color);
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body {
  padding: 40px 0;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 500px;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
    gap: 10px;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn {
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  background: rgba(255, 72, 32, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover {
  background: rgb(255, 72, 32);
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover i {
  color: var(--white-color);
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 350px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
    gap: 12px;
    min-width: 100%;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
  background: #F3F3F3;
  border-radius: 5px;
  min-width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
    height: 60px;
    min-width: 55px;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  width: 55px;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  padding-right: 40px;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  color: var(--title-color);
  display: inline-block;
  margin-bottom: 3px;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area {
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 15px;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .footer-button {
  padding-top: 40px;
  width: 100%;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
@media (max-width: 576px) {
  header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
    flex-wrap: wrap;
    gap: 15px;
  }
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li {
  width: 100%;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
}
header.style-5 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
}
header.style-5 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-5 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 50px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
  header.style-5 .nav-right .mobile-menu-btn span {
    height: 2px;
    width: 25px;
    background-color: var(--title-color);
    display: flex;
    transition: transform 0.5s ease-in;
    position: absolute;
    top: 0px;
  }
  header.style-5 .nav-right .mobile-menu-btn span::before {
    transition-duration: 0.5s;
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: var(--title-color);
    content: "";
    top: -10px;
    right: 0;
  }
  header.style-5 .nav-right .mobile-menu-btn span::after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 2px;
    right: 0;
    background-color: var(--title-color);
    content: "";
    top: 10px;
  }
  header.style-5 .nav-right .mobile-menu-btn.active span {
    background-color: transparent;
  }
  header.style-5 .nav-right .mobile-menu-btn.active span::before {
    transform: rotateZ(45deg) translate(8px, 6px);
    width: 35px;
  }
  header.style-5 .nav-right .mobile-menu-btn.active span::after {
    transform: rotateZ(-45deg) translate(8px, -6px);
    width: 35px;
  }
}
header.style-5 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-5 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-5 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-5 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
header.style-5 .main-menu ul > li.current-menu-parent a, header.style-5 .main-menu ul > li.current-menu-item a, header.style-5 .main-menu ul > li.current-menu-ancestor a {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-5 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li.position-inherit > a::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-5 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-5 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-5 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-5 .main-menu ul > li a {
  color: var(--title-color);
  display: block;
  text-transform: capitalize;
  padding: 15px 20px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-5 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-5 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-5 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-5 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-5 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  color: var(--title-color);
  opacity: 1;
  top: 8px;
  font-size: 17px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li ul.sub-menu > li i {
    font-size: 20px;
    top: 8px;
    right: 0;
  }
}
header.style-5 .main-menu ul > li ul.sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-5 .main-menu ul > li ul.sub-menu > li.menu-item-has-children:after {
  display: none;
}
header.style-5 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 15px;
  color: var(--text-color1);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
header.style-5 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, #06D889, #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-5 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #06D889, #ddd);
  }
}
header.style-5 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-5 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-5 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu {
    margin-left: 10px;
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: none;
}
header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children i {
  display: block;
}
header.style-5 .main-menu ul > li ul.sub-menu > li .sub-menu > li.menu-item-has-children:after {
  display: none;
}
header.style-5 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-5 .main-menu ul > li .mega-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  min-width: 1040px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  padding: 45px 40px;
  border-radius: 0 0 10px 10px;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li .mega-menu {
    min-width: 935px;
  }
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li .mega-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding: 0;
    padding-left: 10px;
  }
}
header.style-5 .main-menu ul > li .mega-menu .menu-row {
  display: flex;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row {
    display: block;
  }
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
  display: block;
  padding: 0;
  padding-right: 70px;
  position: relative;
  flex-basis: 33.33%;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 55px;
  }
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 0px;
    margin-bottom: 15px;
    flex-basis: unset;
  }
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
    margin-bottom: 0;
  }
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #eee;
  position: absolute;
  right: 27px;
  top: 0;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    right: 18px;
  }
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    display: none;
    visibility: hidden;
  }
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  padding-right: 0;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child::after {
  display: none;
  visibility: hidden;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
    color: #b5b5b5;
    position: relative;
    padding-left: 15px;
  }
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6::before {
    height: 8px;
    width: 8px;
    background-color: #b5b5b5;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li {
  display: block;
  line-height: 1;
  padding: 0;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 1;
  padding: 12px 0;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a:hover {
  color: var(--primary-color1);
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgba(233, 228, 228, 0.5);
  }
  header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a img {
    display: none;
    visibility: hidden;
  }
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn {
  padding-top: 20px;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 7px;
  border-bottom: none;
  font-weight: 600;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: relative;
  right: unset;
  top: unset;
  cursor: pointer;
  display: flex;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover i {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-5 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-5 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  header.style-5 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@media (min-width: 992px) {
  header.style-5 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-5 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-5 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-5 .main-menu .topbar-right {
  padding-top: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-5 .main-menu .topbar-right .primary-btn3 {
  justify-content: center;
  width: 100%;
}
header.style-5 .main-menu .topbar-right .primary-btn3 svg {
  fill: var(--title-color);
}
header.style-5 .main-menu .topbar-right a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
  text-transform: uppercase;
}
header.style-5 .main-menu .topbar-right a:last-child {
  margin-bottom: 0;
}
header.style-5 .main-menu .topbar-right a svg {
  fill: var(--primary-color1);
}
header.style-5 .main-menu .topbar-right a:hover {
  color: var(--primary-color1);
}
header.style-5 .main-menu .topbar-right a:hover svg {
  fill: var(--primary-color1);
}
header.style-5 .main-menu .topbar-right .sell-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
  background-color: transparent;
}
header.style-5 .main-menu .topbar-right .sell-btn:last-child {
  margin-bottom: 0;
}
header.style-5 .main-menu .topbar-right .sell-btn svg {
  fill: var(--primary-color1);
}
header.style-5 .main-menu .topbar-right .sell-btn.primary-btn3 {
  justify-content: center;
}
header.style-5 .main-menu .topbar-right .sell-btn.primary-btn3 svg {
  fill: var(--title-color);
}
header.style-5 .main-menu .topbar-right .sell-btn:hover {
  color: var(--primary-color1);
}
header.style-5 .main-menu .topbar-right .sell-btn:hover svg {
  fill: var(--primary-color1);
}
header.style-5 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}
header.style-5 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-5 .main-menu .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-5 .main-menu .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-5 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-5 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
header.style-5 .main-menu .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
@media only screen and (max-width: 991px) {
  header.style-5 .mobile-logo-area {
    justify-content: center !important;
  }
  header.style-5 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-5 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-5 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-5 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-5 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  header.style-5 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-5 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-5 .main-menu ul li i {
    display: block;
  }
  header.style-5 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-5 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
  }
  header.style-5 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-5 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-5 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-5 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-5 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-5 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

header.style-6 {
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8%;
  transition: all 0.8s ease-out 0s;
  border-bottom: 1px solid transparent;
  background-color: var(--white-color);
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-6 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-6 {
    padding: 0px 3%;
  }
}
header.style-6.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  background-color: var(--white-color);
  z-index: 999;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-6 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-6 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
header.style-6 .menu-close-btn i {
  color: var(--white-color);
}
header.style-6 .nav-right {
  gap: 45px;
}
@media (max-width: 991px) {
  header.style-6 .nav-right {
    gap: 0px;
  }
}
header.style-6 .nav-right .modal-btn {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-6 .nav-right .modal-btn i {
  line-height: 1;
  transition: 0.35s;
}
header.style-6 .nav-right .dropdown .cart-menu {
  min-height: 10rem;
  background-color: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  border-radius: 0 0 10px 10px;
  position: absolute;
  right: 0;
  top: 45px;
  padding: 20px;
  z-index: 99;
  display: none;
  transform: scaleY(0);
  transform-origin: top;
  margin-right: -2.5rem;
}
header.style-6 .nav-right .dropdown .cart-menu.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
header.style-6 .nav-right .dropdown .cart-body {
  padding: 0px 0 20px;
}
header.style-6 .nav-right .dropdown .cart-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 221px;
  overflow-y: auto;
}
header.style-6 .nav-right .dropdown .cart-body ul::-webkit-scrollbar {
  width: 5px;
}
header.style-6 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
header.style-6 .nav-right .dropdown .cart-body ul::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.5s;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
  display: inline-flex;
  gap: 15px;
  min-width: 290px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .dropdown .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
header.style-6 .nav-right .dropdown .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-6 .nav-right .dropdown .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-6 .nav-right .dropdown .cart-footer .pricing-area {
  width: 100%;
}
header.style-6 .nav-right .dropdown .cart-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 10px;
}
header.style-6 .nav-right .dropdown .cart-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-6 .nav-right .dropdown .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button {
  padding-top: 20px;
  width: 100%;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li {
  width: 100%;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  border: 1px solid transparent;
  padding: 8px 20px;
  display: flex;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li a svg {
  fill: var(--title-color);
  transition: 0.5s;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li a::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li a:hover {
  color: var(--white-color);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li a:hover svg {
  fill: var(--white-color);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: var(--title-color);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a::after {
  background-color: var(--white-color);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover {
  color: var(--title-color);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover svg {
  fill: var(--title-color);
}
header.style-6 .nav-right .dropdown .cart-footer .footer-button ul li:first-child a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-6 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-6 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-6 .nav-right .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-6 .nav-right .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-6 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-6 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
header.style-6 .nav-right .primary-btn6 {
  font-family: var(--font-montserrat);
  padding: 5px 16px;
}
@media (max-width: 991px) {
  header.style-6 .nav-right .primary-btn6 {
    display: none;
    visibility: hidden;
  }
}
header.style-6 .nav-right .modal-dialog {
  min-height: 100vh;
  max-width: 620px;
  margin: 0 0 0 auto;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog {
    max-width: unset;
    width: 100%;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content {
  padding: 65px 60px;
  min-height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog .modal-content {
    padding: 50px 20px;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-header {
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-header .modal-title {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 24px;
  color: var(--title-color);
  margin-bottom: 0;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-header .close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-header .close-btn i {
  color: rgb(0, 0, 0);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover {
  background: rgb(0, 0, 0);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-header .close-btn:hover i {
  color: var(--white-color);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body {
  padding: 40px 0;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 500px;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item:last-child {
  margin-bottom: 0;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area {
    gap: 10px;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn {
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.14);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn i {
  color: rgb(0, 0, 0);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover {
  background: rgb(0, 0, 0);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .close-btn:hover i {
  color: var(--white-color);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 350px;
  width: 100%;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item {
    gap: 12px;
    min-width: 100%;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
  background: #F3F3F3;
  border-radius: 5px;
  min-width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img {
    height: 60px;
    min-width: 55px;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  width: 55px;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  padding-right: 40px;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  color: var(--title-color);
  display: inline-block;
  margin-bottom: 3px;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
  color: var(--title-color);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--title-color);
  border: 1px solid var(--title-color);
  color: var(--white-color);
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(238, 238, 238, 0.933333);
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area {
  border-bottom: 1px solid rgba(238, 238, 238, 0.933333);
  width: 100%;
  padding: 10px 0px 15px;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 15px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .footer-button {
  padding-top: 40px;
  width: 100%;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
@media (max-width: 576px) {
  header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul {
    flex-wrap: wrap;
    gap: 15px;
  }
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li {
  width: 100%;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li a {
  width: 100%;
  justify-content: center;
}
header.style-6 .nav-right .modal-dialog .modal-content .modal-footer .footer-button ul li:first-child a {
  background-color: var(--title-color);
  color: var(--white-color);
}
header.style-6 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-6 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 50px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
  header.style-6 .nav-right .mobile-menu-btn span {
    height: 2px;
    width: 25px;
    background-color: var(--title-color);
    display: flex;
    transition: transform 0.5s ease-in;
    position: absolute;
    top: 0px;
  }
  header.style-6 .nav-right .mobile-menu-btn span::before {
    transition-duration: 0.5s;
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: var(--title-color);
    content: "";
    top: -10px;
    right: 0;
  }
  header.style-6 .nav-right .mobile-menu-btn span::after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 2px;
    right: 0;
    background-color: var(--title-color);
    content: "";
    top: 10px;
  }
  header.style-6 .nav-right .mobile-menu-btn.active span {
    background-color: transparent;
  }
  header.style-6 .nav-right .mobile-menu-btn.active span::before {
    transform: rotateZ(45deg) translate(8px, 6px);
    width: 35px;
  }
  header.style-6 .nav-right .mobile-menu-btn.active span::after {
    transform: rotateZ(-45deg) translate(8px, -6px);
    width: 35px;
  }
}
header.style-6 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-6 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-6 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-6 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
header.style-6 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-6 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li.position-inherit > a::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-6 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-6 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-6 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-6 .main-menu ul > li a {
  color: var(--title-color);
  display: block;
  text-transform: capitalize;
  padding: 15px 20px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-6 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-6 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-6 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-6 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-6 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}
header.style-6 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 14px;
  font-size: 14px;
  right: 10px;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
    right: 0;
    font-size: 20px;
    top: 8px;
  }
}
header.style-6 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 15px;
  color: var(--text-color1);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
}
header.style-6 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-6 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-6 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-6 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-6 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-6 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-6 .main-menu ul > li ul.sub-menu > li .sub-menu {
    margin-left: 10px;
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-6 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: block;
}
header.style-6 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-6 .main-menu ul > li .mega-menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  min-width: 1040px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  padding: 45px 40px;
  border-radius: 0 0 10px 10px;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li .mega-menu {
    min-width: 935px;
  }
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li .mega-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding: 0;
    padding-left: 10px;
  }
}
header.style-6 .main-menu ul > li .mega-menu .menu-row {
  display: flex;
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row {
    display: block;
  }
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
  display: block;
  padding: 0;
  padding-right: 70px;
  position: relative;
  flex-basis: 33.33%;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 55px;
  }
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item {
    padding-right: 0px;
    margin-bottom: 15px;
    flex-basis: unset;
  }
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
    margin-bottom: 0;
  }
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #eee;
  position: absolute;
  right: 27px;
  top: 0;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    right: 18px;
  }
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item::after {
    display: none;
    visibility: hidden;
  }
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  padding-right: 0;
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item:last-child::after {
  display: none;
  visibility: hidden;
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
    color: #b5b5b5;
    position: relative;
    padding-left: 15px;
  }
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item h6::before {
    height: 8px;
    width: 8px;
    background-color: #b5b5b5;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li {
  display: block;
  line-height: 1;
  padding: 0;
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 1;
  padding: 12px 0;
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgba(233, 228, 228, 0.5);
  }
  header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a img {
    display: none;
    visibility: hidden;
  }
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn {
  padding-top: 20px;
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 7px;
  border-bottom: none;
  font-weight: 600;
}
header.style-6 .main-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: relative;
  right: unset;
  top: unset;
  cursor: pointer;
  display: flex;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
header.style-6 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-6 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
  }
}
@media (max-width: 991px) {
  header.style-6 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  header.style-6 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@media (min-width: 992px) {
  header.style-6 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-6 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-6 .main-menu .topbar-right {
  padding-top: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-6 .main-menu .topbar-right .primary-btn6 {
  justify-content: center;
  width: 100%;
}
header.style-6 .main-menu .topbar-right .primary-btn6 svg {
  fill: var(--title-color);
}
header.style-6 .main-menu .topbar-right .primary-btn6:hover {
  color: var(--white-color);
}
header.style-6 .main-menu .topbar-right .primary-btn6:hover svg {
  fill: var(--white-color);
}
header.style-6 .main-menu .topbar-right a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
}
header.style-6 .main-menu .topbar-right a:last-child {
  margin-bottom: 0;
}
header.style-6 .main-menu .topbar-right a svg {
  fill: var(--title-color);
}
header.style-6 .main-menu .topbar-right .sell-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
}
header.style-6 .main-menu .topbar-right .sell-btn:last-child {
  margin-bottom: 0;
}
header.style-6 .main-menu .topbar-right .sell-btn svg {
  fill: var(--title-color);
}
header.style-6 .main-menu .topbar-right .sell-btn.primary-btn6 {
  justify-content: center;
}
header.style-6 .main-menu .topbar-right .sell-btn.primary-btn6 svg {
  fill: var(--title-color);
}
header.style-6 .main-menu .topbar-right .sell-btn.primary-btn6:hover {
  color: var(--white-color);
}
header.style-6 .main-menu .topbar-right .sell-btn.primary-btn6:hover svg {
  fill: var(--white-color);
}
header.style-6 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}
header.style-6 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-6 .main-menu .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-6 .main-menu .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-6 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-6 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
@media only screen and (max-width: 991px) {
  header.style-6 .mobile-logo-area {
    justify-content: center !important;
  }
  header.style-6 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-6 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-6 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-6 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-6 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  header.style-6 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-6 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-6 .main-menu ul li i {
    display: block;
  }
  header.style-6 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-6 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
  }
  header.style-6 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-6 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-6 .main-menu ul li ul.sub-menu > li a:hover {
    margin-left: 10px;
  }
  header.style-6 .main-menu ul li ul.sub-menu > li i {
    right: -13px;
  }
  header.style-6 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

.header-and-filter-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.header-and-filter-area .filter-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .header-and-filter-area .filter-area {
    padding: 15px 3%;
  }
}
@media (max-width: 1399px) {
  .header-and-filter-area .filter-area {
    padding: 15px 3%;
  }
}
.header-and-filter-area .filter-area::after {
  content: "";
  height: 1px;
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}
.header-and-filter-area .filter-area .filter-wrap {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (max-width: 1399px) {
  .header-and-filter-area .filter-area .filter-wrap {
    justify-content: center;
  }
}
.header-and-filter-area .filter-area .filter-wrap form {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  border: 1px solid rgba(70, 217, 147, 0.2);
  background: #1F1F1F;
  padding: 8px 15px;
  max-width: 970px;
  width: 100%;
}
@media (max-width: 1499px) {
  .header-and-filter-area .filter-area .filter-wrap form {
    max-width: 930px;
  }
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select {
  min-width: 150px;
  height: 34px;
  padding-left: 18px;
  padding-right: 36px;
  background-color: #292929;
  border-color: #292929;
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select .current {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select::after {
  right: 18px;
  border-color: rgba(255, 255, 255, 0.6);
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select .list {
  background-color: #292929;
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select .list .option {
  color: rgba(255, 255, 255, 0.6);
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select .list .option.selected {
  background-color: transparent;
  color: var(--white-color);
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner .nice-select .list .option:hover {
  color: rgba(255, 255, 255, 0.8);
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner:first-child {
  max-width: 225px;
  min-width: 225px;
}
@media (max-width: 1499px) {
  .header-and-filter-area .filter-area .filter-wrap form .form-inner:first-child {
    max-width: 185px;
    min-width: 185px;
  }
}
.header-and-filter-area .filter-area .filter-wrap form .form-inner button {
  padding: 11px 18px;
  line-height: 1;
}
.header-and-filter-area .filter-area .filter-wrap .advanced-btn button {
  color: var(--white-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  transition: 0.35s;
  white-space: nowrap;
  background-color: transparent;
}
.header-and-filter-area .filter-area .filter-wrap .advanced-btn button svg {
  transition: 0.35s;
  fill: var(--white-color);
}
.header-and-filter-area .filter-area .filter-wrap .advanced-btn button:hover {
  color: var(--primary-color1);
}
.header-and-filter-area .filter-area .filter-wrap .advanced-btn button:hover svg {
  fill: var(--primary-color1);
}
.header-and-filter-area .filter-area .hotline-area {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
}
.header-and-filter-area .filter-area .hotline-area .icon {
  position: relative;
}
.header-and-filter-area .filter-area .hotline-area .icon::after {
  content: "";
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
}
.header-and-filter-area .filter-area .hotline-area .icon svg {
  fill: var(--primary-color1);
}
.header-and-filter-area .filter-area .hotline-area .content {
  line-height: 1;
}
.header-and-filter-area .filter-area .hotline-area .content span {
  color: var(--white-color);
  font-size: 14px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}
.header-and-filter-area .filter-area .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.header-and-filter-area .filter-area .hotline-area .content h6 a {
  color: var(--primary-color1);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  line-height: 1;
  transition: 0.35s;
}
.header-and-filter-area .filter-area .hotline-area .content h6 a:hover {
  color: var(--white-color);
}

.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  padding: 30px 20px !important;
  z-index: 99999;
  height: 100%;
  overflow: auto;
  background: #fff;
  border-right: 1px solid #eee;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
}
.sidebar-menu::-webkit-scrollbar {
  width: 0px;
}
.sidebar-menu .menu-close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.35s;
}
.sidebar-menu .menu-close-btn i {
  font-size: 18px;
}
.sidebar-menu .menu-close-btn:hover {
  background-color: var(--black-color);
  color: var(--white-color);
}
.sidebar-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
  padding-left: 0;
}
.sidebar-menu ul > li a {
  padding-left: 0;
}
.sidebar-menu ul > li a {
  color: var(--title-color);
  display: block;
  text-transform: capitalize;
  padding: 32px 9px;
  position: relative;
  font-family: var(--font-montserrat);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
.sidebar-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
.sidebar-menu ul > li i.active {
  color: var(--primary-color1);
}
.sidebar-menu ul > li i.active::before {
  content: "\f2ea";
}
.sidebar-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  text-align: left;
}
.sidebar-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
.sidebar-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 10px;
  right: 6px;
  display: block;
  color: var(--text-color);
  font-size: 20px;
}
.sidebar-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--text-color);
  opacity: 1;
  top: 3px;
  font-size: 20px;
  right: 0px;
}
.sidebar-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 12px 25px;
  color: var(--text-color1);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
.sidebar-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, #06D889, #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
.sidebar-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
.sidebar-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
.sidebar-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
.sidebar-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
.sidebar-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
.sidebar-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
.sidebar-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  top: 0;
  margin-left: 10px;
  position: unset;
  max-width: 230px;
  min-width: 215px;
  background: transparent;
  top: 0;
}
.sidebar-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
.sidebar-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: block;
}
.sidebar-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
.sidebar-menu ul > li .mega-menu {
  display: none;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  text-align: left;
  position: static;
  min-width: 200px;
  background: 0 0;
  border: none;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  transform: none;
  transition: none;
  display: none;
  margin-top: 0 !important;
  transform: translateY(0px);
  padding: 0;
  padding-left: 10px;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item {
  display: block;
  padding: 0;
  position: relative;
  padding-right: 0px;
  margin-bottom: 15px;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  margin-bottom: 0;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item:last-child {
  padding-right: 0;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item:last-child::after {
  display: none;
  visibility: hidden;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item h6 {
  margin-bottom: 10px;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  color: #b5b5b5;
  position: relative;
  padding-left: 15px;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item h6::before {
  height: 8px;
  width: 8px;
  background-color: #b5b5b5;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item > ul li {
  display: block;
  line-height: 1;
  padding: 0;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  line-height: 1;
  padding: 12px 0;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a:hover {
  color: var(--primary-color1);
  padding-left: 10px;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item > ul li a img {
  display: none;
  visibility: hidden;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn {
  padding-top: 20px;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 7px;
  border-bottom: none;
  font-weight: 600;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: relative;
  right: unset;
  top: unset;
  cursor: pointer;
  display: flex;
  transition: all 0.5s ease-out 0s;
  opacity: 1;
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover {
  color: var(--primary-color1);
}
.sidebar-menu ul > li .mega-menu .menu-row .menu-single-item .explore-more-btn a:hover i {
  color: var(--primary-color1);
}
.sidebar-menu ul > li:hover > a {
  color: var(--primary-color1);
}
.sidebar-menu ul > li:hover::after {
  color: var(--primary-color1);
}
.sidebar-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
.sidebar-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
.sidebar-menu ul > li.active > a {
  color: var(--primary-color1);
}
.sidebar-menu ul > li.active::after {
  color: var(--primary-color1);
}
.sidebar-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
.sidebar-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
.sidebar-menu ul li.menu-item-has-children > i {
  display: block;
}
.sidebar-menu.show-menu {
  transform: translateX(0);
}
.sidebar-menu .mobile-menu-logo {
  text-align: left;
  padding-top: 20px;
  display: block;
  padding-bottom: 8px;
}
.sidebar-menu .menu-list {
  padding-top: 50px;
  padding-bottom: 30px;
}
.sidebar-menu ul {
  float: none;
  text-align: left;
  padding: 5px 0px 20px 0;
}
.sidebar-menu ul li {
  display: block;
  position: relative;
  padding: 0 5px;
}
.sidebar-menu ul li i {
  display: none;
}
.sidebar-menu ul li.menu-item-has-children i {
  display: block;
}
.sidebar-menu ul li a {
  padding: 10px 0;
  display: block;
}
.sidebar-menu ul li ul.sub-menu {
  position: static;
  min-width: 200px;
  background: 0 0;
  border: none;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  transform: none;
  transition: none;
  display: none;
  margin-top: 0 !important;
  transform: translateY(0px);
}
.sidebar-menu ul li ul.sub-menu > li {
  border-bottom: 1px solid transparent;
}
.sidebar-menu ul li ul.sub-menu > li a {
  color: var(--title-color);
  padding: 12px 0px;
}
.sidebar-menu ul li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
  margin-left: 10px;
}
.sidebar-menu ul li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
.sidebar-menu ul li ul.sub-menu > li i {
  display: none;
  color: var(--primary-color1);
  right: -13px;
}
.sidebar-menu ul li ul.sub-menu > li.menu-item-has-children i {
  display: block;
}
.sidebar-menu ul li ul.sub-menu > li ul.sub-menu > li i {
  display: none;
}
.sidebar-menu ul li ul.sub-menu > li ul.sub-menu > li.menu-item-has-children i {
  display: block;
}
.sidebar-menu ul li .bi {
  top: 10px;
  font-size: 20px;
  color: var(--title-color);
}
.sidebar-menu .mobile-logo-area {
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
  padding-bottom: 25px;
}
.sidebar-menu .menu-list {
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
  padding-top: 30px;
}
.sidebar-menu .topbar-right {
  padding-top: 40px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
.sidebar-menu .topbar-right a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
  text-transform: uppercase;
  font-family: var(--font-open-sans);
}
.sidebar-menu .topbar-right a:last-child {
  margin-bottom: 0;
}
.sidebar-menu .topbar-right a svg {
  fill: var(--primary-color1);
}
.sidebar-menu .topbar-right a.primary-btn3 {
  justify-content: center;
}
.sidebar-menu .topbar-right a.primary-btn3 svg {
  fill: var(--title-color);
}
.sidebar-menu .topbar-right a:hover {
  color: var(--primary-color1);
}
.sidebar-menu .topbar-right a:hover svg {
  fill: var(--primary-color1);
}
.sidebar-menu .topbar-right .sell-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: rgba(19,20,26,0.9);
  margin-bottom: 25px;
  background-color: transparent;
}
.sidebar-menu .topbar-right .sell-btn:last-child {
  margin-bottom: 0;
}
.sidebar-menu .topbar-right .sell-btn svg {
  fill: var(--primary-color1);
}
.sidebar-menu .topbar-right .sell-btn.primary-btn3 {
  justify-content: center;
}
.sidebar-menu .topbar-right .sell-btn.primary-btn3 svg {
  fill: var(--title-color);
}
.sidebar-menu .topbar-right .sell-btn:hover {
  color: var(--primary-color1);
}
.sidebar-menu .topbar-right .sell-btn:hover svg {
  fill: var(--primary-color1);
}
.sidebar-menu .topbar-right .primary-btn3 {
  width: 100%;
  justify-content: center;
}
.sidebar-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}
.sidebar-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
.sidebar-menu .hotline-area .content::after {
  content: "";
  height: 32px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-menu .hotline-area .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  margin-bottom: 5px;
  display: inline-block;
}
.sidebar-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.sidebar-menu .hotline-area .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.5s;
}
.sidebar-menu .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}

.topbar-bottom-mobile {
  margin-top: 20px;
}
.topbar-bottom-mobile ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}
.topbar-bottom-mobile ul li {
  line-height: 1;
}
.topbar-bottom-mobile ul li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #1A151A;
  gap: 5px;
  transition: 0.35s;
  cursor: pointer;
}
.topbar-bottom-mobile ul li > a svg {
  fill: var(--primary-color1);
}
.topbar-bottom-mobile ul li > a:hover {
  background-color: var(--primary-color1);
}
.topbar-bottom-mobile ul li > a:hover svg {
  fill: var(--title-color);
}
.topbar-bottom-mobile ul li .search-btn > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #1A151A;
  gap: 5px;
  transition: 0.35s;
  cursor: pointer;
}
.topbar-bottom-mobile ul li .search-btn > a svg {
  fill: var(--primary-color1);
}
.topbar-bottom-mobile ul li .search-btn > a:hover {
  background-color: var(--primary-color1);
}
.topbar-bottom-mobile ul li .search-btn > a:hover svg {
  fill: var(--title-color);
}
.topbar-bottom-mobile ul li .primary-btn1 {
  color: var(--title-color);
}
.topbar-bottom-mobile ul li .primary-btn1:hover {
  color: unset;
}
.topbar-bottom-mobile ul li .primary-btn1 svg {
  fill: var(--title-color);
}

.search-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: var(--black-color);
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  transition: 0.8s;
}
.search-bar.slide {
  transform: scaleY(1);
  opacity: 1;
}
.search-bar .close-btn {
  position: absolute;
  right: 50px;
  top: 50px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #292929;
  border-color: 1px solid #292929;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: 0.35s;
}
.search-bar .close-btn i {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
  line-height: 1;
  transition: 0.35s;
}
.search-bar .close-btn:hover {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.search-bar .close-btn:hover i {
  color: var(--title-color);
}
.search-bar .filter-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  justify-content: center;
}
@media (max-width: 991px) {
  .search-bar .filter-wrap {
    flex-wrap: wrap;
  }
}
.search-bar .filter-wrap form {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  border: 1px solid rgba(70, 217, 147, 0.2);
  background: #1F1F1F;
  padding: 8px 15px;
  max-width: 970px;
}
@media (max-width: 1499px) {
  .search-bar .filter-wrap form {
    max-width: 930px;
  }
}
@media (max-width: 1199px) {
  .search-bar .filter-wrap form {
    max-width: unset;
    width: unset;
  }
}
@media (max-width: 991px) {
  .search-bar .filter-wrap form {
    flex-wrap: wrap;
  }
}
@media (max-width: 1199px) {
  .search-bar .filter-wrap form .form-inner {
    max-width: unset;
    min-width: unset;
  }
}
@media (max-width: 991px) {
  .search-bar .filter-wrap form .form-inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.search-bar .filter-wrap form .form-inner .nice-select {
  min-width: 150px;
  height: 50px;
  padding-left: 18px;
  padding-right: 36px;
  background-color: #292929;
  border-color: #292929;
}
.search-bar .filter-wrap form .form-inner .nice-select .current {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
}
.search-bar .filter-wrap form .form-inner .nice-select::after {
  right: 18px;
  border-color: rgba(255, 255, 255, 0.6);
}
.search-bar .filter-wrap form .form-inner .nice-select .list {
  background-color: #292929;
}
.search-bar .filter-wrap form .form-inner .nice-select .list .option {
  color: rgba(255, 255, 255, 0.6);
}
.search-bar .filter-wrap form .form-inner .nice-select .list .option.selected {
  background-color: transparent;
  color: var(--white-color);
}
.search-bar .filter-wrap form .form-inner .nice-select .list .option:hover {
  color: rgba(255, 255, 255, 0.8);
}
.search-bar .filter-wrap form .form-inner:first-child {
  max-width: 225px;
  min-width: 225px;
}
@media (max-width: 1499px) {
  .search-bar .filter-wrap form .form-inner:first-child {
    max-width: 185px;
    min-width: 185px;
  }
}
@media (max-width: 1199px) {
  .search-bar .filter-wrap form .form-inner:first-child {
    max-width: unset;
    min-width: unset;
  }
}
@media (max-width: 991px) {
  .search-bar .filter-wrap form .form-inner:first-child {
    width: 100%;
  }
}
.search-bar .filter-wrap form .form-inner button {
  padding: 17px 18px;
  line-height: 1;
}
@media (max-width: 991px) {
  .search-bar .filter-wrap form .form-inner button {
    justify-content: center;
  }
}
.search-bar .filter-wrap .advanced-btn button {
  color: var(--white-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  transition: 0.35s;
  white-space: nowrap;
  background-color: transparent;
}
.search-bar .filter-wrap .advanced-btn button svg {
  transition: 0.35s;
  fill: var(--white-color);
}
.search-bar .filter-wrap .advanced-btn button:hover {
  color: var(--primary-color1);
}
.search-bar .filter-wrap .advanced-btn button:hover svg {
  fill: var(--primary-color1);
}

.inner-page-header .topbar-header {
  position: relative;
  z-index: 999;
  left: unset;
  top: unset;
  width: 100%;
  background: var(--title-color);
}
.inner-page-header .topbar-header header.style-2 .main-menu ul > li.menu-item-has-children::after {
  color: #fffbfb;
}
.inner-page-header .topbar-header header.style-2 .main-menu ul > li.menu-item-has-children:hover::after {
  color: var(--primary-color1);
}
.inner-page-header .header-and-filter-area {
  position: relative;
  z-index: 999;
  left: unset;
  top: unset;
  width: 100%;
  background: var(--title-color);
}
.inner-page-header header.style-4 {
  background-color: var(--title-color);
  position: relative;
}
.inner-page-header header.style-4.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--title-color);
  z-index: 888;
}

/*=====================================
     5. Banner
========================================*/
.banner-section1 {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 95px 0;
}
.banner-section1 .banner-content {
  max-width: 705px;
  width: 100%;
}
.banner-section1 .banner-content .sub-title {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 0.2em;
  color: var(--primary-color1);
  margin-bottom: 15px;
}
.banner-section1 .banner-content .sub-title img,
.banner-section1 .banner-content .sub-title svg {
  fill: var(--primary-color1);
  max-width: 50px;
}
.banner-section1 .banner-content h1 {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 75px;
  line-height: 1.11;
  color: var(--title-color);
  margin-bottom: 20px;
}
@media (max-width: 1399px) {
  .banner-section1 .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .banner-section1 .banner-content h1 {
    font-size: 50px;
  }
}
.banner-section1 .banner-content p {
  font-size: 20px;
  color: var(--text-color);
  line-height: 34px;
  margin-bottom: 0;
}
.banner-section1 .banner-content .customar-review {
  padding-top: 50px;
}
.banner-section1 .banner-content .customar-review > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 80px;
}
@media (max-width: 576px) {
  .banner-section1 .banner-content .customar-review > ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
.banner-section1 .banner-content .customar-review > ul > li {
  position: relative;
}
.banner-section1 .banner-content .customar-review > ul > li::after {
  content: "";
  height: 48px;
  width: 2px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 576px) {
  .banner-section1 .banner-content .customar-review > ul > li::after {
    display: none;
    visibility: hidden;
  }
}
.banner-section1 .banner-content .customar-review > ul > li:first-child::after {
  display: none;
  visibility: hidden;
}
.banner-section1 .banner-content .customar-review > ul > li a {
  display: inline-block;
}
.banner-section1 .banner-content .customar-review > ul > li a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul li {
  line-height: 1;
}
.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.banner-section1 .banner-content .customar-review > ul > li a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.banner-section1 .banner-content .customar-review > ul > li a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  position: relative;
}
.banner-section1 .banner-content .customar-review > ul > li a .content ul li span {
  font-weight: 600;
}
.banner-section1 .banner-content .customar-review > ul > li a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.banner-section1 .banner-content .customar-review > ul > li a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.banner-section1 .car-filter-area {
  background-color: var(--white-color);
  padding: 40px 30px;
  border-radius: 5px;
}
@media (max-width: 1199px) {
  .banner-section1 .car-filter-area {
    max-width: 450px;
    width: 100%;
  }
}
.banner-section1 .car-filter-area h4 {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 26px;
  color: var(--title-color);
  margin-bottom: 25px;
}
.banner-section1 .car-filter-area .car-category {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  border-bottom: none;
  margin: 0;
  padding: 0;
  margin-bottom: 26px;
  gap: 10px;
}
@media (max-width: 576px) {
  .banner-section1 .car-filter-area .car-category {
    justify-content: center;
  }
}
.banner-section1 .car-filter-area .car-category > li {
  text-align: center;
}
.banner-section1 .car-filter-area .car-category > li input[type=radio] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}
.banner-section1 .car-filter-area .car-category > li input[type=radio]::before {
  position: absolute;
  content: "";
  left: 0;
  top: 3px;
  height: 60px;
  width: 100%;
  z-index: -1;
  border-radius: 41.5px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #fff 0%, #ddd 100%) border-box;
  background-origin: padding-box, border-box;
  background-repeat: no-repeat;
}
.banner-section1 .car-filter-area .car-category > li input[type=radio]:checked::before {
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #fff 0%, var(--primary-color1) 100%) border-box;
}
.banner-section1 .car-filter-area .car-category > li label {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 5px 20px 0;
  z-index: 2;
}
.banner-section1 .car-filter-area .car-category > li label img {
  max-width: 40px;
  margin: 0 auto;
}
.banner-section1 .car-filter-area .car-category > li label span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--title-color);
  display: block;
}
.banner-section1 .car-filter-area .car-category > li label.active {
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #fff 0%, #D7B65D 100%) border-box;
}
.banner-section1 .car-filter-area .car-type {
  border-bottom: none;
  gap: 30px;
  justify-content: center;
  margin-bottom: 20px;
}
.banner-section1 .car-filter-area .car-type li button {
  padding: 0;
  border: none;
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
}
.banner-section1 .car-filter-area .car-type li button span {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid rgba(19, 20, 26, 0.4);
  display: inline-block;
  position: relative;
}
.banner-section1 .car-filter-area .car-type li button span::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eee;
}
.banner-section1 .car-filter-area .car-type li button.active {
  background-color: transparent;
}
.banner-section1 .car-filter-area .car-type li button.active span {
  border-color: var(--primary-color1);
}
.banner-section1 .car-filter-area .car-type li button.active span::after {
  background-color: var(--primary-color1);
}
.banner-section1 .car-filter-area .form-inner {
  margin-bottom: 24px;
}
.banner-section1 .car-filter-area .form-inner.last {
  margin-bottom: 0;
}
.banner-section1 .car-filter-area .form-inner label {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--title-color);
  display: block;
  margin-bottom: 10px;
}
.banner-section1 .car-filter-area .form-inner.budget-range input {
  width: 49%;
  background: #F3F3F3;
  border-radius: 5px;
  height: 50px;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.banner-section1 .car-filter-area .form-inner .primary-btn1 {
  width: 100%;
  justify-content: center;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--font-montserrat);
}
.banner-section1 .car-filter-area .advanced-btn {
  padding-top: 12px;
}
.banner-section1 .car-filter-area .advanced-btn button {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  transition: 0.35s;
  background-color: transparent;
}
.banner-section1 .car-filter-area .advanced-btn button svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.banner-section1 .car-filter-area .advanced-btn button:hover {
  color: var(--primary-color1);
}
.banner-section1 .car-filter-area .advanced-btn button:hover svg {
  fill: var(--primary-color1);
}

.banner-section2 {
  padding: 321px 0 167px 0px;
  overflow: hidden;
  position: relative;
  padding-left: 11%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .banner-section2 {
    padding: 260px 0 167px 0px;
    padding-left: 5%;
  }
}
@media (max-width: 1399px) {
  .banner-section2 {
    padding: 260px 0 167px 0px;
    padding-left: 5%;
  }
}
@media (max-width: 767px) {
  .banner-section2 {
    padding: 220px 0 120px 0px;
    padding-left: 5%;
  }
}
@media (max-width: 576px) {
  .banner-section2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.banner-section2 .banner-content {
  max-width: 625px;
  width: 100%;
}
.banner-section2 .banner-content h1 {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  line-height: 1.2;
  color: var(--white-color);
  margin-bottom: 15px;
}
@media (max-width: 1399px) {
  .banner-section2 .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .banner-section2 .banner-content h1 {
    font-size: 50px;
  }
}
.banner-section2 .banner-content p {
  font-family: var(--font-open-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7;
  color: var(--white-color);
  margin-bottom: 40px;
}
.banner-section2 .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  gap: 55px;
}
@media (max-width: 576px) {
  .banner-section2 .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.banner-section2 .banner-content .banner-content-bottom .rating a {
  display: inline-block;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
  position: relative;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .content ul li span {
  font-weight: 600;
}
.banner-section2 .banner-content .banner-content-bottom .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--white-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.banner-section2 .banner-content .banner-content-bottom .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}

.text-slider2 {
  overflow: hidden;
  background: rgba(19,20,26,0.9);
  padding: 30px 0;
}
.text-slider2 .marquee_text .js-marquee {
  display: flex;
  align-items: center;
  gap: 50px;
  color: #FFF;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
}

.topbar-header {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
}

.quick-link-area {
  background-color: var(--title-color);
  padding: 30px 11%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .quick-link-area {
    padding: 30px 3%;
  }
}
@media (max-width: 1399px) {
  .quick-link-area {
    padding: 30px 3%;
  }
}
.quick-link-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .quick-link-area ul {
    -moz-columns: 2;
         columns: 2;
    display: block;
  }
}
@media (max-width: 576px) {
  .quick-link-area ul {
    -moz-columns: 1;
         columns: 1;
    display: block;
  }
}
.quick-link-area ul li {
  line-height: 1;
  position: relative;
}
@media (max-width: 991px) {
  .quick-link-area ul li {
    display: flex;
    justify-content: center;
  }
  .quick-link-area ul li:nth-child(odd) {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .quick-link-area ul li {
    margin-bottom: 30px;
    justify-content: flex-start;
  }
  .quick-link-area ul li:last-child {
    margin-bottom: 0;
  }
}
.quick-link-area ul li::after {
  content: "";
  height: 46px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -134px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1699px) {
  .quick-link-area ul li::after {
    left: -120px;
  }
}
@media (max-width: 1199px) {
  .quick-link-area ul li::after {
    left: -75px;
  }
}
@media (max-width: 991px) {
  .quick-link-area ul li::after {
    display: none;
    visibility: hidden;
  }
}
.quick-link-area ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.quick-link-area ul li a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 10px;
}
.quick-link-area ul li a svg {
  fill: var(--white-color);
}

/*=====================================
     6. Brand Category
========================================*/
.view-btn-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.view-btn-area p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--title-color);
  margin-bottom: 0;
  text-align: center;
}

.brand-category-area {
  position: relative;
}
.brand-category-area .single-category1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: rgba(246, 243, 249, 0.6);
  border-radius: 10px;
  padding: 30px 20px;
  z-index: 1;
  transition: all ease 0.35s;
}
.brand-category-area .single-category1 .brand-icon {
  margin-bottom: 25px;
}
.brand-category-area .single-category1::after {
  content: "";
  background-image: url(img/home1/category1-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: -1;
  transition: all ease 0.35s;
  opacity: 0;
}
.brand-category-area .single-category1:hover::after {
  opacity: 1;
}
.brand-category-area .view-btn-area {
  background-color: var(--white-color);
  max-width: 500px;
  margin: 0 auto;
}
.brand-category-area .slick-arrow {
  position: absolute;
  top: unset;
  bottom: -90px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  z-index: 9;
  transition: 0.35s;
  opacity: 0.5;
}
.brand-category-area .slick-arrow::before {
  content: url(img/home1/icon/arrow-left.svg);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.35s;
}
.brand-category-area .slick-arrow:hover {
  opacity: 1;
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.brand-category-area .slick-prev {
  left: 12px;
}
.brand-category-area .slick-next {
  right: 12px;
}
.brand-category-area .slick-next::before {
  content: url(img/home1/icon/arrow-right.svg);
}

/*=====================================
     7. Most Search Area
========================================*/
.most-search-area {
  padding-left: 8%;
  padding-right: 8%;
}
.most-search-area .slider-btn {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  position: absolute;
  left: -3px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
@media (max-width: 991px) {
  .most-search-area .slider-btn {
    display: none;
    visibility: hidden;
  }
}
.most-search-area .slider-btn.next-1 {
  right: -3px;
  left: unset;
}
.most-search-area .slider-btn svg {
  fill: var(--primary-color1);
  transition: 0.35s;
}
.most-search-area .slider-btn:hover {
  background-color: var(--primary-color1);
}
.most-search-area .slider-btn:hover svg {
  fill: var(--white-color);
}
@media (max-width: 1699px) {
  .most-search-area {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media (max-width: 1399px) {
  .most-search-area {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.most-search-area .nav-tabs {
  border-bottom: 1px solid rgba(19, 20, 26, 0.1);
}
.most-search-area .nav-tabs li {
  padding: 0;
}
.most-search-area .nav-tabs li button {
  padding: 9px 15px;
  border: none;
  font-family: var(--font-open-sans);
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  color: var(--title-color);
  border-radius: 0;
}
.most-search-area .nav-tabs li button.active {
  color: var(--white-color);
  background-color: var(--title-color);
}
.most-search-area.style-2 .slider-btn {
  position: relative;
  left: unset;
  top: unset;
  right: unset;
  transform: unset;
}

.product-card {
  padding: 10px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  transition: 0.35s;
  position: relative;
}
.product-card .new {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: var(--black-color);
  background-color: var(--primary-color1);
  display: inline-block;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  padding: 6px 20px;
  border-radius: 10px 0;
}
.product-card .product-img {
  position: relative;
  border-radius: 5px;
  transition: 0.35s;
}
.product-card .product-img .countdown-timer {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  display: flex;
  justify-content: center;
}
@media (max-width: 576px) {
  .product-card .product-img .countdown-timer {
    width: 100%;
  }
}
.product-card .product-img .countdown-timer .auction-time-countdown {
  width: 100%;
}
.product-card .product-img .countdown-timer .countdown_row {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  gap: 5px;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show1 .countdown_section {
  width: 98%;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show2 .countdown_section {
  width: 48% !important;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show3 .countdown_section {
  width: 32.5% !important;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show4 .countdown_section {
  width: 24.5% !important;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show5 .countdown_section {
  width: 25% !important;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show6 .countdown_section {
  width: 16.25% !important;
}
.product-card .product-img .countdown-timer .countdown_row .countdown_show7 .countdown_section {
  width: 14% !important;
}
.product-card .product-img .countdown-timer .hasCountdown {
  overflow: hidden;
  padding: 0;
}
.product-card .product-img .countdown-timer .hasCountdown .countdown_section {
  display: block;
  float: left;
  padding: 7px;
  line-height: 15px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
.product-card .product-img .countdown-timer .hasCountdown .countdown_section .countdown_amount {
  font-weight: 700;
}
.product-card .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card .product-img .number-of-img a {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
}
.product-card .product-img .fav {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  opacity: 0;
}
.product-card .product-img .fav svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.product-card .product-img .fav:hover svg {
  fill: var(--primary-color1);
}
.product-card .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 20, 26, 0.6);
  cursor: pointer;
  transition: 0.35s;
}
.product-card .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.product-card .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.product-card .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50.27%);
  transition: 0.35s;
  opacity: 0;
}
.product-card .product-img img {
  border-radius: 5px;
}
@media (max-width: 767px) {
  .product-card .product-img img {
    width: 100%;
  }
}
.product-card .product-content {
  padding: 15px 10px;
}
@media (max-width: 1499px) {
  .product-card .product-content {
    padding: 15px 10px;
  }
}
.product-card .product-content h5 {
  margin: 10px 0;
  line-height: 1;
}
.product-card .product-content h5 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: var(--title-color);
  transition: 0.35s;
}
.product-card .product-content h5 a:hover {
  color: var(--primary-color1);
}
.product-card .product-content .price-location {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  line-height: 1;
  margin-bottom: 10px;
}
.product-card .product-content .price-location .price {
  line-height: 1;
  position: relative;
  padding-right: 10px;
}
.product-card .product-content .price-location .price strong {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: var(--title-color);
}
.product-card .product-content .price-location .price strong span.auction-price.starting-bid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.product-card .product-content .price-location .price del {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
}
.product-card .product-content .price-location .price .current.auction {
  font-size: 80%;
  font-weight: 500;
}
.product-card .product-content .price-location .location {
  line-height: 1;
}
.product-card .product-content .price-location .location a {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  color: var(--title-color);
  margin-top: 8px;
}
.product-card .product-content .price-location .location a:first-child {
  margin-top: 0;
}
.product-card .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 7px;
  position: relative;
  padding: 15px 0;
  flex-wrap: wrap;
}
@media (max-width: 1699px) {
  .product-card .product-content .features {
    gap: 15px;
  }
}
@media (max-width: 1399px) {
  .product-card .product-content .features {
    gap: 5px;
    flex-wrap: wrap;
  }
}
.product-card .product-content .features::after {
  content: "";
  background-color: #eee;
  position: absolute;
  left: 50%;
  height: 1px;
  width: 80%;
  bottom: 0;
  transform: translateX(-50%);
}
.product-card .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin-right: 3px;
}
@media (max-width: 1499px) {
  .product-card .product-content .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-card .product-content .features li {
    padding-right: 5px;
  }
}
.product-card .product-content .features li svg {
  fill: var(--text-color);
}
.product-card .product-content .content-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.product-card .product-content .content-btm .view-btn2 {
  text-align: end;
  padding-left: 51px;
  padding: 10px;
  border-radius: 10px;
  background: black;
  font-family: var(--font-montserrat);
  font-weight: 600;
  /* font-size: 14px; */
  line-height: 17px;
  color: white;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.35s;
}
.product-card .product-content .content-btm .view-btn2 svg path {
  fill: var(--primary-color1);
}
.product-card .product-content .content-btm .view-btn2 svg path:first-child {
  fill: none;
  stroke: var(--primary-color1);
}
.product-card .product-content .content-btm .view-btn2:hover {
  color: var(--primary-color1);
}
.product-card .product-content .content-btm .brand img {
  width: 40px;
}
.product-card.style-2 {
  background: #F6F4Fa;
}
.product-card.style-2 .product-content {
  padding-top: 20px;
}
.product-card.style-2 .product-content .content-top {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.product-card.style-2 .product-content .content-top .price {
  margin-bottom: 7px;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--title-color);
}
.product-card.style-2 .product-content .content-top .company-logo {
  height: 56px;
  width: 62px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-35px);
  z-index: 99;
}
.product-card.style-2 .product-content .launch-date {
  margin-bottom: 30px;
}
.product-card.style-2 .product-content .launch-date p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-color);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-card.style-2 .product-content .launch-date p {
    font-size: 14px;
  }
}
.product-card.style-2 .product-content .launch-date p span {
  color: var(--black-color);
  display: inline-block;
  padding-left: 35px;
  position: relative;
}
.product-card.style-2 .product-content .launch-date p span::before {
  content: "";
  height: 1px;
  width: 16px;
  background-color: var(--text-color);
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.product-card.style-2 .product-content .launch-btn .primary-btn1 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  justify-content: center;
  padding: 13px 20px;
  width: 100%;
  justify-content: center;
}
.product-card.style-2.compare {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: #FFF;
  position: relative;
}
.product-card.style-2.compare .close-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.product-card.style-2.compare .close-btn i {
  font-size: 20px;
  font-weight: 700;
  transition: 0.35s;
}
.product-card.style-2.compare .close-btn:hover {
  background-color: red;
}
.product-card.style-2.compare .close-btn:hover i {
  color: var(--white-color);
}
.product-card.style-2.compare .product-content {
  padding-top: 40px;
  position: relative;
}
.product-card.style-2.compare .product-content .content-top {
  margin-bottom: 13px;
}
.product-card.style-2.compare .product-content .content-top .company-logo {
  height: 50px;
  width: 50px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  top: -25px;
}
.product-card.style-2.compare .product-content .change-model {
  height: 48px;
}
.product-card.style-2.compare .product-content .change-model .nice-select {
  height: 48px;
  border-radius: 5px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: #F6F4Fa;
}
.product-card:hover {
  border-color: var(--primary-color1);
}
.product-card:hover .product-img .number-of-img {
  opacity: 1;
}
.product-card:hover .product-img .fav {
  opacity: 1;
}
.product-card:hover .product-img .slider-btn-group {
  opacity: 1;
}
.product-card:hover .product-img::after {
  opacity: 1;
}

.swiper.upcoming-car-slider {
  position: relative;
}

.slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.slider-btn-group.style-2 {
  position: relative;
  left: unset;
  top: unset;
  transform: unset;
  margin-top: 40px;
}
.slider-btn-group.style-3 {
  width: unset;
}
.slider-btn-group .slider-btn {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.slider-btn-group .slider-btn svg {
  fill: var(--primary-color1);
  transition: 0.35s;
}
.slider-btn-group .slider-btn:hover {
  background-color: var(--primary-color1);
}
.slider-btn-group .slider-btn:hover svg {
  fill: var(--white-color);
}

/*=====================================
     8. Recent Product Area
========================================*/
.recent-product-section .nav-tabs {
  border-bottom: 1px solid rgba(19, 20, 26, 0.1);
}
@media (max-width: 576px) {
  .recent-product-section .nav-tabs {
    gap: 10px;
  }
}
.recent-product-section .nav-tabs li {
  padding: 0;
}
.recent-product-section .nav-tabs li button {
  padding: 9px 15px;
  border: none;
  font-family: var(--font-open-sans);
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  color: var(--title-color);
  border-radius: 0;
}
.recent-product-section .nav-tabs li button.active {
  color: var(--white-color);
  background-color: var(--title-color);
}

/*=====================================
     9. why Choose Section
========================================*/
.why-choose-area .choose-card {
  background: linear-gradient(116.81deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%) padding-box, linear-gradient(-90deg, rgba(204, 244, 224, 0.1), rgba(70, 217, 147, 0.4)) border-box;
  border: 1px solid silver;
  background-origin: padding-box, border-box;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  transition: 0.35s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .why-choose-area .choose-card {
    padding: 30px 20px;
  }
}
.why-choose-area .choose-card::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  background: linear-gradient(116.81deg, #fff 100%, white 100%) padding-box, linear-gradient(-90deg, var(--primary-color1), var(--primary-color1)) border-box;
  border: 1px solid transparent;
  background-origin: padding-box, border-box;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0;
  transition: 0.35s;
}
.why-choose-area .choose-card:hover::after {
  opacity: 1;
}
.why-choose-area .choose-card .choose-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}
.why-choose-area .choose-card .choose-top h5 {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--title-color);
  margin-bottom: 0;
}
.why-choose-area .choose-card .choose-top h5 span {
  color: var(--primary-color1);
}
.why-choose-area .choose-card p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--text-color);
  margin-bottom: 0;
}
.why-choose-area .choose-card .more-btn {
  padding-top: 20px;
}
.why-choose-area .choose-card .more-btn a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.35s;
}
.why-choose-area .choose-card .more-btn a span {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.why-choose-area .choose-card .more-btn a span i {
  font-size: 17px;
  color: var(--title-color);
}
.why-choose-area .choose-card .more-btn a:hover {
  color: var(--primary-color1);
}
.why-choose-area .our-activetis {
  padding: 40px 0;
  border-bottom: 1px solid rgba(19, 20, 26, 0.1);
  border-top: 1px solid rgba(19, 20, 26, 0.1);
}
.why-choose-area .our-activetis .single-activiti {
  display: flex;
  align-items: center;
  gap: 20px;
}
.why-choose-area .our-activetis .single-activiti .content .number {
  display: flex;
  align-items: center;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--title-color);
  margin-bottom: 4px;
}
.why-choose-area .our-activetis .single-activiti .content .number h5 {
  margin-bottom: 0;
  line-height: 1;
}
.why-choose-area .our-activetis .single-activiti .content p {
  margin-bottom: 0;
  color: var(--text-color);
  line-height: 22px;
}
.why-choose-area .our-activetis .divider {
  position: relative;
}
.why-choose-area .our-activetis .divider::after {
  height: 41px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.16);
  position: absolute;
  right: 0;
  top: 50%;
  left: unset;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .why-choose-area .our-activetis .divider::after {
    display: none;
    visibility: hidden;
  }
}
.why-choose-area .trustpilot-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 30px;
}
.why-choose-area .trustpilot-review > strong {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-color);
}
.why-choose-area .trustpilot-review p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.why-choose-area .trustpilot-review p strong {
  font-weight: 600;
}
.why-choose-area .trustpilot-review p a {
  color: var(--text-color);
  text-decoration: underline;
}
.why-choose-area .divider {
  position: relative;
  z-index: 1;
}
.why-choose-area .divider::after {
  content: "";
  height: 1px;
  width: 80%;
  background-color: #eee;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.why-choose-area.two .rating {
  min-width: 270px;
}
.why-choose-area.two .rating a {
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 10px 20px 15px;
}
.why-choose-area.two .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.why-choose-area.two .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.why-choose-area.two .rating a .review-top .star ul li {
  line-height: 1;
}
.why-choose-area.two .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.why-choose-area.two .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.why-choose-area.two .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  position: relative;
}
.why-choose-area.two .rating a .content ul li span {
  font-weight: 600;
}
.why-choose-area.two .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.why-choose-area.two .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}

/*=====================================
     10. Recommendded Car Section
========================================*/
.recommended-car-section {
  padding: 0 10%;
  position: relative;
  z-index: 1;
}
.recommended-car-section::after {
  content: "";
  background-color: var(--title-color);
  width: 100%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 1399px) {
  .recommended-car-section::after {
    height: 260px;
  }
}
@media (max-width: 1199px) {
  .recommended-car-section::after {
    height: 210px;
  }
}
@media (max-width: 991px) {
  .recommended-car-section::after {
    display: none;
    visibility: hidden;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .recommended-car-section {
    padding: 0 3%;
  }
}
@media (max-width: 1399px) {
  .recommended-car-section {
    padding: 0 3%;
  }
}
@media (max-width: 1199px) {
  .recommended-car-section {
    padding: 0%;
  }
}
@media (max-width: 991px) {
  .recommended-car-section {
    background-color: var(--title-color);
    padding: 80px 0px;
  }
}
.recommended-car-section .recommended-left {
  display: flex;
  align-items: end;
  z-index: 1;
}
.recommended-car-section .recommended-left .section-title1 {
  margin-bottom: 90px;
}
@media (max-width: 1399px) {
  .recommended-car-section .recommended-left .section-title1 {
    margin-bottom: 70px;
  }
}
@media (max-width: 1199px) {
  .recommended-car-section .recommended-left .section-title1 {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .recommended-car-section .recommended-left .section-title1 {
    margin-bottom: 0px;
    text-align: center;
  }
}
.recommended-car-section .recommended-left .section-title1 h2 {
  color: var(--white-color);
}
@media (max-width: 991px) {
  .recommended-car-section .recommended-left .think-img {
    display: none;
    visibility: hidden;
  }
}
.recommended-car-section .recommended-right {
  max-width: 340px;
  margin-left: auto;
  margin-bottom: 90px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .recommended-car-section .recommended-right {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .recommended-car-section .recommended-right {
    margin-bottom: 0px;
    margin-right: auto;
    text-align: center;
  }
}
.recommended-car-section .recommended-right p {
  color: var(--white-color);
  margin-bottom: 25px;
}
.recommended-car-section .recommended-right a {
  display: inline-block;
  font-size: 14px;
  padding: 10px 40px;
}

/*=====================================
     11. Top Rate Used Cars Section
========================================*/
.top-rate-used-car-area {
  padding-left: 8%;
  padding-right: 8%;
}
@media (max-width: 767px) {
  .top-rate-used-car-area .slider-btn-group {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 1699px) {
  .top-rate-used-car-area {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media (max-width: 1399px) {
  .top-rate-used-car-area {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.top-rate-used-car-area .form-inner {
  display: flex;
  align-items: center;
  gap: 20px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding: 6px 6px 6px 20px;
}
.top-rate-used-car-area .form-inner label {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 16px;
  color: var(--title-color);
  white-space: nowrap;
}
.top-rate-used-car-area .form-inner .nice-select {
  min-width: 150px;
  height: 34px;
  padding-left: 18px;
  padding-right: 36px;
  background-color: var(--primary-color1);
}
.top-rate-used-car-area .form-inner .nice-select .list {
  max-height: 350px;
}
.top-rate-used-car-area .form-inner .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
}
.top-rate-used-car-area .form-inner .nice-select:focus {
  color: var(--title-color);
}
.top-rate-used-car-area .form-inner .nice-select::after {
  right: 18px;
  border-color: var(--title-color);
}

/*=====================================
     12. Compare Car With Brand Section
========================================*/
.compare-car-section .single-compare-card {
  background-image: url(img/home1/compare-bg.png), linear-gradient(#F6F4Fa, #F6F4Fa);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 10px 15px 35px 15px;
  border: 1px solid #EEEEEE;
}
.compare-car-section .single-compare-card .compare-top {
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  padding: 30px 15px;
}
.compare-car-section .single-compare-card .compare-top .single-car .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: var(--text-color);
}
.compare-car-section .single-compare-card .compare-top .single-car .content h6 {
  margin-bottom: 0;
}
.compare-car-section .single-compare-card .compare-top .single-car .content h6.title {
  margin-bottom: 5px;
}
.compare-car-section .single-compare-card .compare-top .single-car .content h6.title a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.35s;
}
.compare-car-section .single-compare-card .compare-top .single-car .content h6.title a:hover {
  color: var(--primary-color1);
}
.compare-car-section .single-compare-card .compare-top .single-car .content h6.price {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
}
.compare-car-section .single-compare-card .compare-top .single-car .content h6.price del {
  font-size: 80%;
  opacity: 0.5;
}
.compare-car-section .single-compare-card .compare-top .vs {
  padding: 0 12px;
  transform: translateY(-40px);
}
.compare-car-section .single-compare-card .compare-top .vs span {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 12px;
  color: var(--black-color);
}
.compare-car-section .single-compare-card .compare-top .vs span::after {
  content: "";
  height: 90px;
  width: 1px;
  background-color: var(--primary-color1);
  position: absolute;
  bottom: -90px;
}
.compare-car-section .single-compare-card .compare-top .vs span::before {
  content: "";
  width: 9px;
  height: 1px;
  background-color: var(--primary-color1);
  position: absolute;
  bottom: -90px;
}
.compare-car-section .single-compare-card .compare-btm a {
  text-align: center;
  justify-content: center;
  font-family: var(--font-montserrat);
  font-size: 14px;
  padding: 7px 20px;
}
.compare-car-section .single-compare-card .compare-btm button {
  width: 100%;
  justify-content: center;
  padding: 15px 20px;
  line-height: 1;
}
.compare-car-section .single-compare-card.style-2 {
  border: 1px solid #323232;
  background: #141414;
}
.compare-car-section .single-compare-card.style-2 .compare-top {
  background: #1D1D1D;
}
.compare-car-section .single-compare-card.style-2 .compare-top .single-car .content h6 {
  margin-bottom: 0;
}
.compare-car-section .single-compare-card.style-2 .compare-top .single-car .content h6.title {
  margin-bottom: 5px;
}
.compare-car-section .single-compare-card.style-2 .compare-top .single-car .content h6.title a {
  color: var(--white-color);
}
.compare-car-section .single-compare-card.style-2 .compare-top .single-car .content h6.title a:hover {
  color: var(--primary-color1);
}
.compare-car-section .single-compare-card.style-2 .compare-top .single-car .content h6.price {
  color: #B3B3B3;
  font-weight: 600;
}
.compare-car-section .single-compare-card.style-2 .compare-top .vs {
  padding: 0 12px;
  transform: translateY(-40px);
}
.compare-car-section .single-compare-card.style-2 .compare-top .vs span {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #323232;
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 12px;
  color: var(--white-color);
}
.compare-car-section .single-compare-card.style-2 .compare-top .vs span::after {
  content: "";
  height: 90px;
  width: 1px;
  background-color: #323232;
  position: absolute;
  bottom: -90px;
}
.compare-car-section .single-compare-card.style-2 .compare-top .vs span::before {
  content: "";
  width: 9px;
  height: 1px;
  background-color: #323232;
  position: absolute;
  bottom: -90px;
}
.compare-car-section .single-compare-card.style-2 .compare-btm .primary-btn2 {
  text-align: center;
  justify-content: center;
  font-family: var(--font-montserrat);
  font-size: 14px;
  padding: 18px 20px;
  color: var(--white-color);
  background-color: #1D1D1D;
}
.compare-car-section .single-compare-card.style-2 .compare-btm .primary-btn2::after {
  background-color: var(--white-color);
}
.compare-car-section .single-compare-card.style-2 .compare-btm .primary-btn2:hover {
  color: var(--title-color);
}
.compare-car-section.two {
  background: #1D1D1D;
}
.compare-car-section.two .section-title-2 h2 {
  color: var(--white-color);
}
.compare-car-section.two .section-title-2 p {
  color: var(--white-color);
}
.compare-car-section.two .nav-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  gap: 15px;
}
.compare-car-section.two .nav-tabs .nav-item .nav-link {
  color: #FFF;
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 2px 21px;
  border-radius: 25px;
}
.compare-car-section.two .nav-tabs .nav-item .nav-link.active {
  color: var(--black-color2);
  background: #F8F8F8;
}
.compare-car-section.two .compare-btn-and-slider-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.compare-car-section.two .compare-btn-and-slider-btn .see-all-btn a {
  color: #FFF;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}
.compare-car-section.two .compare-btn-and-slider-btn .see-all-btn a svg {
  fill: #FFF;
}
.compare-car-section.two .compare-btn-and-slider-btn .slider-btn-group {
  max-width: 90px;
  width: 100%;
}
.compare-car-section.two .compare-btn-and-slider-btn .slider-btn-group .slider-btn {
  border-color: rgba(255, 255, 255, 0.2);
}
.compare-car-section.two .compare-btn-and-slider-btn .slider-btn-group .slider-btn svg {
  fill: rgba(255, 255, 255, 0.2);
}
.compare-car-section.two .compare-btn-and-slider-btn .slider-btn-group .slider-btn:hover {
  border: var(--white-color);
  background-color: var(--white-color);
}
.compare-car-section.two .compare-btn-and-slider-btn .slider-btn-group .slider-btn:hover svg {
  fill: var(--title-color);
}

/*=====================================
     13. Shop Car Parts Section
========================================*/
.shop-card {
  position: absolute;
  top: -126px;
  right: -445px;
  max-width: 315px;
  min-width: 315px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 10px 24px 10px;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.35s;
  z-index: 999;
}
.shop-card.left {
  right: unset;
  left: -445px;
  transform-origin: right;
}
.shop-card .shop-img {
  position: relative;
  background: #F6F4Fa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shop-card .shop-img img {
  max-height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
.shop-card .shop-img .offer-batch {
  position: absolute;
  left: 14px;
  top: 14px;
}
.shop-card .shop-img .offer-batch span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--white-color);
  background: #FF5E3A;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 7px;
}
.shop-card .shop-img .fav {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 14px;
}
.shop-card .shop-img .fav svg {
  fill: var(--title-color);
}
.shop-card .content {
  padding: 20px 20px 0px;
}
.shop-card .content .star {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}
.shop-card .content .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3px;
}
.shop-card .content .star ul li {
  line-height: 1;
}
.shop-card .content .star ul li i {
  font-size: 10px;
  color: #DDA701;
}
.shop-card .content .star span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--title-color);
  line-height: 1;
}
.shop-card .content h6 {
  margin-bottom: 20px;
}
.shop-card .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--title-color);
}
.shop-card .content h6 a span {
  font-weight: 600;
}
.shop-card .content .content-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop-card .content .content-btm .price {
  line-height: 1;
}
.shop-card .content .content-btm .price h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--title-color);
}
.shop-card .content .content-btm .price h6 ins {
  text-decoration: none;
}
.shop-card .content .content-btm .price h6 del {
  font-size: 80%;
  opacity: 0.5;
  margin-left: 5px;
}
.shop-card .content .content-btm a {
  font-size: 13px;
  font-family: var(--font-montserrat);
  padding: 2px 17px;
  justify-content: center;
}

.shop-card2 {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 10px 24px 10px;
  transition: 0.35s;
  border: 1px solid #eee;
}
.shop-card2 .shop-img {
  position: relative;
  border-radius: 10px;
}
.shop-card2 .shop-img img {
  border-radius: 10px;
}
@media (max-width: 991px) {
  .shop-card2 .shop-img img {
    width: 100%;
  }
}
.shop-card2 .shop-img .offer-batch {
  position: absolute;
  left: 14px;
  top: 14px;
  line-height: 1;
}
.shop-card2 .shop-img .offer-batch span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--white-color);
  background: #FF5E3A;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 7px;
}
.shop-card2 .shop-img .fav {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 14px;
}
.shop-card2 .shop-img .fav svg {
  fill: var(--title-color);
}
.shop-card2 .content {
  margin-top: -17px;
  padding: 0 20px;
}
.shop-card2 .content .cart-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.shop-card2 .content .cart-btn .primary-btn2 {
  font-size: 13px;
  font-family: var(--font-montserrat);
  padding: 2px 17px;
  justify-content: center;
}
.shop-card2 .content .star {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 5px;
}
.shop-card2 .content .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3px;
  line-height: 1;
}
.shop-card2 .content .star ul li {
  line-height: 1;
}
.shop-card2 .content .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.shop-card2 .content .star span {
  font-family: var(--font-open-sans);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  line-height: 1;
}
.shop-card2 .content h6 {
  margin-bottom: 15px;
}
.shop-card2 .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: var(--title-color);
}
.shop-card2 .content h6 a span {
  font-weight: 600;
}
.shop-card2 .content .content-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop-card2 .content .content-btm .price {
  line-height: 1;
}
.shop-card2 .content .content-btm .price h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: var(--title-color);
}
.shop-card2 .content .content-btm .price h6 del {
  color: var(--text-color);
  font-weight: 600;
}

.shop-car-parts-section {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.shop-car-parts-section .text-slider {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
}
.shop-car-parts-section .text-slider h2 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 70px;
  line-height: 1;
  color: #e7e7e7;
}
.shop-car-parts-section .text-slider h2 .js-marquee {
  display: flex;
  align-items: center;
  gap: 70px;
  margin-right: 0px !important;
}
.shop-car-parts-section .section-title1 {
  max-width: 472px;
  width: 100%;
  margin-left: auto;
  padding-top: 80px;
}
@media (max-width: 991px) {
  .shop-car-parts-section .section-title1 {
    margin: 0 auto;
    text-align: center;
    padding-top: 0;
  }
}
.shop-car-parts-section .section-title1 h2 {
  margin-bottom: 40px;
}
.shop-car-parts-section .section-title1 a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  padding: 10px 38px;
}
.shop-car-parts-section .shop-big-img {
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .shop-car-parts-section .shop-big-img {
    display: flex;
    justify-content: center;
  }
}
.shop-car-parts-section .shop-big-img > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.shop-car-parts-section .shop-big-img > ul > li {
  position: absolute;
  transition: 0.35s;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main::after {
  content: url(img/home1/icon/shop-vec.svg);
  position: absolute;
  left: 5px;
  bottom: 0px;
  transition: 0.35s;
  opacity: 0;
  transform: translateX(0);
  transform-origin: left;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main.left::after {
  content: url(img/home1/icon/shop-vec2.svg);
  position: absolute;
  right: 5px;
  left: unset;
  bottom: 0px;
  transition: 0.35;
  opacity: 0;
  transform: translateX(0);
  transform-origin: left;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main .waves {
  position: absolute;
  width: 35px;
  height: 35px;
  background: white;
  opacity: 0;
  border-radius: 100%;
  right: -16px;
  bottom: -8px;
  z-index: 1;
  animation: waves 3s ease-in-out infinite;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main .wave-1 {
  animation-delay: 0s;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main .wave-2 {
  animation-delay: 1s;
}
.shop-car-parts-section .shop-big-img > ul > li .dot-main .wave-3 {
  animation-delay: 2s;
  background: #D7B65D;
}
@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
.shop-car-parts-section .shop-big-img > ul > li .dot {
  height: 16px;
  width: 16px;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: 0.35s;
}
.shop-car-parts-section .shop-big-img > ul > li .dot::after {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.35s;
}
.shop-car-parts-section .shop-big-img > ul > li .dot:hover {
  border-color: var(--primary-color1);
}
.shop-car-parts-section .shop-big-img > ul > li .dot:hover::after {
  background-color: var(--primary-color1);
}
.shop-car-parts-section .shop-big-img > ul > li:nth-child(5) {
  left: 25px;
  top: 127px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(5) {
    top: 93px;
  }
}
@media (max-width: 1399px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(5) {
    top: 80px;
  }
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(5) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.shop-car-parts-section .shop-big-img > ul > li:nth-child(2) {
  left: 50px;
  bottom: 127px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(2) {
    bottom: 40px;
  }
}
@media (max-width: 1399px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(2) {
    left: 40px;
    bottom: 40px;
  }
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(2) {
    display: none;
    visibility: hidden;
  }
}
.shop-car-parts-section .shop-big-img > ul > li:nth-child(3) {
  right: 40px;
  bottom: 40px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(3) {
    bottom: 30px;
  }
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(3) {
    display: none;
    visibility: hidden;
  }
}
.shop-car-parts-section .shop-big-img > ul > li:nth-child(4) {
  right: 105px;
  top: 47%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(4) {
    right: 90px;
  }
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(4) {
    display: none;
    visibility: hidden;
  }
}
.shop-car-parts-section .shop-big-img > ul > li:nth-child(1) {
  right: 190px;
  top: 115px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(1) {
    top: 75px;
  }
}
@media (max-width: 1399px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(1) {
    right: 130px;
    top: 45px;
  }
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-big-img > ul > li:nth-child(1) {
    display: none;
    visibility: hidden;
  }
}
.shop-car-parts-section .shop-big-img > ul > li.active .dot-main::after {
  opacity: 1;
  transform: scaleX(1);
}
.shop-car-parts-section .shop-big-img > ul > li.active .shop-card {
  transform: scaleX(1);
  opacity: 1;
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-big-img > ul > li.active .shop-card {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(1);
    opacity: 1;
    right: unset;
    top: unset;
  }
}
.shop-car-parts-section .shop-right-content {
  max-width: 490px;
  width: 100%;
}
@media (max-width: 1699px) {
  .shop-car-parts-section .shop-right-content {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 1399px) {
  .shop-car-parts-section .shop-right-content {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 1199px) {
  .shop-car-parts-section .shop-right-content {
    display: block;
    visibility: visible;
  }
}
@media (max-width: 991px) {
  .shop-car-parts-section .shop-right-content {
    margin: 0 auto;
    text-align: center;
  }
}
.shop-car-parts-section .shop-right-content p {
  margin-bottom: 0;
  color: var(--title-color);
  position: relative;
}
.shop-car-parts-section .shop-right-content p::after {
  content: "";
  height: 1px;
  width: 100px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: -10px;
}

/*=====================================
     14. Testimonial Section
========================================*/
.customar-feedback-area .customer-feedback-left {
  background-image: url(img/home1/customer-feedbac-bg1.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  padding: 50px 20px;
  min-height: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 60px;
  position: relative;
}
.customar-feedback-area .customer-feedback-left::after {
  content: "";
  height: 1px;
  width: 144px;
  background-color: rgba(19, 20, 26, 0.25);
  position: absolute;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);
}
.customar-feedback-area .customer-feedback-left .trustpilot {
  text-align: center;
  display: flex;
  align-items: center;
}
.customar-feedback-area .customer-feedback-left .trustpilot h5 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--title-color);
  margin-bottom: 10px;
}
.customar-feedback-area .customer-feedback-left .trustpilot span {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
  display: inline-block;
  padding-top: 6px;
  margin-bottom: 10px;
}
.customar-feedback-area .customer-feedback-left .trustpilot span strong {
  font-weight: 600;
}
.customar-feedback-area .customer-feedback-left .google {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.customar-feedback-area .customer-feedback-left .google ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}
.customar-feedback-area .customer-feedback-left .google ul li {
  line-height: 1;
}
.customar-feedback-area .customer-feedback-left .google ul li i {
  font-style: 14px;
  color: #DDA701;
}
.customar-feedback-area .customer-feedback-left .google span {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
  display: inline-block;
  padding-top: 6px;
}
.customar-feedback-area .customer-feedback-left .google span strong {
  font-weight: 600;
}
.customar-feedback-area .customer-feedback-right .feedback-card {
  padding: 40px 30px;
  background: #F6F4Fa;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.35s;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 15px;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area {
  display: flex;
  flex-direction: column;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area ul li {
  line-height: 1;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area ul li i {
  font-style: 13px;
  color: #DDA701;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area img {
  margin-bottom: 5px;
}
.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.customar-feedback-area .customer-feedback-right .feedback-card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-color);
}
.customar-feedback-area .customer-feedback-right .feedback-card .author-name {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}
.customar-feedback-area .customer-feedback-right .feedback-card .author-name span {
  color: var(--text-color);
  font-size: 12px;
  font-family: var(--font-open-sans);
  font-weight: 600;
}
.customar-feedback-area .customer-feedback-right .feedback-card .author-name h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  padding-left: 30px;
  position: relative;
}
.customar-feedback-area .customer-feedback-right .feedback-card .author-name h6::after {
  content: "";
  height: 1px;
  width: 20px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.customar-feedback-area .customer-feedback-right .feedback-card:hover {
  border-color: var(--primary-color1);
}
.customar-feedback-area.style-2 .slider-btn-group .slider-btn {
  background-color: transparent;
  border-color: rgba(19, 20, 26, 0.2);
}
.customar-feedback-area.style-2 .slider-btn-group .slider-btn svg {
  fill: rgba(19, 20, 26, 0.2);
}
.customar-feedback-area.style-2 .slider-btn-group .slider-btn:hover {
  background-color: var(--title-color);
  border-color: var(--title-color);
}
.customar-feedback-area.style-2 .slider-btn-group .slider-btn:hover svg {
  fill: var(--white-color);
}
.customar-feedback-area.style-2 .slider-btn-group .view-btn::after {
  border-color: #000;
}
.customar-feedback-area.style-2 .slider-btn-group .view-btn:hover {
  color: #000;
}
.customar-feedback-area .sub-title {
  z-index: 1;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.customar-feedback-area .sub-title h6 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  margin-bottom: 0;
  background-color: var(--white-color);
  z-index: 5;
  position: relative;
  white-space: nowrap;
  padding: 0 20px 0 0;
}
.customar-feedback-area .sub-title .dash {
  position: relative;
  width: 100%;
}
.customar-feedback-area .sub-title .dash::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #eee;
}
.customar-feedback-area .partner-slider {
  overflow: hidden;
}
.customar-feedback-area .partner-slider .js-marquee {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-right: 0 !important;
}

.trusted-partner-section .sub-title {
  z-index: 1;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.trusted-partner-section .sub-title h6 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  margin-bottom: 0;
  background-color: var(--white-color);
  z-index: 5;
  position: relative;
  white-space: nowrap;
  padding: 0 20px 0 0;
}
.trusted-partner-section .sub-title .dash {
  position: relative;
  width: 100%;
}
.trusted-partner-section .sub-title .dash::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #eee;
}
.trusted-partner-section .partner-slider {
  overflow: hidden;
}
.trusted-partner-section .partner-slider .js-marquee {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-right: 0 !important;
}
@media (max-width: 576px) {
  .trusted-partner-section .partner-slider .js-marquee {
    gap: 40px;
  }
}

/*=====================================
     15. News Section
========================================*/
.news-section.grid-view {
  background-color: #fff;
}
.news-section.two {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.news-section.five {
  background-color: transparent;
}
.news-section .news-card {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding: 10px;
}
.news-section .news-card .news-img {
  position: relative;
  border-radius: 5px;
}
.news-section .news-card .news-img img {
  border-radius: 5px;
  width: 100%;
  min-height: 270px;
  -o-object-fit: cover;
     object-fit: cover;
}
.news-section .news-card .news-img .date {
  position: absolute;
  right: -10px;
  bottom: -15px;
}
.news-section .news-card .news-img .date a {
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 7% 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 7% 50%);
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 3px 30px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--title-color);
  transition: 0.35s;
}
.news-section .news-card .content {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.news-section .news-card .content > h6 {
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.news-section .news-card .content > h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--title-color);
  transition: 0.35s;
}
.news-section .news-card .content > h6 a:hover {
  color: var(--primary-color1);
}
.news-section .news-card .content .news-btm .author-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.news-section .news-card .content .news-btm .author-area .author-img img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.news-section .news-card .content .news-btm .author-area .author-content {
  display: inline-grid;
}
.news-section .news-card .content .news-btm .author-area .author-content h6 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: var(--title-color);
}
.news-section .news-card .content .news-btm .author-area .author-content a {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  transition: 0.35s;
}
.news-section .news-card .content .news-btm .author-area .author-content a:hover {
  color: var(--primary-color1);
}
.news-section .news-card .content .news-btm .author-area .author-content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
}
.news-section .news-card.style-2 .date {
  position: absolute;
  left: -10px;
  right: unset;
  bottom: -15px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(100% 0, 93% 50%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 93% 50%, 100% 100%, 0 100%, 0 0);
  padding: 0px 22px;
  line-height: 1;
}
.news-section .news-card.style-2 .date a {
  -webkit-clip-path: unset;
          clip-path: unset;
  background-color: transparent;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--title-color);
  position: relative;
}
.news-section .news-card.style-2 .date a::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.5);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.news-section .news-card.style-2 .date a:first-child::after {
  display: none;
}
.news-section .news-card.style-2 .social-area {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.45s;
  position: relative;
}
.news-section .news-card.style-2 .social-area .share-icon {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F6F6;
  border: 1px solid var(--primary-color1);
  border-radius: 50%;
  transition: 0.45s;
  cursor: pointer;
}
.news-section .news-card.style-2 .social-area .share-icon svg {
  fill: var(--title-color);
  transition: 0.45s;
}
.news-section .news-card.style-2 .social-area .share-icon:hover {
  background-color: var(--primary-color1);
}
.news-section .news-card.style-2 .social-area .social-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.45s;
  position: absolute;
  right: 38px;
}
.news-section .news-card.style-2 .social-area .social-icons li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: #F6F6F6;
  border: 1px solid var(--primary-color1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.45s;
  color: var(--title-color);
}
.news-section .news-card.style-2 .social-area .social-icons li a:hover {
  background-color: var(--primary-color1);
}
.news-section .news-card.style-2 .social-area:hover .social-icons {
  opacity: 1;
  transform: scaleX(1);
}
.news-section .news-card.dark .date {
  background-color: var(--white-color);
  border: 1px solid rgba(238, 238, 238, 0.93);
}
.news-section .news-card.dark .social-area .share-icon {
  background-color: #eee;
  border: 1px solid #eee;
}
.news-section .news-card.dark .social-area .share-icon svg {
  fill: var(--title-color);
  transition: 0.45s;
}
.news-section .news-card.dark .social-area .share-icon:hover {
  background-color: var(--title-color);
}
.news-section .news-card.dark .social-area .share-icon:hover svg {
  fill: var(--white-color);
}
.news-section .news-card.dark .social-area .social-icons li a {
  background-color: #eee;
  border: 1px solid #eee;
}
.news-section .news-card.dark .social-area .social-icons li a:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.news-section .news-card.dark .social-area:hover .social-icons {
  opacity: 1;
  transform: scaleX(1);
}
.news-section.six {
  background-color: transparent;
}
.news-section.six .slider-btn-group2 .slider-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.news-section.six .slider-btn-group2 .slider-btn:hover svg {
  fill: var(--white-color);
}

/*=====================================
     16. Footer Section
========================================*/
footer {
  background: var(--title-color);
}
footer .footer-top {
  padding: 50px 11%;
}
@media (max-width: 1699px) {
  footer .footer-top {
    padding: 50px 6%;
  }
}
@media (max-width: 1399px) {
  footer .footer-top {
    padding: 50px 5%;
  }
}
@media (max-width: 1399px) {
  footer .footer-top {
    padding: 50px 3%;
  }
}
@media (max-width: 991px) {
  footer .footer-top {
    padding: 50px 2%;
  }
}
footer .footer-widget .widget-title {
  margin-bottom: 23px;
}
footer .footer-widget .widget-title h5 {
  margin-bottom: 0;
  color: var(--white-color);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 600;
}
footer .footer-widget .menu-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer .footer-widget .menu-container ul li {
  margin-bottom: 8px;
}
footer .footer-widget .menu-container ul li:last-child {
  margin-bottom: 0;
}
footer .footer-widget .menu-container ul li a {
  color: #B3B3B3;
  font-size: 15px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  transition: 0.35s;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0px;
}
footer .footer-widget .menu-container ul li a svg {
  fill: #B3B3B3;
  transition: 0.35s;
  opacity: 0;
}
footer .footer-widget .menu-container ul li a:hover {
  color: var(--white-color);
}
footer .footer-widget .menu-container ul li a:hover svg {
  fill: var(--primary-color1);
  opacity: 1;
}
footer .footer-widget .app-download {
  padding-top: 10px;
}
footer .footer-widget .app-download ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer .footer-widget .app-download ul li {
  margin-bottom: 28px;
}
footer .footer-widget .app-download ul li:last-child {
  margin-bottom: 0;
}
footer .footer-widget .app-download ul li a {
  display: inline-block;
  padding: 14px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
footer .footer-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 11% 35px;
}
@media (max-width: 1699px) {
  footer .footer-center {
    padding: 0px 3% 35px;
  }
}
@media (max-width: 1399px) {
  footer .footer-center {
    padding: 0px 3% 35px;
  }
}
@media (max-width: 1199px) {
  footer .footer-center {
    padding: 0px 3% 35px;
    flex-direction: column;
    gap: 40px;
  }
}
@media (max-width: 991px) {
  footer .footer-center {
    padding: 0px 2% 35px;
  }
}
footer .footer-center .contact-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
}
@media (max-width: 1199px) {
  footer .footer-center .contact-area {
    gap: 40px;
  }
}
@media (max-width: 767px) {
  footer .footer-center .contact-area {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}
footer .footer-center .contact-area .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
footer .footer-center .contact-area .hotline-area .icon {
  position: relative;
}
footer .footer-center .contact-area .hotline-area .icon::after {
  content: "";
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
}
footer .footer-center .contact-area .hotline-area .icon svg {
  fill: var(--primary-color1);
}

.mobile-drawer-phone svg{
  fill: var(--primary-color1);
}
footer .footer-center .contact-area .hotline-area .content {
  line-height: 1;
}
footer .footer-center .contact-area .hotline-area .content span {
  color: #B3B3B3;
  font-size: 14px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}
footer .footer-center .contact-area .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
footer .footer-center .contact-area .hotline-area .content h6 a {
  color: var(--primary-color1);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  line-height: 1;
  transition: 0.35s;
}
footer .footer-center .contact-area .hotline-area .content h6 a:hover {
  color: var(--white-color);
}
footer .footer-center ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (max-width: 1199px) {
  footer .footer-center ul {
    gap: 30px;
  }
}
footer .footer-center ul li {
  line-height: 1;
}
footer .footer-center ul li a {
  color: #B3B3B3;
  font-size: 15px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  transition: 0.35s;
}
footer .footer-center ul li a:hover {
  color: var(--white-color);
}
footer .footer-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 17px 11%;
}
@media (max-width: 1699px) {
  footer .footer-btm {
    padding: 17px 6%;
  }
}
@media (max-width: 1399px) {
  footer .footer-btm {
    padding: 17px 5%;
  }
}
@media (max-width: 1399px) {
  footer .footer-btm {
    padding: 17px 3%;
  }
}
@media (max-width: 991px) {
  footer .footer-btm {
    padding: 17px 2%;
  }
}
@media (max-width: 767px) {
  footer .footer-btm {
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
}
footer .footer-btm .copyright-area p {
  margin-bottom: 0;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-open-sans);
}
footer .footer-btm .copyright-area p a {
  color: var(--primary-color1);
}
footer .footer-btm .social-area {
  display: flex;
  align-items: center;
  gap: 15px;
}
footer .footer-btm .social-area h6 {
  color: var(--white-color);
  font-size: 14px;
  font-family: var(--font-open-sans);
  font-weight: 400;
  margin-bottom: 0;
}
footer .footer-btm .social-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
}
footer .footer-btm .social-area ul li a {
  font-size: 12px;
  width: 26px;
  height: 26px;
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--primary-color1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.35s;
}
footer .footer-btm .social-area ul li a:hover {
  color: var(--title-color);
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
footer.style-2 {
  border-radius: 30px 30px 0 0;
}
footer.style-3 .footer-widget .menu-container ul li a:hover {
  color: var(--white-color);
}
footer.style-3 .footer-widget .menu-container ul li a:hover svg {
  fill: var(--white-color);
  opacity: 1;
}
footer.style-3 .footer-center .contact-area .hotline-area .icon svg {
  fill: var(--white-color);
}
footer.style-3 .footer-center .contact-area .hotline-area .content h6 a {
  color: var(--white-color);
}
footer.style-3 .footer-btm .copyright-area p a {
  color: var(--white-color);
}
footer.style-3 .footer-btm .social-area ul li a {
  color: rgba(255, 255, 255, 0.5);
}
footer.style-3 .footer-btm .social-area ul li a:hover {
  color: var(--title-color);
  background-color: var(--white-color);
  border-color: var(--white-color);
}

/*=====================================
     17. dream Car Section
========================================*/
.explore-btn2 {
  color: var(--title-color);
  font-size: 16px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: 0.35s;
  line-height: 0.7;
  white-space: nowrap;
}
.explore-btn2 i {
  font-size: 20px;
}
.explore-btn2:hover {
  color: var(--primary-color1);
}
.explore-btn2.two:hover {
  color: var(--title-color);
}

.dream-car-area .filter-area {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 35px;
  padding: 18px;
  gap: 15px;
}
.dream-car-area .nav-pills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
}
.dream-car-area .nav-pills li button {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 991px) {
  .dream-car-area .nav-pills li button {
    padding: 0;
  }
}
.dream-car-area .nav-pills li button.active {
  background: transparent;
  color: var(--title-color);
}
.dream-car-area .nav-pills li button:hover {
  background: transparent;
  color: var(--title-color);
}
.dream-car-area .car-category {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
@media (max-width: 576px) {
  .dream-car-area .car-category {
    padding: 25px 20px;
  }
}
.dream-car-area .car-category:hover {
  border: 1px solid rgba(19, 20, 26, 0.5);
}
.dream-car-area .car-category .icon {
  margin-bottom: 20px;
}
.dream-car-area .car-category .content {
  line-height: 1;
}
.dream-car-area .car-category .content h6 {
  color: var(--title-color);
  font-size: 14px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  margin-bottom: 2px;
  line-height: 20px;
}
.dream-car-area .car-category .content span {
  color: var(--text-color);
  font-size: 14px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  margin-bottom: 0;
  line-height: 20px;
}

/*=====================================
     18. dream Car Section
========================================*/
.featured-car-section {
  padding-left: 9%;
  padding-right: 9%;
}
@media (max-width: 1699px) {
  .featured-car-section {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media (max-width: 1399px) {
  .featured-car-section {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.featured-car-section .slider-btn-group2 {
  max-width: 90px;
  width: 100%;
}
.featured-car-section .feature-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.35s;
}
.featured-car-section .feature-card .product-img {
  position: relative;
  border-radius: 10px;
  transition: 0.35s;
}
.featured-car-section .feature-card .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.featured-car-section .feature-card .product-img .number-of-img a {
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
}
.featured-car-section .feature-card .product-img .fav {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-car-section .feature-card .product-img .fav svg {
  fill: var(--title-colort);
  transition: 0.35s;
}
.featured-car-section .feature-card .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.featured-car-section .feature-card .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.featured-car-section .feature-card .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19,20,26,0.9);
  cursor: pointer;
  transition: 0.35s;
}
.featured-car-section .feature-card .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.featured-car-section .feature-card .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.featured-car-section .feature-card .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(19, 20, 26, 0.8) 0%, rgba(19, 20, 26, 0) 100%);
  transition: 0.35s;
}
.featured-car-section .feature-card .product-img img {
  border-radius: 10px;
  height: 270px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .featured-car-section .feature-card .product-img img {
    width: 100%;
  }
}
.featured-car-section .feature-card .product-img .number-of-img img {
  height: 14px;
}
.featured-car-section .feature-card .product-content {
  position: absolute;
  bottom: 20px;
  left: 30px;
  right: 30px;
  z-index: 1;
}
@media (max-width: 1499px) {
  .featured-car-section .feature-card .product-content {
    left: 25px;
    right: 25px;
  }
}
.featured-car-section .feature-card .product-content .price {
  line-height: 1;
  margin-bottom: 8px;
}
.featured-car-section .feature-card .product-content .price strong {
  color: var(--white-color);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 700;
  line-height: 1;
}
.featured-car-section .feature-card .product-content .price strong del {
  font-size: 14px;
  opacity: 0.5;
}
.featured-car-section .feature-card .product-content h5 {
  margin-bottom: 0;
}
.featured-car-section .feature-card .product-content h5 a {
  color: var(--white-color);
  font-size: 18px;
  font-family: var(--font-montserrat);
  font-weight: 600;
}
.featured-car-section .feature-card .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  gap: 13px;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  padding-top: 15px;
  margin-top: 10px;
}
.featured-car-section .feature-card .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--white-color);
  font-size: 13px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  line-height: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .featured-car-section .feature-card .product-content .features li {
    margin-right: 10px;
  }
}
@media (max-width: 1399px) {
  .featured-car-section .feature-card .product-content .features li {
    margin-right: 10px;
  }
}
.featured-car-section .feature-card .product-content .features li:last-child {
  margin-right: 0;
}
.featured-car-section .feature-card .product-content .features li svg {
  fill: var(--white-color);
}
.featured-car-section .feature-card:hover .product-img .number-of-img {
  opacity: 1;
}
.featured-car-section .feature-card:hover .product-img .slider-btn-group {
  opacity: 1;
}
.featured-car-section .feature-card:hover .product-content .features {
  display: flex;
  animation: fade-up 0.45s linear;
}

.slider-btn-group2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-btn-group2 .slider-btn {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(19, 20, 26, 0.16);
  cursor: pointer;
  transition: 0.35s;
}
.slider-btn-group2 .slider-btn svg {
  fill: rgba(19, 20, 26, 0.16);
}
.slider-btn-group2 .slider-btn:hover {
  background-color: var(--primary-color1);
}
.slider-btn-group2 .slider-btn:hover svg {
  fill: var(--title-color);
}
.slider-btn-group2.style-6 {
  max-width: 90px;
  width: 100%;
}
.slider-btn-group2.style-6 .slider-btn:hover {
  background-color: var(--title-color);
}
.slider-btn-group2.style-6 .slider-btn:hover svg {
  fill: var(--white-color);
}

/*=====================================
     19. Home2 Inner Banner Section
========================================*/
.home2-inner-banner {
  padding: 80px 0 90px;
}
.home2-inner-banner .inner-banner-content {
  max-width: 670px;
  width: 100%;
}
.home2-inner-banner .inner-banner-content h2 {
  color: var(--white-color);
  margin-bottom: 8px;
}
.home2-inner-banner .inner-banner-content p {
  color: var(--white-color);
  margin-bottom: 50px;
}

/*=====================================
   20. Recent Launched Cars Section
========================================*/
.recent-launched-car .slider-btn-group2 {
  max-width: 90px;
  width: 100%;
}

.product-card2 {
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 10px;
  transition: 0.35s;
}
.product-card2 .product-img {
  border-radius: 10px;
  position: relative;
  transition: 0.35s;
}
.product-card2 .product-img .date {
  position: absolute;
  left: -89px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg) scaleX(0);
  transition: 0.35s;
  opacity: 0;
}
.product-card2 .product-img .date button {
  color: var(--black-color);
  font-size: 14px;
  font-family: var(--font-open-sans);
  font-weight: 400;
  background-color: var(--primary-color1);
  border-radius: 0px 0px 30px 30px;
  padding: 2px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 190px;
}
.product-card2 .product-img .fav {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 14px;
  transition: 0.35s;
}
.product-card2 .product-img .fav svg {
  fill: var(--title-color);
}
.product-card2 .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.product-card2 .product-img img {
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .product-card2 .product-img img {
    width: 100%;
  }
}
.product-card2 .product-content {
  position: relative;
  padding: 27px 10px 15px 10px;
}
.product-card2 .product-content .company-logo {
  position: absolute;
  right: 20px;
  top: -22px;
}
.product-card2 .product-content .details-btn {
  position: absolute;
  right: 20px;
  top: -19px;
}
.product-card2 .product-content .details-btn a {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #F6F4Fa;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.product-card2 .product-content .details-btn a i {
  font-size: 24px;
  color: #cbc5c5;
  transform: rotate(-45deg);
  transition: 0.35s;
}
.product-card2 .product-content .price {
  line-height: 1;
  margin-bottom: 10px;
}
.product-card2 .product-content .price strong {
  color: var(--title-color);
  font-size: 18px;
  font-family: var(--font-montserrat);
  font-weight: 700;
  line-height: 1;
}
.product-card2 .product-content .price strong del {
  font-size: 12px;
  opacity: 0.5;
  margin-left: 5px;
}
.product-card2 .product-content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.product-card2 .product-content h6 a {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  transition: 0.35s;
  cursor: pointer;
}
.product-card2 .product-content h6 a:hover {
  color: var(--primary-color1);
}
.product-card2.two .product-content {
  padding: 22px 10px 10px 10px;
}
.product-card2.two .product-content .company-logo a {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: centre;
  justify-content: center;
  background-color: #fff;
  padding: 5px;
}
.product-card2:hover {
  border: 1px solid rgba(19, 20, 26, 0.5);
}
.product-card2:hover .product-img .date {
  opacity: 1;
  transform: translateY(-50%) rotate(-90deg) scaleX(1);
}
.product-card2:hover .product-content .details-btn a {
  background-color: var(--primary-color1);
}
.product-card2:hover .product-content .details-btn a i {
  color: var(--title-color);
  transform: rotate(0deg);
}

/*=====================================
   21. Browse Used Car  Section
========================================*/
.browse-used-car-section .browse-car-filter-area {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 35px;
}
.browse-used-car-section .browse-car-filter-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  display: flex;
  justify-content: center;
}
.browse-used-car-section .browse-car-filter-area ul li button {
  padding: 0;
  border: none;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  padding: 7px 25px;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}
.browse-used-car-section .browse-car-filter-area ul li button::after {
  content: "";
  height: 1px;
  width: 65px;
  background-color: var(--title-color);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.browse-used-car-section .browse-car-filter-area ul li button.active {
  background-color: transparent;
  color: var(--title-color);
}
.browse-used-car-section .browse-car-filter-area ul li button.active::after {
  opacity: 1;
}
.browse-used-car-section .browse-car-filter-area ul li button:hover {
  background-color: transparent;
  color: var(--title-color);
}

/*=====================================
   22. How We Work Section
========================================*/
.how-it-work-section {
  background: #F6F4Fa;
  padding: 23px 0;
}
.how-it-work-section.style-2 {
  border-radius: 30px;
  background: #F6F6F6;
}
.how-it-work-section .video-btn a {
  color: var(--white-color);
  font-size: 15px;
  font-family: var(--font-montserrat);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid var(--title-color);
  background: var(--title-color);
  padding: 5px 18px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.5s ease-out 0s;
}
.how-it-work-section .video-btn a i {
  line-height: 1;
  color: var(--white-color);
  transition: all 0.5s ease-out 0s;
}
.how-it-work-section .video-btn a::after {
  position: absolute;
  content: "";
  display: block;
  left: 8%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #fff;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.how-it-work-section .video-btn a:hover {
  color: var(--title-color);
}
.how-it-work-section .video-btn a:hover i {
  color: var(--title-color);
}
.how-it-work-section .video-btn a:hover svg {
  fill: var(--title-color);
}
.how-it-work-section .video-btn a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.how-it-work-section .single-work-process {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.35s;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
}
.how-it-work-section .single-work-process .step {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 1px solid rgba(19, 20, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.how-it-work-section .single-work-process .step span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: var(--title-color);
  font-size: 15px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.how-it-work-section .single-work-process .icon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--white-color);
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  margin-bottom: 13px;
}
.how-it-work-section .single-work-process .content h6 {
  color: var(--title-color);
  font-size: 18px;
  font-family: var(--font-montserrat);
  font-weight: 700;
  margin-bottom: 8px;
}
.how-it-work-section .single-work-process .content p {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-open-sans);
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.how-it-work-section .single-work-process:hover .icon {
  background-color: var(--primary-color1);
}
.how-it-work-section .work-process-group {
  border-bottom: 1px solid #eee;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}
.how-it-work-section .work-process-group::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #eee;
  position: absolute;
  top: 17px;
  left: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .how-it-work-section .work-process-group::after {
    display: none;
    visibility: hidden;
  }
}
.how-it-work-section .trustpilot-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 30px;
}
.how-it-work-section .trustpilot-review > strong {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-color);
}
.how-it-work-section .trustpilot-review p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.how-it-work-section .trustpilot-review p strong {
  font-weight: 600;
}
.how-it-work-section .trustpilot-review p a {
  color: var(--text-color);
  text-decoration: underline;
  transition: 0.35s;
}
.how-it-work-section .trustpilot-review p a strong {
  transition: 0.35s;
}
.how-it-work-section .trustpilot-review p a:hover {
  color: var(--primary-color1);
}
.how-it-work-section.style-3 .work-process-group::after {
  display: none;
  visibility: hidden;
}
.how-it-work-section.style-3 .work-process-group .single-work-process {
  position: relative;
}
.how-it-work-section.style-3 .work-process-group .single-work-process .steps {
  position: absolute;
  right: -51px;
  top: 20px;
}
@media (max-width: 1399px) {
  .how-it-work-section.style-3 .work-process-group .single-work-process .steps {
    right: -28px;
  }
}
@media (max-width: 991px) {
  .how-it-work-section.style-3 .work-process-group .single-work-process .steps {
    position: relative;
    right: unset;
    top: unset;
    margin-bottom: 20px;
  }
}
.how-it-work-section.style-3 .work-process-group .single-work-process .steps::before {
  content: "";
  position: absolute;
  right: 30px;
  width: 90px;
  height: 1px;
  background-color: rgba(29, 29, 29, 0.07);
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
@media (max-width: 991px) {
  .how-it-work-section.style-3 .work-process-group .single-work-process .steps::before {
    display: none;
    visibility: hidden;
  }
}
.how-it-work-section.style-3 .work-process-group .single-work-process .steps::after {
  content: "";
  position: absolute;
  left: 30px;
  width: 90px;
  height: 1px;
  background-color: rgba(29, 29, 29, 0.07);
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
@media (max-width: 991px) {
  .how-it-work-section.style-3 .work-process-group .single-work-process .steps::after {
    display: none;
    visibility: hidden;
  }
}
.how-it-work-section.style-3 .work-process-group .single-work-process .steps span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--black-color2);
  font-size: 15px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(29, 29, 29, 0.2);
}
.how-it-work-section.style-3 .work-process-group .single-work-process:hover .icon {
  background-color: var(--white-color);
}

/*=====================================
   23. Home2 Compar Section
========================================*/
.home2-compare-area {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(img/home2/compire-bg.html);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 90px;
  padding-bottom: 90px;
}
.home2-compare-area .compare-content {
  max-width: 630px;
  margin: 0 auto;
}
.home2-compare-area .compare-content .section-title-2 {
  max-width: 570px;
  width: 100%;
  margin: 0 auto 50px;
}
.home2-compare-area .compare-content .section-title-2 h2 {
  color: var(--white-color);
  margin-bottom: 10px;
}
.home2-compare-area .compare-content .section-title-2 p {
  color: var(--white-color);
}
.home2-compare-area .compare-content .compare-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 576px) {
  .home2-compare-area .compare-content .compare-wrap {
    flex-direction: column;
  }
}
.home2-compare-area .compare-content .compare-wrap .compare-left {
  text-align: end;
}
@media (max-width: 576px) {
  .home2-compare-area .compare-content .compare-wrap .compare-left {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .home2-compare-area .compare-content .compare-wrap .compare-left,
  .home2-compare-area .compare-content .compare-wrap .compare-right {
    text-align: center;
  }
}
.home2-compare-area .compare-content .compare-wrap .compare-left span,
.home2-compare-area .compare-content .compare-wrap .compare-right span {
  color: var(--white-color);
  font-size: 16px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 5px;
}
.home2-compare-area .compare-content .compare-wrap .compare-left h6,
.home2-compare-area .compare-content .compare-wrap .compare-right h6 {
  margin-bottom: 10px;
}
.home2-compare-area .compare-content .compare-wrap .compare-left h6 a,
.home2-compare-area .compare-content .compare-wrap .compare-right h6 a {
  color: var(--white-color);
  font-size: 24px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  transition: 0.35s;
}
.home2-compare-area .compare-content .compare-wrap .compare-left h6 a:hover,
.home2-compare-area .compare-content .compare-wrap .compare-right h6 a:hover {
  color: var(--primary-color1);
}
.home2-compare-area .compare-content .compare-wrap .compare-left .price strong,
.home2-compare-area .compare-content .compare-wrap .compare-right .price strong {
  color: var(--primary-color1);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 600;
}
.home2-compare-area .compare-content .compare-wrap .divider {
  height: 106px;
  width: 6px;
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 90%);
          clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 90%);
}
@media (max-width: 576px) {
  .home2-compare-area .compare-content .compare-wrap .divider {
    height: 6px;
    width: 106px;
    -webkit-clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
            clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
  }
}
.home2-compare-area .compare-content .compare-btm {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.home2-compare-area .compare-content .compare-btm a {
  display: inline-flex;
}
.home2-compare-area .slider-btn-group2 {
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  padding-top: 60px;
}
.home2-compare-area .slider-btn-group2 .slider-btn {
  border: 1px solid #fff;
}
.home2-compare-area .slider-btn-group2 .slider-btn svg {
  fill: var(--white-color);
}
.home2-compare-area .swiper-slide-active .car-img {
  animation: zoomIn 1.7s;
}
.home2-compare-area .swiper-slide-active .section-title-2 {
  animation: fadeInDown 1.7s;
}
.home2-compare-area .swiper-slide-active .compare-wrap {
  animation: fadeInUp 1.7s;
}

/*=====================================
   24. Home2 Testimonial Section
========================================*/
.home2-testimonial-section .slider-btn-group2 {
  max-width: 90px;
  min-width: 90px;
  width: 100%;
}
.home2-testimonial-section .rating {
  min-width: 270px;
}
.home2-testimonial-section .rating a {
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 10px 20px 15px;
}
.home2-testimonial-section .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.home2-testimonial-section .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.home2-testimonial-section .rating a .review-top .star ul li {
  line-height: 1;
}
.home2-testimonial-section .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.home2-testimonial-section .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.home2-testimonial-section .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  position: relative;
}
.home2-testimonial-section .rating a .content ul li span {
  font-weight: 600;
}
.home2-testimonial-section .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.home2-testimonial-section .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.home2-testimonial-section .feedback-card {
  padding: 40px 30px;
  background: #F6F4Fa;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.35s;
  border: 1px solid rgba(238, 238, 238, 0.93);
}
.home2-testimonial-section .feedback-card .feedback-top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 15px;
}
.home2-testimonial-section .feedback-card .feedback-top .stat-area {
  display: flex;
  flex-direction: column;
}
.home2-testimonial-section .feedback-card .feedback-top .stat-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}
.home2-testimonial-section .feedback-card .feedback-top .stat-area ul li {
  line-height: 1;
}
.home2-testimonial-section .feedback-card .feedback-top .stat-area ul li i {
  font-style: 13px;
  color: #DDA701;
}
.home2-testimonial-section .feedback-card .feedback-top .stat-area img {
  margin-bottom: 5px;
}
.home2-testimonial-section .feedback-card .feedback-top .stat-area span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.home2-testimonial-section .feedback-card .feedback-top .services span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.home2-testimonial-section .feedback-card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-color);
  margin-bottom: 15px;
}
.home2-testimonial-section .feedback-card p strong {
  font-weight: 700;
}
.home2-testimonial-section .feedback-card .author-name {
  display: flex;
  align-items: center;
  gap: 6px;
}
.home2-testimonial-section .feedback-card .author-name h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  padding-left: 30px;
  position: relative;
}
.home2-testimonial-section .feedback-card .author-name h6::after {
  content: "";
  height: 1px;
  width: 20px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.home2-testimonial-section .feedback-card .author-name span {
  color: var(--text-color);
  font-size: 12px;
  font-family: var(--font-open-sans);
  font-weight: 600;
}
.home2-testimonial-section .feedback-card:hover {
  border-color: var(--primary-color1);
}

/*=====================================
   25. Home3 BAnner Section
========================================*/
.home3-banner-area {
  position: relative;
}
.home3-banner-area .banner-bg {
  height: 840px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-area .banner-bg {
    height: 650px;
  }
}
@media (max-width: 1399px) {
  .home3-banner-area .banner-bg {
    height: 650px;
  }
}
.home3-banner-area .banner-wrapper {
  position: absolute;
  bottom: 100px;
  z-index: 9;
  width: 100%;
  left: 0;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-area .banner-wrapper {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  .home3-banner-area .banner-wrapper {
    padding: 0px 3%;
  }
}
@media (max-width: 991px) {
  .home3-banner-area .banner-wrapper {
    flex-wrap: wrap;
    gap: 50px;
  }
}
.home3-banner-area .banner-wrapper .banner-content h1 {
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 75px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 15px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-area .banner-wrapper .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 1399px) {
  .home3-banner-area .banner-wrapper .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .home3-banner-area .banner-wrapper .banner-content h1 {
    font-size: 55px;
  }
}
.home3-banner-area .banner-wrapper .banner-content .banner-feature ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.home3-banner-area .banner-wrapper .banner-content .banner-feature ul li {
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  position: relative;
  margin-right: 25px;
  line-height: 1;
}
.home3-banner-area .banner-wrapper .banner-content .banner-feature ul li:last-child {
  margin-right: 0;
}
.home3-banner-area .banner-wrapper .banner-content .banner-feature ul li span {
  color: var(--primary-color1);
}
.home3-banner-area .banner-wrapper .banner-content .banner-feature ul li::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
}
.home3-banner-area .banner-wrapper .banner-content .banner-feature ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 40px;
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review > strong {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--white-color);
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--white-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review p strong {
  font-weight: 600;
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review p a {
  color: var(--white-color);
  text-decoration: underline;
  transition: 0.35s;
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review p a strong {
  transition: 0.35s;
}
.home3-banner-area .banner-wrapper .banner-content .trustpilot-review p a:hover {
  color: var(--primary-color1);
}
@media (max-width: 767px) {
  .home3-banner-area .banner-wrapper .slider-btn-group {
    display: none;
    visibility: hidden;
  }
}
.home3-banner-area .banner-wrapper .slider-btn-group .slider-btn {
  min-width: 34px;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}
.home3-banner-area .banner-wrapper .slider-btn-group .slider-btn svg {
  fill: rgba(255, 255, 255, 0.5);
}
.home3-banner-area .banner-wrapper .slider-btn-group .slider-btn:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.home3-banner-area .banner-wrapper .slider-btn-group .slider-btn:hover svg {
  fill: var(--title-color);
}
.home3-banner-area .banner-wrapper .slider-btn-group .paginations {
  display: flex;
  align-items: center;
  gap: 20px;
}
.home3-banner-area .banner-wrapper .slider-btn-group .swiper-pagination-bullet {
  width: 30px;
  height: 3px;
  background-color: #D9D9D9;
  border-radius: 10px;
  opacity: 1;
}
.home3-banner-area .banner-wrapper .slider-btn-group .swiper-pagination-bullet-active {
  background-color: var(--primary-color1);
}

/*=====================================
   26. Brand Category Section
========================================*/
.brand-category-section .slider-btn-group2 {
  max-width: 90px;
  width: 100%;
}
.brand-category-section .brand-category-card {
  position: relative;
  transition: 0.35s;
  overflow: hidden;
}
.brand-category-section .brand-category-card .category-img {
  transition: 0.35s;
}
.brand-category-section .brand-category-card .category-img img {
  transition: 0.35s;
}
@media (max-width: 576px) {
  .brand-category-section .brand-category-card .category-img img {
    width: 100%;
  }
}
.brand-category-section .brand-category-card .category-img::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.brand-category-section .brand-category-card .batch {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 2;
}
.brand-category-section .brand-category-card .batch span {
  color: var(--black-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  -webkit-clip-path: polygon(100% 0, 93% 51%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 93% 51%, 100% 100%, 0 100%, 0 0);
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  padding: 6px 19px;
}
.brand-category-section .brand-category-card .category-content {
  position: absolute;
  bottom: 25px;
  z-index: 2;
  left: 30px;
  right: 30px;
}
.brand-category-section .brand-category-card .category-content h5 {
  margin-bottom: 0;
}
.brand-category-section .brand-category-card .category-content h5 a {
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  transition: 0.35s;
}
.brand-category-section .brand-category-card .category-content h5 a:hover {
  color: var(--primary-color1);
}
.brand-category-section .brand-category-card .category-content span {
  color: var(--white-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.brand-category-section .brand-category-card:hover .category-img img {
  transform: scale(1.05);
}

/*=====================================
   27. Home3 Featured Car Section
========================================*/
.home3-featured-car-area {
  padding-left: 8%;
  padding-right: 8%;
}
@media (max-width: 1699px) {
  .home3-featured-car-area {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media (max-width: 1399px) {
  .home3-featured-car-area {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.home3-featured-car-area .tab-and-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 70px;
}
@media (max-width: 767px) {
  .home3-featured-car-area .tab-and-btn {
    gap: 25px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.home3-featured-car-area .tab-and-btn ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  gap: 20px;
  flex-wrap: nowrap;
}
@media (max-width: 576px) {
  .home3-featured-car-area .tab-and-btn ul {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.home3-featured-car-area .tab-and-btn ul li button {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  border-radius: 50px;
  border: 1px solid #EEE;
  background: var(--white-color);
  white-space: nowrap;
  padding: 7px 16px;
  line-height: 1;
}
.home3-featured-car-area .tab-and-btn ul li button.active {
  color: var(--white-color);
  background: rgba(19,20,26,0.9);
  border-color: var(--title-color);
}
.home3-featured-car-area .tab-and-btn .slider-btn-group2 {
  max-width: 90px;
  min-width: 90px;
}

.product-card3 {
  position: relative;
  transition: 0.35s;
}
.product-card3 .product-img {
  position: relative;
  transition: 0.35s;
}
.product-card3 .product-img img {
  -o-object-fit: cover;
     object-fit: cover;
}
.product-card3 .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card3 .product-img .number-of-img a {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
}
.product-card3 .product-img .number-of-img a img {
  height: 14px;
}
.product-card3 .product-img .fav {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}
.product-card3 .product-img .fav svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.product-card3 .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.product-card3 .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card3 .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 20, 26, 0.6);
  cursor: pointer;
  transition: 0.35s;
}
.product-card3 .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.product-card3 .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.product-card3 .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50.27%);
  transition: 0.35s;
  opacity: 0;
}
@media (max-width: 767px) {
  .product-card3 .product-img img {
    width: 100%;
  }
}
.product-card3 .product-img .countdown-timer {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  display: flex;
  justify-content: center;
}
@media (max-width: 576px) {
  .product-card3 .product-img .countdown-timer {
    width: 100%;
  }
}
.product-card3 .product-img .countdown-timer .auction-time-countdown {
  width: 100%;
}
.product-card3 .product-img .countdown-timer .countdown_row {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  gap: 5px;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show1 .countdown_section {
  width: 98%;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show2 .countdown_section {
  width: 48% !important;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show3 .countdown_section {
  width: 32.5% !important;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show4 .countdown_section {
  width: 24.5% !important;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show5 .countdown_section {
  width: 25% !important;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show6 .countdown_section {
  width: 16.25% !important;
}
.product-card3 .product-img .countdown-timer .countdown_row .countdown_show7 .countdown_section {
  width: 14% !important;
}
.product-card3 .product-img .countdown-timer .hasCountdown {
  overflow: hidden;
  padding: 0;
}
.product-card3 .product-img .countdown-timer .hasCountdown .countdown_section {
  display: block;
  float: left;
  padding: 7px;
  line-height: 15px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
.product-card3 .product-img .countdown-timer .hasCountdown .countdown_section .countdown_amount {
  font-weight: 700;
}
.product-card3 .product-content {
  padding: 20px 0 0;
}
@media (max-width: 1499px) {
  .product-card3 .product-content {
    padding: 20px 10px 0px;
  }
}
.product-card3 .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}
@media (max-width: 1699px) {
  .product-card3 .product-content .features {
    gap: 15px;
  }
}
@media (max-width: 1399px) {
  .product-card3 .product-content .features {
    gap: 5px;
    flex-wrap: wrap;
  }
}
.product-card3 .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
}
@media (max-width: 1499px) {
  .product-card3 .product-content .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-card3 .product-content .features li {
    padding-right: 5px;
  }
}
.product-card3 .product-content .features li svg {
  fill: var(--text-color);
}
.product-card3 .product-content h5 {
  margin-bottom: 0px;
  line-height: 1;
}
.product-card3 .product-content h5 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: var(--title-color);
  transition: 0.35s;
  cursor: pointer;
}
.product-card3 .product-content h5 a:hover {
  color: var(--primary-color1);
}
.product-card3 .product-content .price-location {
  display: flex;
  align-items: center;
  gap: 30px;
  line-height: 1;
  margin-bottom: 10px;
}
.product-card3 .product-content .price-location .price {
  line-height: 1;
  position: relative;
}
.product-card3 .product-content .price-location .price::after {
  content: "";
  height: 13px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.3);
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}
.product-card3 .product-content .price-location .price strong {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: var(--title-color);
}
.product-card3 .product-content .price-location .price strong del {
  font-size: 14px;
  opacity: 0.5;
}
.product-card3 .product-content .price-location .location {
  line-height: 1;
}
.product-card3 .product-content .price-location .location a {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
}
.product-card3.style-2 .product-content {
  padding: 28px 0 0;
  position: relative;
}
@media (max-width: 1499px) {
  .product-card3.style-2 .product-content {
    padding: 28px 10px 0px;
  }
}
.product-card3.style-2 .product-content h5 {
  margin-bottom: 10px;
}
.product-card3.style-2 .product-content h5 a {
  font-size: 18px;
  color: var(--title-color);
}
.product-card3.style-2 .product-content h5 a:hover {
  color: var(--primary-color1);
}
.product-card3.style-2 .product-content .price-tag {
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 2;
}
.product-card3.style-2 .product-content .price-tag span {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 7% 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 7% 50%);
  background-color: var(--primary-color1);
  height: 30px;
  display: inline-flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 3px 30px;
}
.product-card3.style-2 .product-content .price-tag span del {
  font-size: 12px;
  opacity: 0.5;
  margin-left: 5px;
}
.product-card3.style-2 .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}
@media (max-width: 1699px) {
  .product-card3.style-2 .product-content .features {
    gap: 15px;
  }
}
@media (max-width: 1399px) {
  .product-card3.style-2 .product-content .features {
    gap: 5px;
    flex-wrap: wrap;
  }
}
.product-card3.style-2 .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
}
@media (max-width: 1499px) {
  .product-card3.style-2 .product-content .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-card3.style-2 .product-content .features li {
    padding-right: 5px;
  }
}
.product-card3.style-2 .product-content .features li svg {
  fill: var(--text-color);
}
.product-card3.style-3 .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  padding-top: 10px;
}
@media (max-width: 1699px) {
  .product-card3.style-3 .features {
    gap: 15px;
  }
}
@media (max-width: 1399px) {
  .product-card3.style-3 .features {
    gap: 5px;
    flex-wrap: wrap;
  }
}
.product-card3.style-3 .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
}
@media (max-width: 1499px) {
  .product-card3.style-3 .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-card3.style-3 .features li {
    padding-right: 5px;
  }
}
.product-card3.style-3 .features li svg {
  fill: var(--text-color);
}
.product-card3:hover {
  border-color: var(--primary-color1);
}
.product-card3:hover .product-img .number-of-img {
  opacity: 1;
}
.product-card3:hover .product-img .slider-btn-group {
  opacity: 1;
}
.product-card3:hover .product-img::after {
  opacity: 1;
}

/*=====================================
   28. Home3 Recent Launched Car Section
========================================*/
.home3-recent-launched-section .slider-btn-group2 {
  max-width: 90px;
  min-width: 90px;
  width: 100%;
}
.home3-recent-launched-section .slider-btn-and-filter {
  gap: 40px;
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select {
  height: 32px;
  border-radius: 50px;
  padding-right: 32px;
  padding-left: 16px;
  background-color: var(--title-color);
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select .current {
  color: var(--white-color);
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select .list {
  background-color: var(--title-color);
  border-color: var(--title-color);
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select .list .option {
  color: var(--white-color);
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select .list .option.selected.focus {
  background-color: var(--title-color);
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select:focus {
  border-color: var(--title-color);
}
.home3-recent-launched-section .slider-btn-and-filter .nice-select::after {
  right: 16px;
  border-color: var(--white-color);
}

/*=====================================
   29. Home3 Inner Banner Section
========================================*/
.home3-inner-banner {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 0;
  min-height: 340px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .home3-inner-banner {
    padding: 50px 0;
  }
}
.home3-inner-banner .logo {
  margin-bottom: 45px;
}
.home3-inner-banner .content .price h4 {
  margin-bottom: 10px;
  line-height: 1;
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 700;
}
.home3-inner-banner .content h5 {
  margin-bottom: 30px;
}
.home3-inner-banner .content h5 a {
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  transition: 0.35s;
}
.home3-inner-banner .content h5 a:hover {
  color: var(--primary-color1);
}
.home3-inner-banner .content .primary-btn3 {
  border-radius: 0;
  padding: 10px 28px;
}
.home3-inner-banner .offer-tag {
  position: relative;
  height: 194px;
  width: 194px;
  display: flex;
  align-items: center;
  margin: auto 0;
}
.home3-inner-banner .offer-tag .offer-content {
  height: 145px;
  width: 145px;
  border-radius: 50%;
  border: 2px solid #3A3A43;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
}
.home3-inner-banner .offer-tag .offer-content p {
  margin-bottom: 0;
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.home3-inner-banner .offer-tag .offer-content p strong {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
}
.home3-inner-banner .offer-tag .offer-content span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--primary-color1);
  font-family: var(--font-montserrat);
  font-size: 25px;
  font-weight: 700;
}
.home3-inner-banner .offer-tag .offer-content span i {
  color: var(--white-color);
  font-size: 13px;
}
.home3-inner-banner .product-img {
  padding: 30px 0;
}

/*=====================================
   30. Home3 Compare Section
========================================*/
.home3-compare-section {
  background-image: url(img/home1/why-choose-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home3-compare-section .slider-btn-group2 {
  max-width: 90px;
  width: 100%;
}
.home3-compare-section .home3-compare .compare-img {
  display: flex;
  align-items: center;
  position: relative;
}
.home3-compare-section .home3-compare .compare-img span {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 576px) {
  .home3-compare-section .home3-compare .compare-img .single-img {
    width: 100%;
  }
  .home3-compare-section .home3-compare .compare-img .single-img img {
    width: 100%;
  }
}
.home3-compare-section .home3-compare .compare-content {
  border: 1px solid #EEE;
  background: #FFF;
  padding: 0px 30px 35px;
}
@media (max-width: 1199px) {
  .home3-compare-section .home3-compare .compare-content {
    padding: 0px 20px 35px;
  }
}
.home3-compare-section .home3-compare .compare-content .content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 30px;
  margin-top: -17px;
  z-index: 1;
  position: relative;
}
.home3-compare-section .home3-compare .compare-content .content-top::after {
  content: "";
  height: 30px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.15);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content .brand {
  margin-bottom: 13px;
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content .brand a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  border-radius: 15px;
  border: 1px solid #EEE;
  background: var(--white-color);
  padding: 5px 20px;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content .brand a::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--primary-color1);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content .brand a:hover {
  color: var(--title-color);
  border-color: var(--primary-color1);
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content .brand a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content h6 {
  margin-bottom: 3px;
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  transition: 0.35s;
  line-height: 1;
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content h6 a:hover {
  color: var(--primary-color1);
}
.home3-compare-section .home3-compare .compare-content .content-top .single-content .price h6 {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
}
.home3-compare-section .home3-compare .compare-content .primary-btn3 {
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 0;
  padding: 7px 28px;
  width: 100%;
}

/*=====================================
   31. Home3 Testimonial Section
========================================*/
.home3-testimonial-area {
  position: relative;
}
@media (max-width: 991px) {
  .home3-testimonial-area {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .home3-testimonial-area .slider-btn {
    display: none;
    visibility: hidden;
  }
}
.home3-testimonial-area .sm-img-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 991px) {
  .home3-testimonial-area .sm-img-group {
    display: none;
    visibility: hidden;
  }
}
.home3-testimonial-area .sm-img-group li {
  position: absolute;
}
.home3-testimonial-area .sm-img-group li img {
  border-radius: 50%;
}
.home3-testimonial-area .sm-img-group li:nth-child(1) {
  top: 0;
  left: 20%;
}
.home3-testimonial-area .sm-img-group li:nth-child(1) img {
  width: 92px;
  height: 92px;
}
.home3-testimonial-area .sm-img-group li:nth-child(2) {
  top: 0;
  right: 20%;
}
.home3-testimonial-area .sm-img-group li:nth-child(2) img {
  width: 74px;
  height: 74px;
}
.home3-testimonial-area .sm-img-group li:nth-child(3) {
  right: 8%;
  top: 18%;
}
.home3-testimonial-area .sm-img-group li:nth-child(3) img {
  width: 54px;
  height: 54px;
}
.home3-testimonial-area .sm-img-group li:nth-child(4) {
  left: 8%;
  bottom: 30%;
}
.home3-testimonial-area .sm-img-group li:nth-child(4) img {
  width: 68px;
  height: 68px;
}
.home3-testimonial-area .sm-img-group li:nth-child(5) {
  bottom: 23%;
  left: 25%;
}
@media (max-width: 1399px) {
  .home3-testimonial-area .sm-img-group li:nth-child(5) {
    display: none;
    visibility: hidden;
  }
}
.home3-testimonial-area .sm-img-group li:nth-child(5) img {
  width: 54px;
  height: 54px;
}
.home3-testimonial-area .sm-img-group li:nth-child(6) {
  right: 11%;
  bottom: 12%;
}
.home3-testimonial-area .sm-img-group li:nth-child(6) img {
  width: 90px;
  height: 90px;
}
.home3-testimonial-area .testimonial-slider3 {
  max-width: 740px;
  width: 100%;
  margin: 0 auto 50px;
}
.home3-testimonial-area .testimonial-wrap .author-image-area {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
.home3-testimonial-area .testimonial-wrap .author-image-area .quat {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--title-color);
  background-color: var(--white-color);
  position: absolute;
  right: -13px;
  top: 25%;
}
.home3-testimonial-area .testimonial-wrap .author-image-area > img {
  height: 79px;
  width: 79px;
  border-radius: 50%;
}
.home3-testimonial-area .testimonial-wrap .review {
  margin-bottom: 10px;
}
.home3-testimonial-area .testimonial-wrap .review h6 {
  color: rgba(19,20,26,0.9);
  text-align: center;
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 3px;
}
.home3-testimonial-area .testimonial-wrap .content {
  margin-bottom: 15px;
}
.home3-testimonial-area .testimonial-wrap .content p {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .home3-testimonial-area .testimonial-wrap .content p {
    font-sizw: 20px;
    line-height: 33px;
  }
}
.home3-testimonial-area .testimonial-wrap .author-name h5 {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.home3-testimonial-area .trustpilot-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.home3-testimonial-area .trustpilot-review > strong {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-color);
}
.home3-testimonial-area .trustpilot-review p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.home3-testimonial-area .trustpilot-review p strong {
  font-weight: 600;
}
.home3-testimonial-area .trustpilot-review p a {
  color: var(--text-color);
  text-decoration: underline;
}

/*=====================================
   32. Home4 Banner Section
========================================*/
.home4-banner-area {
  position: relative;
}
.home4-banner-area .video-area {
  position: relative;
}
.home4-banner-area .video-area::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.home4-banner-area video {
  width: 100%;
}
@media (max-width: 767px) {
  .home4-banner-area video {
    min-height: 650px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.home4-banner-area .banner-content {
  max-width: 970px;
  width: 100%;
  position: absolute;
  top: 39%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
@media (max-width: 576px) {
  .home4-banner-area .banner-content {
    top: 180px;
  }
}
.home4-banner-area .banner-content h1 {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  line-height: 1.2;
  color: var(--white-color);
  margin-bottom: 8px;
}
@media (max-width: 1399px) {
  .home4-banner-area .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .home4-banner-area .banner-content h1 {
    font-size: 50px;
  }
}
.home4-banner-area .banner-content p {
  font-family: var(--font-open-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7;
  color: var(--white-color);
  margin-bottom: 40px;
}
.home4-banner-area .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 55px;
}
@media (max-width: 576px) {
  .home4-banner-area .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.home4-banner-area .banner-content .banner-content-bottom .rating a {
  display: inline-block;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
  position: relative;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .content ul li span {
  font-weight: 600;
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--white-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.home4-banner-area .banner-content .banner-content-bottom .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}

/*=====================================
   33. Home4 Available Car Section
========================================*/
.explore-more-btn a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  transition: 0.35s;
  white-space: nowrap;
}
.explore-more-btn a:hover {
  color: var(--primary-color1);
}
.explore-more-btn.two {
  position: relative;
}
.explore-more-btn.two::after {
  content: "";
  width: 1px;
  height: 34px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1199px) {
  .explore-more-btn.three::after {
    display: none;
    visibility: hidden;
  }
}

.home3-available-car .sub-title h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
}
.home3-available-car .sub-title.two {
  margin-bottom: 20px;
}
.home3-available-car .explore-btn {
  padding-top: 15px;
}
.home3-available-car .explore-btn a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.35s;
}
.home3-available-car .explore-btn a i {
  transition: 0.35s;
  font-weight: 700;
}
.home3-available-car .explore-btn a:hover {
  color: var(--primary-color1);
}
.home3-available-car .explore-btn a:hover i {
  color: var(--primary-color1);
}
.home3-available-car .slide-item {
  padding: 15px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home3-available-car .slide-item {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .home3-available-car .slide-item {
    padding: 10px;
  }
}
.home3-available-car .car-category {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
@media (max-width: 767px) {
  .home3-available-car .car-category {
    padding: 25px 20px;
  }
}
.home3-available-car .car-category:hover {
  border: 1px solid var(--primary-color1);
}
.home3-available-car .car-category .icon {
  margin-bottom: 20px;
}
.home3-available-car .car-category .content {
  line-height: 1;
}
.home3-available-car .car-category .content h6 {
  color: var(--title-color);
  font-size: 14px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  margin-bottom: 2px;
  line-height: 20px;
}
.home3-available-car .car-category .content span {
  color: var(--text-color);
  font-size: 14px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  margin-bottom: 0;
  line-height: 20px;
}
.home3-available-car .car-filter-area {
  background-image: url(img/home4/search-area-bg.png), linear-gradient(180deg, #f6f4fa 0%, #f6f4fa 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 60px 140px;
  border-radius: 10px;
}
@media (max-width: 1399px) {
  .home3-available-car .car-filter-area {
    padding: 40px 40px 45px;
  }
}
@media (max-width: 576px) {
  .home3-available-car .car-filter-area {
    padding: 30px 20px 35px;
  }
}
.home3-available-car .car-filter-area h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 20px;
}
.home3-available-car .car-filter-area .slider-btn i {
  color: #5E5E5E;
  transition: 0.35s;
}
.home3-available-car .car-filter-area .slider-btn i:hover {
  color: var(--title-color);
}
.home3-available-car .car-filter-area .form-inner label {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}
.home3-available-car .car-filter-area .form-inner label span {
  color: var(--text-color);
}
.home3-available-car .car-filter-area .form-inner .single-category {
  border-radius: 5px;
  background: #FFF;
  text-align: center;
  padding: 10px;
  position: relative;
  cursor: pointer;
}
.home3-available-car .car-filter-area .form-inner .single-category .check-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
          clip-path: polygon(0 0, 0% 100%, 100% 0);
  border-top-left-radius: 5px;
  opacity: 0;
}
.home3-available-car .car-filter-area .form-inner .single-category .check-icon img {
  margin-top: -11px;
  margin-left: -11px;
}
.home3-available-car .car-filter-area .form-inner .single-category .icon {
  margin-bottom: -30px;
}
.home3-available-car .car-filter-area .form-inner .single-category .icon img {
  height: 24px;
}
.home3-available-car .car-filter-area .form-inner .single-category span {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.home3-available-car .car-filter-area .form-inner .single-category input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
}
.home3-available-car .car-filter-area .form-inner .single-category input[type=radio]:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home3-available-car .car-filter-area .form-inner .selected .single-category .check-icon {
  opacity: 1;
}
.home3-available-car .car-filter-area .form-inner .primary-btn1 {
  font-size: 16px;
  font-family: var(--font-montserrat);
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px 20px;
}
.home3-available-car .car-filter-area .advanced-btn {
  padding-top: 12px;
}
.home3-available-car .car-filter-area .advanced-btn button {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  transition: 0.35s;
  background-color: transparent;
}
.home3-available-car .car-filter-area .advanced-btn button svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.home3-available-car .car-filter-area .advanced-btn button:hover {
  color: var(--primary-color1);
}
.home3-available-car .car-filter-area .advanced-btn button:hover svg {
  fill: var(--primary-color1);
}

.product-card4 {
  padding: 10px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  transition: 0.35s;
  position: relative;
}
.product-card4 .product-img {
  position: relative;
  transition: 0.35s;
}
.product-card4 .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card4 .product-img .number-of-img a {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
}
.product-card4 .product-img .number-of-img a img {
  height: 14px;
}
.product-card4 .product-img .fav {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}
.product-card4 .product-img .fav svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.product-card4 .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.product-card4 .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card4 .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 20, 26, 0.6);
  cursor: pointer;
  transition: 0.35s;
}
.product-card4 .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.product-card4 .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.product-card4 .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50.27%);
  transition: 0.35s;
  opacity: 0;
  border-radius: 5px 5px 0 0;
}
.product-card4 .product-img img {
  border-radius: 5px 5px 0 0;
}
@media (max-width: 767px) {
  .product-card4 .product-img img {
    width: 100%;
  }
}
.product-card4 .product-content {
  padding: 25px 15px 15px;
}
@media (max-width: 1499px) {
  .product-card4 .product-content {
    padding: 25px 10px 15px;
  }
}
.product-card4 .product-content .location {
  margin-bottom: 12px;
}
.product-card4 .product-content .location a {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.35s;
}
.product-card4 .product-content .location a:hover {
  color: var(--primary-color1);
}
.product-card4 .product-content h6 {
  margin-bottom: 14px;
}
.product-card4 .product-content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
  transition: 0.35s;
}
.product-card4 .product-content h6 a:hover {
  color: var(--primary-color1);
}
.product-card4 .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  padding-bottom: 15px;
  padding-top: 12px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.product-card4 .product-content .features::after {
  content: "";
  background: radial-gradient(50% 50% at 50% 50%, #EEE 0%, rgba(238, 238, 238, 0.2) 100%);
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  bottom: 0;
  transform: translateX(-50%);
}
.product-card4 .product-content .features::before {
  content: "";
  background: radial-gradient(50% 50% at 50% 50%, #EEE 0%, rgba(238, 238, 238, 0.2) 100%);
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  top: 0;
  transform: translateX(-50%);
}
.product-card4 .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin-right: 15px;
}
@media (max-width: 1499px) {
  .product-card4 .product-content .features li {
    font-size: 12px;
  }
}
.product-card4 .product-content .button-and-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-card4 .product-content .button-and-price .primary-btn3 {
  font-size: 15px;
  font-family: var(--font-montserrat);
  padding: 5px 14px;
}
.product-card4 .product-content .button-and-price .price-area {
  line-height: 1;
  position: relative;
  padding-left: 12px;
}
.product-card4 .product-content .button-and-price .price-area span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}
.product-card4 .product-content .button-and-price .price-area h6 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
}
.product-card4 .product-content .button-and-price .price-area del {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  padding-top: 8px;
  display: inline-block;
}
.product-card4.style-2 .product-content h6 {
  margin-bottom: 0px;
}
.product-card4.style-2 .product-content .exp-date {
  display: flex;
}
.product-card4.style-2 .product-content .exp-date p {
  margin-bottom: 14px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
}
.product-card4.style-2 .product-content .exp-date p span {
  color: var(--title-color);
  display: inline-block;
  padding-left: 40px;
  position: relative;
}
.product-card4.style-2 .product-content .exp-date p span::after {
  content: "";
  height: 2px;
  width: 20px;
  opacity: 0.200000003;
  background: #5E5E5E;
  position: absolute;
  top: 50%;
  left: 10px;
}
.product-card4.style-2 .product-content .button-and-price .primary-btn3 {
  font-size: 15px;
  font-family: var(--font-montserrat);
  padding: 5px 18px;
}
.product-card4:hover {
  border-color: var(--primary-color1);
}
.product-card4:hover .product-img .number-of-img {
  opacity: 1;
}
.product-card4:hover .product-img .fav {
  opacity: 1;
}
.product-card4:hover .product-img .slider-btn-group {
  opacity: 1;
}
.product-card4:hover .product-img::after {
  opacity: 1;
}
.product-card4.style-3 .product-img {
  position: relative;
  transition: 0.35s;
}
.product-card4.style-3 .product-img .fav:hover {
  background-color: var(--title-color);
}
.product-card4.style-3 .product-img .fav:hover svg {
  fill: var(--white-color);
}
.product-card4.style-3 .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card4.style-3 .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--title-color);
}
.product-card4.style-3 .product-content .location a:hover {
  color: var(--title-color);
}
.product-card4.style-3 .product-content h6 a:hover {
  color: var(--title-color);
}
.product-card4.style-3 .product-content .button-and-price .primary-btn7 {
  padding: 11px 14px;
}
.product-card4.style-3:hover {
  border-color: var(--title-color);
}
.product-card4.style-3:hover .product-img .number-of-img {
  opacity: 1;
}
.product-card4.style-3:hover .product-img .fav {
  opacity: 1;
}
.product-card4.style-3:hover .product-img .slider-btn-group {
  opacity: 1;
}
.product-card4.style-3:hover .product-img::after {
  opacity: 1;
}

.slider-btn-pagination {
  display: flex;
  align-items: center;
  gap: 15px;
}
.slider-btn-pagination .slider-btn {
  line-height: 1;
}
.slider-btn-pagination .slider-btn i {
  line-height: 1;
  font-size: 24px;
  vertical-align: -webkit-baseline-middle;
  color: var(--title-color);
  transition: 0.35s;
}
.slider-btn-pagination .slider-btn i:hover {
  color: var(--primary-color1);
}
.slider-btn-pagination .pagination {
  width: unset;
}
.slider-btn-pagination .pagination .swiper-pagination-bullet {
  height: 8px;
  width: 8px;
  background-color: #E9E9E9;
  opacity: 1;
}
.slider-btn-pagination .pagination .swiper-pagination-bullet-active {
  height: 10px;
  width: 10px;
  background-color: var(--primary-color1);
}

/*=====================================
   34. Home4 Latest Car Section
========================================*/
.used-car-filter-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: none;
}
.used-car-filter-area ul li .nav-link {
  padding: 0;
  border: none;
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  border-radius: 25px;
  padding: 10px 20px;
  line-height: 1;
}
.used-car-filter-area ul li .nav-link.active {
  background-color: var(--title-color);
  color: var(--white-color);
}

/*=====================================
   35. Home4 Inner Banner Section
========================================*/
.home4-inner-banner-section {
  background: rgba(19,20,26,0.9);
  padding: 40px 0;
}
.home4-inner-banner-section .section-title-2 {
  max-width: 600px;
  width: 100%;
}
.home4-inner-banner-section .section-title-2 h2 {
  color: var(--white-color);
  margin-bottom: 12px;
}
.home4-inner-banner-section .section-title-2 p {
  color: var(--white-color);
  margin-bottom: 40px;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 576px) {
  .home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .primary-btn3 {
  color: var(--title-color);
  padding: 10px 27px;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .primary-btn3 svg {
  fill: var(--title-color);
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .primary-btn3:hover::after {
  background-color: var(--white-color);
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info > span {
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .icon {
  position: relative;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .icon::after {
  content: "";
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .icon svg {
  fill: var(--primary-color1);
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .content {
  line-height: 1;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .content span {
  color: #B3B3B3;
  font-size: 14px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .content h6 a {
  color: var(--primary-color1);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  line-height: 1;
  transition: 0.35s;
}
.home4-inner-banner-section .section-title-2 .sell-btn-and-contact-info .hotline-area .content h6 a:hover {
  color: var(--white-color);
}
.home4-inner-banner-section .inner-banner-img {
  position: relative;
}
.home4-inner-banner-section .inner-banner-img .background-img {
  width: 326px;
  height: 300px;
  border-radius: 50%;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home4-inner-banner-section .inner-banner-img .background-img img {
  width: 308px;
  height: 282px;
  border-radius: 50%;
}
.home4-inner-banner-section .inner-banner-img .car-img {
  position: absolute;
  bottom: 15px;
  right: 92px;
  max-width: 378px;
  width: 100%;
}
@media (max-width: 576px) {
  .home4-inner-banner-section .inner-banner-img .car-img {
    right: 30px;
    bottom: 25px;
  }
}
.home4-inner-banner-section.style-2 {
  background-color: var(--black-color2);
}
.home4-inner-banner-section.style-2 .section-title-2 {
  max-width: 600px;
  width: 100%;
}
.home4-inner-banner-section.style-2 .section-title-2 h2 {
  color: var(--white-color);
  margin-bottom: 12px;
}
.home4-inner-banner-section.style-2 .section-title-2 p {
  color: var(--white-color);
  margin-bottom: 40px;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 576px) {
  .home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .primary-btn6 {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: var(--white-color);
  padding: 10px 27px;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .primary-btn6 svg {
  fill: var(--white-color);
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .primary-btn6:hover {
  color: var(--title-color);
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .primary-btn6:hover svg {
  fill: var(--title-color);
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .primary-btn6:hover::after {
  background-color: var(--white-color);
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info > span {
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .icon {
  position: relative;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .icon::after {
  content: "";
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .icon svg {
  fill: var(--white-color);
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .content {
  line-height: 1;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .content span {
  color: #B3B3B3;
  font-size: 13px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .content h6 a {
  color: var(--white-color);
  font-size: 20px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  line-height: 1;
  transition: 0.35s;
}
.home4-inner-banner-section.style-2 .section-title-2 .sell-btn-and-contact-info .hotline-area .content h6 a:hover {
  color: var(--white-color);
}

/*=====================================
   36. Home4 Compare Section
========================================*/
.home4-comparing-car-area {
  background: #F6F4Fa;
}
.home4-comparing-car-area .home4-compare {
  border-radius: 5px 5px 0 0;
}
.home4-comparing-car-area .home4-compare .compare-img {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px 5px 0 0;
}
@media (max-width: 576px) {
  .home4-comparing-car-area .home4-compare .compare-img .single-img {
    width: 100%;
  }
  .home4-comparing-car-area .home4-compare .compare-img .single-img img {
    width: 100%;
  }
}
.home4-comparing-car-area .home4-compare .compare-img .single-img.left img {
  border-radius: 5px 0 0 0;
}
.home4-comparing-car-area .home4-compare .compare-img .single-img.right img {
  border-radius: 0 5px 0 0;
}
.home4-comparing-car-area .home4-compare .compare-content {
  border: 1px solid #EEE;
  background: #FFF;
  padding: 20px 30px 25px;
}
@media (max-width: 1199px) {
  .home4-comparing-car-area .home4-compare .compare-content {
    padding: 20px 20px 25px;
  }
}
.home4-comparing-car-area .home4-compare .compare-content .content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;
  z-index: 1;
  position: relative;
}
.home4-comparing-car-area .home4-compare .compare-content .content-top::after {
  content: "";
  height: 30px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.15);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
}
.home4-comparing-car-area .home4-compare .compare-content .content-top .single-content h6 {
  margin-bottom: 3px;
}
.home4-comparing-car-area .home4-compare .compare-content .content-top .single-content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  transition: 0.35s;
  line-height: 1;
}
.home4-comparing-car-area .home4-compare .compare-content .content-top .single-content h6 a:hover {
  color: var(--primary-color1);
}
.home4-comparing-car-area .home4-compare .compare-content .content-top .single-content .price h6 {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
}
.home4-comparing-car-area .home4-compare .compare-content .primary-btn3 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 7px 28px;
  width: 100%;
}

/*=====================================
   37. Home5 banner Section
========================================*/
.home5-banner-area {
  position: relative;
  border-radius: 30px;
}
@media (max-width: 767px) {
  .home5-banner-area {
    border-radius: 0;
  }
}
.home5-banner-area .paginations111 {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: 2;
  width: unset;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 20px;
}
.home5-banner-area .paginations111 .swiper-pagination-bullet {
  position: relative;
  opacity: 1;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.home5-banner-area .paginations111 .swiper-pagination-bullet::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.5);
}
.home5-banner-area .paginations111 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--primary-color1);
}
.home5-banner-area .paginations111 .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  background-color: var(--primary-color1);
}
.home5-banner-area .banner-bg {
  height: 633px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 30px;
}
@media (max-width: 767px) {
  .home5-banner-area .banner-bg {
    border-radius: 0;
  }
}
.home5-banner-area .banner-content {
  max-width: 970px;
  width: 100%;
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.home5-banner-area .banner-content h1 {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  line-height: 1.2;
  color: var(--white-color);
  margin-bottom: 8px;
}
@media (max-width: 1399px) {
  .home5-banner-area .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .home5-banner-area .banner-content h1 {
    font-size: 50px;
  }
}
.home5-banner-area .banner-content p {
  font-family: var(--font-open-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7;
  color: var(--white-color);
  margin-bottom: 40px;
}
.home5-banner-area .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 55px;
}
@media (max-width: 576px) {
  .home5-banner-area .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.home5-banner-area .banner-content .banner-content-bottom .rating a {
  display: inline-block;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
  position: relative;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .content ul li span {
  font-weight: 600;
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--white-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.home5-banner-area .banner-content .banner-content-bottom .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}

/*=====================================
   38. Home5 Featured Car Section
========================================*/
.home5-featured-cars-section .slider-btn-group2 {
  max-width: 90px;
  width: 100%;
}

.product-card5 {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: #FFF;
  padding: 10px;
  transition: 0.35s;
}
.product-card5 .product-img {
  position: relative;
  border-radius: 5px;
  transition: 0.35s;
}
.product-card5 .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card5 .product-img .number-of-img a {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
}
.product-card5 .product-img .fav {
  position: absolute;
  top: 17px;
  left: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-card5 .product-img .fav svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.product-card5 .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.product-card5 .product-img .product-price {
  position: absolute;
  right: -1px;
  bottom: 0;
  z-index: 2;
  line-height: 1;
  display: flex;
  transition: 0.35s;
}
.product-card5 .product-img .product-price span {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  background-color: var(--white-color);
  padding: 4px 23px;
  transition: 0.35s;
}
.product-card5 .product-img .product-price span del {
  font-size: 80%;
  opacity: 0.5;
}
.product-card5 .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-card5 .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 20, 26, 0.6);
  cursor: pointer;
  transition: 0.35s;
}
.product-card5 .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.product-card5 .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.product-card5 .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50.27%);
  transition: 0.35s;
  opacity: 0;
}
.product-card5 .product-img img {
  border-radius: 5px 5px 0 0;
}
@media (max-width: 991px) {
  .product-card5 .product-img img {
    width: 100%;
  }
}
.product-card5 .product-content {
  padding: 15px;
}
@media (max-width: 1499px) {
  .product-card5 .product-content {
    padding: 15px 10px;
  }
}
.product-card5 .product-content .location {
  padding-top: 8px;
  margin-bottom: 8px;
}
.product-card5 .product-content .location a {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.35s;
}
.product-card5 .product-content .location a:hover {
  color: var(--primary-color1);
}
.product-card5 .product-content h6 {
  margin-bottom: 15px;
  line-height: 1;
}
.product-card5 .product-content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: var(--title-color);
  transition: 0.35s;
}
.product-card5 .product-content h6 a:hover {
  color: var(--primary-color1);
}
.product-card5 .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 24px;
  position: relative;
  -moz-columns: 2;
       columns: 2;
  padding-bottom: 10px;
  padding-top: 18px;
}
.product-card5 .product-content .features::before {
  content: "";
  background-color: #eee;
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  top: 0;
  transform: translateX(-50%);
}
.product-card5 .product-content .features::after {
  content: "";
  background-color: #eee;
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  bottom: 0;
  transform: translateX(-50%);
}
.product-card5 .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin-bottom: 8px;
}
@media (max-width: 1499px) {
  .product-card5 .product-content .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-card5 .product-content .features li {
    padding-right: 5px;
  }
}
.product-card5 .product-content .features li svg {
  fill: var(--text-color);
}
.product-card5 .product-content .content-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.product-card5 .product-content .content-btm .view-btn2 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.35s;
}
.product-card5 .product-content .content-btm .view-btn2 svg path {
  fill: var(--primary-color1);
}
.product-card5 .product-content .content-btm .view-btn2 svg path:first-child {
  fill: none;
  stroke: var(--primary-color1);
}
.product-card5 .product-content .content-btm .view-btn2:hover {
  color: var(--primary-color1);
}
.product-card5.two .product-content h6 {
  margin-bottom: 5px;
  padding-top: 8px;
}
.product-card5.two .product-content .exp-date {
  display: flex;
  position: relative;
}
.product-card5.two .product-content .exp-date::after {
  content: "";
  height: 1px;
  width: 70%;
  background-color: #eee;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.product-card5.two .product-content .exp-date p {
  margin-bottom: 14px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
}
.product-card5.two .product-content .exp-date p span {
  color: var(--title-color);
  display: inline-block;
  padding-left: 40px;
  position: relative;
}
.product-card5.two .product-content .exp-date p span::after {
  content: "";
  height: 2px;
  width: 20px;
  opacity: 0.200000003;
  background: #5E5E5E;
  position: absolute;
  top: 50%;
  left: 10px;
}
.product-card5:hover {
  border-color: var(--primary-color1);
}
.product-card5:hover .product-img .number-of-img {
  opacity: 1;
}
.product-card5:hover .product-img .product-price span {
  background-color: var(--primary-color1);
}
.product-card5:hover .product-img .slider-btn-group {
  opacity: 1;
}
.product-card5:hover .product-img::after {
  opacity: 1;
}

/*=====================================
   39. Home5 Brand Category Section
========================================*/
.home5-brand-category-area .filter-and-slider-btn {
  display: flex;
  align-items: center;
  gap: 60px;
}
.home5-brand-category-area .slider-btn-group2 {
  max-width: 90px;
  min-width: 90px;
  width: 100%;
}

.single-category5 {
  border-radius: 10px;
  border: 1px solid #EEE;
  background: var(--white-color);
  padding: 25px;
  transition: 0.35s;
  position: relative;
  overflow: hidden;
}
.single-category5 .category-icon {
  margin-bottom: 20px;
}
.single-category5 .category-content {
  border-bottom: 1px solid #EEE;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.single-category5 .category-content h5 {
  margin-bottom: 0;
  line-height: 1;
}
.single-category5 .category-content h5 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
  transition: 0.35s;
}
.single-category5 .category-content h5 a:hover {
  color: var(--primary-color1);
}
.single-category5 .category-content span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}
.single-category5 .explore-btn5 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  transition: 0.35s;
}
.single-category5 .explore-btn5 a span {
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.35s;
  width: 0;
  overflow: hidden;
}
.single-category5 .explore-btn5 a i {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color1);
  font-size: 20px;
  transform: rotate(-45deg);
  transition: 0.35s;
}
.single-category5::before {
  content: "";
  position: absolute;
  right: -23px;
  top: -15px;
  width: 66px;
  height: 59px;
  border-radius: 50%;
  border: 1px solid #eee;
  transition: 0.35s;
}
.single-category5::after {
  content: "";
  position: absolute;
  right: -20px;
  top: -15px;
  width: 60px;
  height: 53px;
  border-radius: 50%;
  background-color: #EEEEEE;
  transition: 0.35s;
}
.single-category5:hover {
  border-color: var(--primary-color1);
}
.single-category5:hover .explore-btn5 a span {
  transform: scaleX(1);
  padding-right: 10px;
  width: 100%;
}
.single-category5:hover .explore-btn5 a i {
  background-color: var(--primary-color1);
}
.single-category5:hover::before {
  border-color: var(--primary-color1);
}
.single-category5:hover::after {
  background-color: var(--primary-color1);
}

/*=====================================
   40. Home5 About Section
========================================*/
.home5-why-drivco-area .drivco-content {
  max-width: 520px;
  width: 100%;
}
.home5-why-drivco-area .drivco-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.home5-why-drivco-area .drivco-content ul .single-feature {
  display: flex;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 20px;
  transition: 0.35s;
}
.home5-why-drivco-area .drivco-content ul .single-feature:last-child {
  margin-bottom: 0;
}
.home5-why-drivco-area .drivco-content ul .single-feature .feature-icon {
  padding-left: 17px;
  position: relative;
  transition: 0.35s;
}
.home5-why-drivco-area .drivco-content ul .single-feature .feature-icon::before {
  content: "";
  height: 36px;
  width: 2px;
  background-color: rgba(94, 94, 94, 0.15);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.35s;
}
.home5-why-drivco-area .drivco-content ul .single-feature .feature-content h5 {
  margin-bottom: 5px;
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 500;
}
.home5-why-drivco-area .drivco-content ul .single-feature .feature-content p {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}
.home5-why-drivco-area .drivco-content ul .single-feature:hover .feature-icon::before {
  background-color: var(--primary-color1);
}
.home5-why-drivco-area .drivco-img {
  position: relative;
  z-index: 1;
}
.home5-why-drivco-area .drivco-img::before {
  content: "";
  height: 87px;
  width: 87px;
  border-radius: 15px 0 0 0;
  background-color: var(--primary-color1);
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: -1;
}
.home5-why-drivco-area .drivco-img::after {
  content: "";
  height: 87px;
  width: 87px;
  border-radius: 0 0 15px 0;
  background-color: var(--primary-color1);
  position: absolute;
  right: -10px;
  bottom: -10px;
  z-index: -1;
}
.home5-why-drivco-area .drivco-img img {
  border-radius: 10px;
}

/*=====================================
   41. Home5 Recent Section
========================================*/
.home5-recent-car-section {
  position: relative;
}
.home5-recent-car-section .slide-item {
  padding: 15px;
}
.home5-recent-car-section .slick-arrow {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(19, 20, 26, 0.16);
  cursor: pointer;
  transition: 0.35s;
}
.home5-recent-car-section .slick-arrow.slick-prev {
  left: unset;
  transform: unset;
  right: 220px;
  top: -84px;
}
.home5-recent-car-section .slick-arrow.slick-prev::before {
  content: url(img/home5/icon/slick-prev.svg);
  opacity: 0.16;
  transition: 0.35s;
}
.home5-recent-car-section .slick-arrow.slick-next {
  right: unset;
  transform: unset;
  right: 162px;
  top: -84px;
}
.home5-recent-car-section .slick-arrow.slick-next::before {
  content: url(img/home5/icon/slick-next.svg);
  opacity: 0.16;
  transition: 0.35s;
}
.home5-recent-car-section .slick-arrow:hover {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.home5-recent-car-section .slick-arrow:hover::before {
  opacity: 1;
}

.product-st-card1 {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: var(--white-color);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
  transition: 0.35s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-st-card1 {
    flex-direction: column;
    align-items: start;
  }
}
@media (max-width: 576px) {
  .product-st-card1 {
    flex-direction: column;
    align-items: start;
  }
}
.product-st-card1 .product-img {
  position: relative;
  border-radius: 5px 0 0 5px;
  transition: 0.35s;
  max-width: 264px;
  width: 100%;
}
@media (max-width: 1399px) {
  .product-st-card1 .product-img {
    max-width: 230px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-st-card1 .product-img {
    max-width: unset;
    border-radius: 5px 5px 0 0;
  }
}
@media (max-width: 576px) {
  .product-st-card1 .product-img {
    max-width: unset;
    border-radius: 5px 5px 0 0;
  }
}
.product-st-card1 .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-st-card1 .product-img .number-of-img a {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
}
.product-st-card1 .product-img .number-of-img a img {
  height: 14px;
}
.product-st-card1 .product-img .fav {
  position: absolute;
  top: 17px;
  left: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}
.product-st-card1 .product-img .fav svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.product-st-card1 .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.product-st-card1 .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
  opacity: 0;
}
.product-st-card1 .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 20, 26, 0.6);
  cursor: pointer;
  transition: 0.35s;
}
.product-st-card1 .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.product-st-card1 .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.product-st-card1 .product-img .product-price {
  position: absolute;
  left: -10px;
  bottom: 0;
  z-index: 2;
  line-height: 1;
  display: flex;
  transition: 0.35s;
}
.product-st-card1 .product-img .product-price span {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  background-color: var(--white-color);
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  padding: 4px 23px;
  transition: 0.35s;
}
.product-st-card1 .product-img .product-price span del {
  font-size: 12px;
  opacity: 0.5;
}
.product-st-card1 .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50.27%);
  transition: 0.35s;
  opacity: 0;
}
.product-st-card1 .product-img img {
  border-radius: 5px 0 0 5px;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-st-card1 .product-img img {
    max-width: unset;
    border-radius: 5px 5px 0 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .product-st-card1 .product-img img {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .product-st-card1 .product-img img {
    max-width: unset;
    border-radius: 5px 5px 0 0;
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-st-card1 .product-content {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 576px) {
  .product-st-card1 .product-content {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.product-st-card1 .product-content .location {
  margin-bottom: 8px;
}
.product-st-card1 .product-content .location a {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.35s;
}
.product-st-card1 .product-content .location a:hover {
  color: var(--primary-color1);
}
.product-st-card1 .product-content h6 {
  margin-bottom: 15px;
  line-height: 1;
}
.product-st-card1 .product-content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: var(--title-color);
  transition: 0.35s;
}
.product-st-card1 .product-content h6 a:hover {
  color: var(--primary-color1);
}
.product-st-card1 .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 34px;
  position: relative;
  padding-top: 18px;
  padding-bottom: 10px;
}
@media (max-width: 1699px) {
  .product-st-card1 .product-content .features {
    gap: 15px;
  }
}
@media (max-width: 1399px) {
  .product-st-card1 .product-content .features {
    gap: 5px;
    flex-wrap: wrap;
  }
}
.product-st-card1 .product-content .features::before {
  content: "";
  background-color: #eee;
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  top: 0;
  transform: translateX(-50%);
}
.product-st-card1 .product-content .features::after {
  content: "";
  background-color: #eee;
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  bottom: 0;
  transform: translateX(-50%);
}
.product-st-card1 .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--text-color);
  margin-bottom: 8px;
}
@media (max-width: 1499px) {
  .product-st-card1 .product-content .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-st-card1 .product-content .features li {
    padding-right: 5px;
  }
}
.product-st-card1 .product-content .features li svg {
  fill: var(--text-color);
}
.product-st-card1 .product-content .content-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.product-st-card1 .product-content .content-btm .view-btn2 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.35s;
}
.product-st-card1 .product-content .content-btm .view-btn2 svg path {
  fill: var(--primary-color1);
}
.product-st-card1 .product-content .content-btm .view-btn2 svg path:first-child {
  fill: none;
  stroke: var(--primary-color1);
}
.product-st-card1 .product-content .content-btm .view-btn2:hover {
  color: var(--primary-color1);
}
.product-st-card1.two {
  gap: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-st-card1.two {
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .product-st-card1.two {
    flex-direction: row;
    align-items: center;
  }
}
.product-st-card1.two .product-img {
  position: relative;
  border-radius: 5px 0 0 5px;
  transition: 0.35s;
  max-width: 180px;
  width: 100%;
}
@media (max-width: 1399px) {
  .product-st-card1.two .product-img {
    max-width: 150px;
  }
}
.product-st-card1.two .product-img .fav {
  position: absolute;
  top: 17px;
  left: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}
.product-st-card1.two .product-img .fav svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.product-st-card1.two .product-img .fav:hover {
  background-color: var(--primary-color1);
}
.product-st-card1.two .product-img .product-price {
  position: absolute;
  left: -10px;
  bottom: 0;
  z-index: 2;
  line-height: 1;
  display: flex;
  transition: 0.35s;
}
.product-st-card1.two .product-img .product-price span {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  background-color: var(--white-color);
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  padding: 4px 23px;
  transition: 0.35s;
}
.product-st-card1.two .product-img img {
  border-radius: 5px 0 0 5px;
  height: 145px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .product-st-card1.two .product-img img {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-st-card1.two .product-content {
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 576px) {
  .product-st-card1.two .product-content {
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.product-st-card1.two .product-content h6 {
  margin-bottom: 9px;
  line-height: 1;
}
.product-st-card1.two .product-content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: var(--title-color);
  transition: 0.35s;
}
.product-st-card1.two .product-content h6 a:hover {
  color: var(--primary-color1);
}
.product-st-card1.two .product-content .features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  position: relative;
  padding-top: 12px;
  padding-bottom: 0;
}
@media (max-width: 1699px) {
  .product-st-card1.two .product-content .features {
    gap: 4px;
  }
}
@media (max-width: 1399px) {
  .product-st-card1.two .product-content .features {
    gap: 4px;
    flex-wrap: wrap;
  }
}
.product-st-card1.two .product-content .features::before {
  content: "";
  background-color: #eee;
  position: absolute;
  left: 50%;
  height: 1px;
  width: 100%;
  top: 0;
  transform: translateX(-50%);
}
.product-st-card1.two .product-content .features::after {
  display: none;
}
.product-st-card1.two .product-content .features li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin-bottom: 8px;
}
@media (max-width: 1499px) {
  .product-st-card1.two .product-content .features li {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-st-card1.two .product-content .features li {
    padding-right: 5px;
  }
}
.product-st-card1.two .product-content .features li svg {
  fill: var(--text-color);
}
.product-st-card1:hover {
  border-color: var(--primary-color1);
}
.product-st-card1:hover .product-img .number-of-img {
  opacity: 1;
}
.product-st-card1:hover .product-img .product-price span {
  background-color: var(--primary-color1);
}
.product-st-card1:hover .product-img .slider-btn-group {
  opacity: 1;
}
.product-st-card1:hover .product-img::after {
  opacity: 1;
}

/*=====================================
   42. Home5 Top Used Cars Section
========================================*/
.slider-btn-and-exp-btn {
  display: flex;
  align-items: center;
  gap: 40px;
}
.slider-btn-and-exp-btn .slider-btn-group2 {
  max-width: 90px;
  min-width: 90px;
}

/*=====================================
   43. Home5 Upcoming Cars Section
========================================*/
.home5-upcomming-car-section .slider-btn-group2 {
  max-width: 90px;
  width: 100%;
}

/*=====================================
   44. Home5 Comparing Cars Section
========================================*/
.home5-compare-car-section .slide-item {
  padding: 15px;
}
.home5-compare-car-section .slick-arrow {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(19, 20, 26, 0.16);
  cursor: pointer;
  transition: 0.35s;
}
.home5-compare-car-section .slick-arrow.slick-prev {
  left: unset;
  transform: unset;
  right: 220px;
  top: -84px;
}
.home5-compare-car-section .slick-arrow.slick-prev::before {
  content: url(img/home5/icon/slick-prev.svg);
  opacity: 0.16;
  transition: 0.35s;
}
.home5-compare-car-section .slick-arrow.slick-next {
  right: unset;
  transform: unset;
  right: 162px;
  top: -84px;
}
.home5-compare-car-section .slick-arrow.slick-next::before {
  content: url(img/home5/icon/slick-next.svg);
  opacity: 0.16;
  transition: 0.35s;
}
.home5-compare-car-section .slick-arrow:hover {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.home5-compare-car-section .slick-arrow:hover::before {
  opacity: 1;
}
.home5-compare-car-section .compare-card5 {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: #FFF;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-compare-car-section .compare-card5 {
    display: flex;
    align-items: flex-start;
  }
}
@media (max-width: 576px) {
  .home5-compare-car-section .compare-card5 {
    display: flex;
    align-items: flex-start;
  }
}
.home5-compare-car-section .compare-card5 .primary-btn3 {
  position: absolute;
  right: -81px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  padding: 7px 39px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-compare-car-section .compare-card5 .primary-btn3 {
    right: unset;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
    top: unset;
    bottom: 0;
  }
}
@media (max-width: 576px) {
  .home5-compare-car-section .compare-card5 .primary-btn3 {
    right: unset;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
    top: unset;
    bottom: 0;
  }
}
.home5-compare-car-section .compare-card5 .single-compare {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1399px) {
  .home5-compare-car-section .compare-card5 .single-compare {
    gap: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-compare-car-section .compare-card5 .single-compare {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 576px) {
  .home5-compare-car-section .compare-card5 .single-compare {
    flex-direction: column;
    align-items: flex-start;
  }
}
.home5-compare-car-section .compare-card5 .single-compare .compare-img {
  max-width: 283px;
  width: 100%;
}
.home5-compare-car-section .compare-card5 .single-compare .compare-img.one img {
  border-radius: 5px 0 0 0;
}
.home5-compare-car-section .compare-card5 .single-compare .compare-img.two img {
  border-radius: 0 0 0 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-compare-car-section .compare-card5 .single-compare .compare-img {
    border-radius: unset;
  }
  .home5-compare-car-section .compare-card5 .single-compare .compare-img img {
    border-radius: unset;
  }
  .home5-compare-car-section .compare-card5 .single-compare .compare-img.one img {
    border-radius: 5px 0 0 0;
  }
  .home5-compare-car-section .compare-card5 .single-compare .compare-img.two img {
    border-radius: 0 5px 0 0;
  }
}
@media (max-width: 576px) {
  .home5-compare-car-section .compare-card5 .single-compare .compare-img {
    border-radius: unset;
  }
  .home5-compare-car-section .compare-card5 .single-compare .compare-img img {
    border-radius: unset;
  }
  .home5-compare-car-section .compare-card5 .single-compare .compare-img.one img {
    border-radius: 5px 0 0 0;
  }
  .home5-compare-car-section .compare-card5 .single-compare .compare-img.two img {
    border-radius: 0 5px 0 0;
  }
}
.home5-compare-car-section .compare-card5 .single-compare .content {
  padding-right: 35px;
  line-height: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-compare-car-section .compare-card5 .single-compare .content {
    padding-right: 10px;
    padding-bottom: 50px;
    padding-left: 10px;
  }
}
@media (max-width: 576px) {
  .home5-compare-car-section .compare-card5 .single-compare .content {
    padding-right: 10px;
    padding-bottom: 50px;
    padding-left: 10px;
  }
}
.home5-compare-car-section .compare-card5 .single-compare .content > span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: 8px;
}
.home5-compare-car-section .compare-card5 .single-compare .content h6 {
  margin-bottom: 12px;
}
.home5-compare-car-section .compare-card5 .single-compare .content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  transition: 0.35s;
}
.home5-compare-car-section .compare-card5 .single-compare .content h6 a:hover {
  color: var(--primary-color1);
}
.home5-compare-car-section .compare-card5 .single-compare .content .price {
  line-height: 1;
}
.home5-compare-car-section .compare-card5 .single-compare .content .price span {
  color: var(--black-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
}

/*=====================================
   45. Home5 Testimonial Section
========================================*/
.home5-testimonial-section {
  border-radius: 30px;
  background: #F6F6F6;
}
.home5-testimonial-section .rating {
  min-width: 270px;
}
.home5-testimonial-section .rating a {
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 10px 20px 15px;
}
.home5-testimonial-section .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.home5-testimonial-section .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.home5-testimonial-section .rating a .review-top .star ul li {
  line-height: 1;
}
.home5-testimonial-section .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.home5-testimonial-section .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.home5-testimonial-section .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  position: relative;
}
.home5-testimonial-section .rating a .content ul li span {
  font-weight: 600;
}
.home5-testimonial-section .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.home5-testimonial-section .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.home5-testimonial-section .feedback-card {
  padding: 40px 30px;
  background: var(--white-color);
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.35s;
  border: 1px solid #eee;
}
.home5-testimonial-section .feedback-card .feedback-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}
.home5-testimonial-section .feedback-card .feedback-top .stat-area {
  display: flex;
  flex-direction: column;
}
.home5-testimonial-section .feedback-card .feedback-top .stat-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}
.home5-testimonial-section .feedback-card .feedback-top .stat-area ul li {
  line-height: 1;
}
.home5-testimonial-section .feedback-card .feedback-top .stat-area ul li i {
  font-style: 13px;
  color: #DDA701;
}
.home5-testimonial-section .feedback-card .feedback-top .stat-area img {
  margin-bottom: 5px;
}
.home5-testimonial-section .feedback-card .feedback-top .stat-area span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.home5-testimonial-section .feedback-card .feedback-top .services span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.home5-testimonial-section .feedback-card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-color);
  margin-bottom: 23px;
  text-align: center;
}
.home5-testimonial-section .feedback-card .author-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home5-testimonial-section .feedback-card .author-name h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: var(--title-color);
  position: relative;
}
.home5-testimonial-section .feedback-card:hover {
  border-color: var(--primary-color1);
}
.home5-testimonial-section .trustpilot-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.home5-testimonial-section .trustpilot-review > strong {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-color);
}
.home5-testimonial-section .trustpilot-review p {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.home5-testimonial-section .trustpilot-review p strong {
  font-weight: 600;
}
.home5-testimonial-section .trustpilot-review p a {
  color: var(--text-color);
  text-decoration: underline;
}

/*=====================================
   46. Home6 Banner Section
========================================*/
.home6-banner-area {
  background: #F6F4Fa;
  padding: 75px 8% 50px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home6-banner-area {
    padding: 75px 3% 50px;
  }
}
@media (max-width: 1399px) {
  .home6-banner-area {
    padding: 75px 3% 130px;
  }
}
.home6-banner-area .banner-content > span {
  color: var(--black-color2);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  position: relative;
}
.home6-banner-area .banner-content > span::after {
  content: "";
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 51px;
  background-color: rgba(29, 29, 29, 0.15);
}
.home6-banner-area .banner-content h1 {
  font-family: var(--font-montserrat);
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  line-height: 1.2;
  color: var(--title-color);
  margin-bottom: 15px;
}
@media (max-width: 1399px) {
  .home6-banner-area .banner-content h1 {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .home6-banner-area .banner-content h1 {
    font-size: 50px;
  }
}
.home6-banner-area .banner-content p {
  font-family: var(--font-open-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
  color: var(--text-color);
  margin-bottom: 40px;
}
.home6-banner-area .banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  gap: 55px;
}
@media (max-width: 576px) {
  .home6-banner-area .banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.home6-banner-area .banner-content .banner-content-bottom .primary-btn7 {
  padding: 18px 28px;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a {
  display: inline-block;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  position: relative;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .content ul li span {
  font-weight: 600;
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--title-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.home6-banner-area .banner-content .banner-content-bottom .rating a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.home6-banner-area .banner-img-group .top-img-group {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
}
.home6-banner-area .banner-img-group .top-img-group .top-left-card {
  background-color: var(--white-color);
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 195px;
  width: 195px;
  border-radius: 73px 73px 0px 70px;
}
.home6-banner-area .banner-img-group .top-img-group .top-left-card .icon {
  margin-bottom: 20px;
}
.home6-banner-area .banner-img-group .top-img-group .top-left-card h4 {
  color: var(--black-color2);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 1;
}
.home6-banner-area .banner-img-group .top-img-group .top-left-card span {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.home6-banner-area .banner-img-group .top-img-group .top-right-img img {
  border-radius: 73px 73px 73px 0;
}
.home6-banner-area .banner-img-group .bottom-img-group {
  display: flex;
  gap: 15px;
}
.home6-banner-area .banner-img-group .bottom-img-group .bottom-left-img img {
  border-radius: 73px 0 73px 73px;
}
.home6-banner-area .banner-img-group .bottom-img-group .bottom-right-img img {
  border-radius: 0 73px 73px 73px;
}

.home6-search-area {
  margin-top: -70px;
}
.home6-search-area form {
  border-radius: 10px;
  border: 1px solid rgba(29, 29, 29, 0.1);
  background: var(--white-color);
  padding: 22px 40px 30px;
}
@media (max-width: 1199px) {
  .home6-search-area form {
    padding: 22px 20px 30px;
  }
}
.home6-search-area form .form-inner {
  width: 100%;
}
.home6-search-area form .form-inner button {
  width: 100%;
  justify-content: center;
  background-color: var(--black-color2);
  color: var(--white-color);
  border: 1px solid var(--black-color2);
  padding: 9px 28px;
}
.home6-search-area form .form-inner button svg {
  fill: var(--white-color);
}
.home6-search-area form .form-inner button::after {
  background-color: var(--white-color);
}
.home6-search-area form .form-inner button:hover {
  color: var(--black-color2);
}
.home6-search-area form .form-inner button:hover svg {
  fill: var(--black-color2);
}
.home6-search-area form .form-inner label {
  color: var(--black-color2);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
  margin-bottom: 12px;
}
.home6-search-area form .form-inner .nice-select {
  padding: 0px 20px;
}
.home6-search-area form .form-inner .nice-select::after {
  right: 23px;
}
.home6-search-area form .form-inner .nice-select:focus {
  border-color: var(--title-color);
}
.home6-search-area form .form-inner .nice-select .list option {
  padding-left: 20px;
  padding-right: 20px;
}

.home6-filter-area {
  position: relative;
  padding: 12px 0;
  gap: 20px;
}
@media (max-width: 767px) {
  .home6-filter-area {
    flex-wrap: wrap;
  }
}
.home6-filter-area::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #EEE 0%, rgba(238, 238, 238, 0.2) 100%);
}
.home6-filter-area::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #EEE 0%, rgba(238, 238, 238, 0.2) 100%);
}
.home6-filter-area .nav-tabs {
  border: none;
  gap: 15px;
}
.home6-filter-area .nav-tabs .nav-item {
  border: none;
}
.home6-filter-area .nav-tabs .nav-item .nav-link {
  padding: 0;
  line-height: 1;
  border: none;
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  border-radius: 25px;
  padding: 9px 20px;
  border: 1px solid transparent;
  background: transparent;
}
.home6-filter-area .nav-tabs .nav-item .nav-link:focus-visible {
  outline: none;
}
.home6-filter-area .nav-tabs .nav-item .nav-link.active {
  border: 1px solid #EEE;
  background: #F8F8F8;
  color: var(--black-color2);
}

/*=====================================
   47. Home6 Brand Category Section
========================================*/
.home6-brand-section .car-category {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
@media (max-width: 576px) {
  .home6-brand-section .car-category {
    padding: 25px 20px;
  }
}
.home6-brand-section .car-category:hover {
  border: 1px solid rgba(19, 20, 26, 0.5);
}
.home6-brand-section .car-category .icon {
  margin-bottom: 20px;
}
.home6-brand-section .car-category .icon img {
  height: 50px;
}
.home6-brand-section .car-category .content {
  line-height: 1;
}
.home6-brand-section .car-category .content h6 {
  color: var(--title-color);
  font-size: 14px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  margin-bottom: 2px;
  line-height: 20px;
}
.home6-brand-section .car-category .content span {
  color: var(--text-color);
  font-size: 14px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  margin-bottom: 0;
  line-height: 20px;
}
.home6-brand-section .our-activetis {
  padding: 40px 0;
  border-bottom: 1px solid rgba(19, 20, 26, 0.1);
  border-top: 1px solid rgba(19, 20, 26, 0.1);
}
.home6-brand-section .our-activetis .single-activiti {
  display: flex;
  align-items: center;
  gap: 20px;
}
.home6-brand-section .our-activetis .single-activiti .content .number {
  display: flex;
  align-items: center;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--title-color);
  margin-bottom: 4px;
}
.home6-brand-section .our-activetis .single-activiti .content .number h5 {
  margin-bottom: 0;
  line-height: 1;
}
.home6-brand-section .our-activetis .single-activiti .content p {
  margin-bottom: 0;
  color: var(--text-color);
  line-height: 22px;
}

.eg-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}
.eg-table thead tr th {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  border-width: 1px;
  border-color: rgba(238, 238, 238, 0.93);
  background-color: #F6F4Fa;
}
.eg-table tbody tr td {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 20px;
  border-width: 1px;
  border-color: rgba(238, 238, 238, 0.93);
}
.eg-table tbody tr td strong {
  font-weight: 600;
}
.eg-table tbody tr td .primary-btn2 {
  padding: 5px 22px;
}
@media (max-width: 576px) {
  .eg-table.compare-table thead tr th:first-child {
    display: none;
    visibility: hidden;
  }
}
.eg-table.compare-table tbody tr td {
  width: 33.33%;
}
@media (max-width: 576px) {
  .eg-table.compare-table tbody tr td {
    width: 50%;
  }
  .eg-table.compare-table tbody tr td:first-child {
    display: none;
    visibility: hidden;
  }
}

.compare-modal .modal-header .modal-title {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--font-montserrat);
}
.compare-modal .modal-header .btn-close {
  padding: 0;
  margin: 0;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
  opacity: 1;
  background-image: none;
}
.compare-modal .modal-header .btn-close i {
  line-height: 1;
  font-size: 22px;
}
.compare-modal .modal-header .btn-close:focus {
  box-sizing: none;
}
.compare-modal .compare-top {
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 30px 15px;
}
.compare-modal .compare-top .single-car .car-img {
  margin-bottom: 15px;
}
.compare-modal .compare-top .single-car .content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: var(--text-color);
}
.compare-modal .compare-top .single-car .content h6 {
  margin-bottom: 0;
}
.compare-modal .compare-top .single-car .content h6.title {
  margin-bottom: 5px;
}
.compare-modal .compare-top .single-car .content h6.title a {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  transition: 0.35s;
}
.compare-modal .compare-top .single-car .content h6.title a:hover {
  color: var(--primary-color1);
}
.compare-modal .compare-top .single-car .content h6.price {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: var(--title-color);
}
.compare-modal .compare-top .single-car .content h6.price del {
  font-size: 80%;
  opacity: 0.5;
}
.compare-modal .compare-top .vs {
  padding: 0 12px;
  transform: translateY(-40px);
}
.compare-modal .compare-top .vs span {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 12px;
  color: var(--black-color);
}
.compare-modal .compare-top .vs span::after {
  content: "";
  height: 90px;
  width: 1px;
  background-color: var(--primary-color1);
  position: absolute;
  bottom: -90px;
}
.compare-modal .compare-top .vs span::before {
  content: "";
  width: 9px;
  height: 1px;
  background-color: var(--primary-color1);
  position: absolute;
  bottom: -90px;
}
.compare-modal .table-car {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.compare-modal .compare-btm {
  width: 50%;
}
.compare-modal.two .modal-header .btn-close {
  background-color: var(--black-color);
}
.compare-modal.two .modal-header .btn-close i {
  color: var(--white-color);
}
.compare-modal.two .compare-top .single-car .content h6.title a:hover {
  color: var(--title-color);
}
.compare-modal.two .compare-top .vs {
  padding: 0 12px;
  transform: translateY(-40px);
}
.compare-modal.two .compare-top .vs span {
  background-color: var(--black-color);
  color: var(--white-color);
}
.compare-modal.two .compare-top .vs span::after {
  background-color: var(--black-color);
}
.compare-modal.two .compare-top .vs span::before {
  background-color: var(--black-color);
}
.compare-modal.two .compare-btm {
  width: 100%;
}
.compare-modal.two tbody tr td .primary-btn2 {
  background-color: var(--black-color);
  padding: 5px 22px;
  color: var(--white-color);
  border: 1px solid var(--black-color);
}
.compare-modal.two tbody tr td .primary-btn2::after {
  background-color: var(--white-color);
}
.compare-modal.two tbody tr td .primary-btn2:hover {
  color: var(--black-color);
}

/*=====================================
   48. Inner Pages Banner
========================================*/
.inner-page-banner.style-2 {
  height: 150px;
}
.inner-page-banner.style-3 {
  height: 60px;
}
.inner-page-banner .banner-wrapper {
  /* padding: 0px 11%; */
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .inner-page-banner .banner-wrapper {
    /* padding: 0px 3%; */
  }
}
@media (max-width: 1399px) {
  .inner-page-banner .banner-wrapper {
    /* padding: 0px 3%; */
  }
}
@media (max-width: 1199px) {
  .inner-page-banner .banner-wrapper {
    /* padding: 0px 1%; */
  }
}
.inner-page-banner .breadcrumb-list {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 24px;
}
.inner-page-banner .breadcrumb-list li {
  line-height: 1;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  position: relative;
  padding-left: 18px;
}
.inner-page-banner .breadcrumb-list li::before {
  content: "";
  height: 1px;
  width: 10px;
  background-color: var(--text-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.inner-page-banner .breadcrumb-list li:first-child {
  padding-left: 0;
}
.inner-page-banner .breadcrumb-list li:first-child::before {
  display: none;
  visibility: hidden;
}
.inner-page-banner .breadcrumb-list li a {
  color: var(--primary-color1);
  transition: 0.35s;
}
.inner-page-banner .breadcrumb-list li a:hover {
  color: var(--title-color);
}
.inner-page-banner .banner-main-content-wrap {
  padding: 10px 0;
}
.inner-page-banner .banner-content {
  width: 100%;
}
.inner-page-banner .banner-content .sub-title {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 3.6px;
  color: var(--primary-color1);
  margin-bottom: 5px;
}
.inner-page-banner .banner-content .sub-title svg {
  fill: var(--primary-color1);
}
.inner-page-banner .banner-content h1 {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 25px;
  color: var(--title-color);
  margin-bottom: 20px;
  line-height: 1.2;
  text-transform: capitalize;
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content h1 {
    font-size: 20px;
  }
}
.inner-page-banner .banner-content .customar-review {
  padding-top: 10px;
}
.inner-page-banner .banner-content .customar-review > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 80px;
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content .customar-review > ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
.inner-page-banner .banner-content .customar-review > ul > li {
  position: relative;
}
.inner-page-banner .banner-content .customar-review > ul > li::after {
  content: "";
  height: 48px;
  width: 2px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content .customar-review > ul > li::after {
    display: none;
    visibility: hidden;
  }
}
.inner-page-banner .banner-content .customar-review > ul > li:first-child::after {
  display: none;
  visibility: hidden;
}
.inner-page-banner .banner-content .customar-review > ul > li a {
  display: inline-block;
}
.inner-page-banner .banner-content .customar-review > ul > li a .review-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
.inner-page-banner .banner-content .customar-review > ul > li a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.inner-page-banner .banner-content .customar-review > ul > li a .review-top .star ul li {
  line-height: 1;
}
.inner-page-banner .banner-content .customar-review > ul > li a .review-top .star ul li i {
  font-size: 12px;
  color: #DDA701;
}
.inner-page-banner .banner-content .customar-review > ul > li a .content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 18px;
}
.inner-page-banner .banner-content .customar-review > ul > li a .content ul li {
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
  position: relative;
}
.inner-page-banner .banner-content .customar-review > ul > li a .content ul li span {
  font-weight: 600;
}
.inner-page-banner .banner-content .customar-review > ul > li a .content ul li::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.inner-page-banner .banner-content .customar-review > ul > li a .content ul li:first-child::after {
  display: none;
  visibility: hidden;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content.style-2 .price-model-and-fav-area {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 20px;
  }
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model {
    flex-wrap: wrap;
    gap: 2px;
  }
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model .price {
  line-height: 1;
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model .price {
    padding-right: 15px;
  }
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model .price h3 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 30px;
  font-weight: 600;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model .price h3 del {
  font-size: 20px;
  opacity: 0.5;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .price-and-model .change-model-btn a {
  display: inline-flex;
  align-items: baseline;
  gap: 6px;
  color: rgba(19,20,26,0.9);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  border-radius: 12px;
  background: #FFF;
  padding: 6px 15px;
  white-space: nowrap;
  line-height: 1;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav::before {
  content: "";
  height: 24px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.2);
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 576px) {
  .inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav::before {
    display: none;
    visibility: hidden;
  }
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li {
  position: relative;
  transition: 0.5s;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li .share-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--white-color);
  border: 1px solid rgba(70, 217, 147, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  cursor: pointer;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li .share-icon:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li .social-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 38px;
  top: 50%;
  transform: scaleX(0) translateY(-50%);
  transform-origin: left;
  opacity: 0;
  transition: 0.5s;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li .social-icons > li > a {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--white-color);
  border: 1px solid rgba(70, 217, 147, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li .social-icons > li > a i {
  transition: 0.35s;
  color: var(--title-color);
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li .social-icons > li > a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.inner-page-banner .banner-content.style-2 .price-model-and-fav-area .share-and-fav > li:hover .social-icons {
  transform: scaleX(1) translateY(-50%);
  opacity: 1;
}
@media (max-width: 1399px) {
  .inner-page-banner .banner-content.style-2 h1 {
    font-size: 45px;
  }
  
}
.inner-banner-heading{
  margin-top: -60px;
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 767px) {
  .inner-page-banner .banner-content.style-2 .location-and-notification ul {
    gap: 5px;
    flex-wrap: wrap;
    
  }
  .inner-banner-heading{
    margin-top: 10px;
  }
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul li {
  display: flex;
  align-items: baseline;
  gap: 5px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .inner-page-banner .banner-content.style-2 .location-and-notification ul li {
    padding-right: 15px;
  }
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul li.alart {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  cursor: pointer;
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul li.alart button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
  font-family: var(--font-montserrat);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 10px;
  transition: 0.35s;
  background-color: transparent;
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul li.alart button svg {
  transition: 0.35s;
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul li.alart button:hover {
  color: var(--title-color);
}
.inner-page-banner .banner-content.style-2 .location-and-notification ul li.alart button:hover svg {
  fill: var(--title-color);
}

/*=====================================
   49. Welcome Banner
========================================*/
.welcome-banner-section .welcome-wrap {
  /* max-width: 970px; */
  width: 100%;
  margin: 0 auto;
}
.welcome-banner-section .welcome-wrap .section-title1 {
  margin-bottom: 20px;
}
.welcome-banner-section .welcome-wrap .welcome-content {
  margin-bottom: 40px;
}
.welcome-banner-section .welcome-wrap .welcome-content p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
  text-align: left;
}
.welcome-banner-section .welcome-wrap .author-area img {
  margin-bottom: 15px;
}
.welcome-banner-section .welcome-wrap .author-area h6 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.welcome-banner-section .welcome-wrap .author-area span {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}

/*=====================================
   50. Drivco Gallery
========================================*/
.drivco-gallery .gallery-item {
  position: relative;
  border-radius: 10px;
}
.drivco-gallery .gallery-item img {
  border-radius: 10px;
}
@media (max-width: 991px) {
  .drivco-gallery .gallery-item img {
    width: 100%;
  }
}
.drivco-gallery .gallery-item a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.view-more-btn .primary-btn3 {
  font-family: var(--font-montserrat);
  padding: 10px 55px;
}

/*=====================================
   51. Car Details Area
========================================*/
.car-details-area .logged-in-as a {
  color: var(--primary-color1);
}
.car-details-area .user-qustion-area .comment-form-cookies-consent input {
  margin-right: 10px;
}
.car-details-area .user-qustion-area .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
}
.car-details-area .user-qustion-area .form-inner textarea {
  border-radius: 5px;
  background: #FFF;
  display: flex;
  padding: 15px 25px;
  width: 100%;
  height: 130px;
  resize: none;
  margin-bottom: 25px;
}
.car-details-area .single-item {
  scroll-margin-top: 200px;
}
.car-details-area .car-details-menu.sticky {
  position: fixed;
  top: 60px;
  z-index: 888;
  max-width: 1300px;
  width: 100%;
}
@media (max-width: 1399px) {
  .car-details-area .car-details-menu.sticky {
    max-width: 1120px;
  }
}
@media (max-width: 1199px) {
  .car-details-area .car-details-menu.sticky {
    max-width: 940px;
  }
}
@media (max-width: 991px) {
  .car-details-area .car-details-menu.sticky {
    max-width: 700px;
  }
}
@media (max-width: 767px) {
  .car-details-area .car-details-menu.sticky {
    max-width: 520px;
  }
}
@media (max-width: 576px) {
  .car-details-area .car-details-menu.sticky {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
  }
}
.car-details-area .car-details-menu .navbar {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 20px 0;
}
.car-details-area .car-details-menu .navbar .nav-pills {
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1199px) {
  .car-details-area .car-details-menu .navbar .nav-pills {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.car-details-area .car-details-menu .navbar .nav-pills .nav-item {
  line-height: 1;
}
.car-details-area .car-details-menu .navbar .nav-pills .nav-item a {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  line-height: 1;
  background-color: transparent;
}
.car-details-area .car-details-menu .navbar .nav-pills .nav-item a.active {
  background-color: transparent;
  color: var(--title-color);
  position: relative;
}
.car-details-area .car-details-menu .navbar .nav-pills .nav-item a.active::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid rgba(19,20,26,0.9);
  width: 100%;
}
@media (max-width: 1199px) {
  .car-details-area .car-details-menu .navbar .nav-pills .nav-item a.active::before {
    bottom: -13px;
  }
}
.car-details-area .car-details-menu .navbar .nav-pills .nav-item a.active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -32px;
  height: 14px;
  width: 14px;
  background-color: var(--white-color);
  transform: rotate(45deg) translateX(-50%);
  border: 1px solid rgba(19,20,26,0.9);
  border-right: none;
  border-bottom: none;
}
@media (max-width: 1199px) {
  .car-details-area .car-details-menu .navbar .nav-pills .nav-item a.active::after {
    bottom: -24px;
  }
}
.car-details-area .car-img-area .video-area iframe,
.car-details-area .car-img-area .video-area video {
  width: 100%;
  min-height: 470px;
  border-radius: 10px;
}
.car-details-area .car-img-area .tab-content .product-img {
  position: relative;
  border-radius: 5px;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img .number-of-img {
  position: absolute;
  left: 20px;
  top: 17px;
  font-family: var(--font-open-sans);
  font-weight: 400;
  font-size: 14px;
  color: var(--white-color);
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img .fav {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
  transition: 0.35s;
  line-height: 1;
  opacity: 0;
}
.car-details-area .car-img-area .tab-content .product-img .fav svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img .fav:hover svg {
  fill: var(--primary-color1);
}
.car-details-area .car-img-area .tab-content .product-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 20, 26, 0.6);
  cursor: pointer;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.car-details-area .car-img-area .tab-content .product-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50.27%);
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img img {
  border-radius: 5px;
}
@media (max-width: 767px) {
  .car-details-area .car-img-area .tab-content .product-img img {
    width: 100%;
  }
}
.car-details-area .car-img-area .tab-content .product-img2 {
  position: relative;
  border-radius: 5px;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img2 .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img2 .slider-btn-group .swiper-arrow {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color1);
  cursor: pointer;
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img2 .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
  transition: 0.35s;
}
.car-details-area .car-img-area .tab-content .product-img2 .slider-btn-group .swiper-arrow:hover {
  background-color: var(--primary-color1);
}
.car-details-area .car-img-area .tab-content .product-img2 .content {
  padding-top: 20px;
}
.car-details-area .car-img-area .tab-content .product-img2 .content h5 {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}
.car-details-area .car-img-area .tab-content .product-img-slider {
  border-radius: 10px;
}
.car-details-area .car-img-area .tab-content .product-img-slider img {
  border-radius: 10px;
}
.car-details-area .car-img-area .nav-tabs {
  border-bottom: none;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .car-details-area .car-img-area .nav-tabs {
    justify-content: center;
    gap: 20px;
  }
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link {
  padding: 0;
  border: none;
  color: var(--text-color);
  font-family: vat(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: 0.35s;
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link svg {
  fill: rgba(19, 20, 26, 0.1);
  transition: 0.35s;
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link span {
  display: flex;
  gap: 10px;
  align-items: center;
  transition: 0.35s;
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link span svg {
  transition: 0.35s;
  fill: var(--text-color);
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link.active {
  border: none;
  color: var(--title-color);
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link.active svg {
  fill: var(--title-color);
}
.car-details-area .car-img-area .nav-tabs .nav-item .nav-link.active span svg {
  fill: var(--title-color);
}
.car-details-area .title {
  line-height: 1;
}
.car-details-area .title h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}
.car-details-area .title h5::after {
  content: "";
  width: 43px;
  height: 1px;
  background: linear-gradient(90deg, rgba(19,20,26,0.9) 0%, rgba(19, 20, 26, 0) 100%);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -53px;
}
.car-details-area .car-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  -moz-columns: 4;
  columns: 3;
}

@media (max-width: 767px) {
  .car-details-area .car-info ul {
    -moz-columns: 2;
    columns: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.car-details-area .car-info ul li {
  display: flex;
  align-items: flex-start; /* Change to flex-start for better alignment */
  gap: 15px;
  height:70px;
}

.car-details-area .car-info ul li .content {
  line-height: 1;
}

.car-details-area .car-info ul li .content h6 {
  margin-bottom: 5px; /* Adjust margin for better spacing */
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}

.car-details-area .car-info ul li .content span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.car-details-area .kye-features ul {
  margin: 0;
  padding: 0;
  list-style: none;
  -moz-columns: 3;
  columns: 3;
}

@media (max-width: 767px) {
  .car-details-area .kye-features ul {
    -moz-columns: 2;
         columns: 2;
  }
}
@media (max-width: 576px) {
  .car-details-area .kye-features ul {
    -moz-columns: 1;
         columns: 1;
  }
}
.car-details-area .kye-features ul li {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.car-details-area .kye-features ul li:last-child {
  margin-bottom: 0;
}
.car-details-area .kye-features ul li svg {
  fill: var(--text-color);
  min-width: 12px;
  margin-top: 2px;
}
.car-details-area .overview-content {
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 10px 10px;
  clear: both;
  content: "";
  display: table;
  width: 100%;
}
@media (max-width: 767px) {
  .car-details-area .overview-content {
    flex-wrap: wrap;
  }
}
.car-details-area .overview-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
@media (max-width: 767px) {
  .car-details-area .overview-content ul {
    max-width: 100%;
  }
}
.car-details-area .overview-content ul li {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  position: relative;
  padding: 18px 0 10px;
  margin-left: 25px;
  float: left;
  width: calc(50% - 37.5px);
}
@media (max-width: 576px) {
  .car-details-area .overview-content ul li {
    width: 100%;
    margin-left: 0;
  }
}
.car-details-area .overview-content ul li::after {
  content: "";
  height: 1px;
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #EEE 0%, rgba(238, 238, 238, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}
.car-details-area .overview-content ul li span {
  font-family: var(--font-montserrat);
  line-height: 1;
  font-weight: 600;
}
.car-details-area .car-colors .title-and-slider-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.car-details-area .car-colors .title-and-slider-btn .slider-btn-group2 {
  max-width: 68px;
  min-width: 68px;
}
@media (max-width: 576px) {
  .car-details-area .car-colors .title-and-slider-btn .slider-btn-group2 {
    display: none;
  }
}
.car-details-area .car-colors .title-and-slider-btn .slider-btn-group2 .slider-btn {
  width: 26px;
  height: 26px;
}
.car-details-area .car-colors .car-color-wrap {
  text-align: center;
}
.car-details-area .car-colors .car-color-wrap .content {
  padding-top: 30px;
}
.car-details-area .car-colors .car-color-wrap .content h6 {
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.car-details-area .faq-wrap {
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 30px 20px;
}
@media (max-width: 576px) {
  .car-details-area .faq-wrap {
    padding: 20px 10px;
  }
}
.car-details-area .section-title-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .car-details-area .section-title-and-filter {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.car-details-area .section-title-and-filter .filter-atra {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 6px 6px 20px;
}
.car-details-area .section-title-and-filter .filter-atra h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 500;
}
.car-details-area .section-title-and-filter .filter-atra .nice-select {
  height: 30px;
  background-color: var(--primary-color1);
  padding-left: 15px;
  padding-right: 35px;
}
.car-details-area .section-title-and-filter .filter-atra .nice-select::after {
  border-color: var(--title-color);
  right: 18px;
  height: 7px;
  width: 7px;
}
.car-details-area .section-title-and-filter .filter-atra .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.car-details-area .text-input-area .form-inner {
  border-radius: 10px;
  border: 1px solid var(--primary-color1);
  background: #FFF;
  display: flex;
  padding: 15px 25px;
}
.car-details-area .text-input-area .form-inner textarea {
  border: none;
  outline: none;
  width: 100%;
}
.car-details-area .text-input-area .form-inner button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.car-details-area .text-input-area .form-inner button svg {
  fill: var(--title-color);
}
.car-details-area .load-moer-btn {
  margin-top: 70px;
}
.car-details-area .load-moer-btn .primary-btn3 {
  text-align: center;
  justify-content: center;
  padding: 11px 33px;
}

button {
  white-space: nowrap;
}

.faq-wrap .accordion .accordion-item {
  border-radius: 5px;
  border: 1px solid rgba(238, 238, 238, 0.5);
  background: #F6F4Fa;
  margin-bottom: 15px;
}
.faq-wrap .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion .accordion-item .accordion-header {
  background: #F6F4Fa;
}
.faq-wrap .accordion .accordion-item .accordion-header .accordion-button {
  background: #F6F4Fa;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  padding: 15px 55px 15px 25px;
  white-space: normal;
}
@media (max-width: 576px) {
  .faq-wrap .accordion .accordion-item .accordion-header .accordion-button {
    padding: 15px 45px 15px 15px;
  }
}
.faq-wrap .accordion .accordion-item .accordion-header .accordion-button::after {
  flex-shrink: 0;
  width: unset;
  height: unset;
  margin-left: auto;
  background-image: none;
  background-repeat: unset;
  background-size: unset;
  font-family: bootstrap-icons !important;
  position: absolute;
  right: 17px;
  content: "\f282";
  transition: unset;
  font-size: 17px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.faq-wrap .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  border: transparent;
  box-shadow: none;
  background-color: transparent;
  color: var(--title-color);
}
.faq-wrap .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: unset;
  font-family: bootstrap-icons !important;
  content: "\f286";
  color: var(--title-color);
}
.faq-wrap .accordion .accordion-item .accordion-header .accordion-button:focus {
  border: transparent;
  box-shadow: none;
}
.faq-wrap .accordion .accordion-item .accordion-body {
  padding: 0px 25px 15px 25px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 576px) {
  .faq-wrap .accordion .accordion-item .accordion-body {
    padding: 0px 15px 15px 15px;
  }
}
.faq-wrap button {
  white-space: wrap;
}

.comment {
  margin: 0;
  padding: 0;
  list-style: none;
}
.comment > li {
  margin-bottom: 20px;
}
.comment > li > .single-comment-area {
  margin-bottom: 24px;
}
.comment > li:last-child {
  margin-bottom: 0;
}
.comment > li:last-child > .single-comment-area {
  margin-bottom: 0;
}
.comment ul.children {
  margin: 0;
  list-style: none;
  margin-left: 70px;
  padding: 20px 0;
  margin-top: 20px;
  border-top: 1px solid #eee;
}
.comment .single-comment-area {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.comment .single-comment-area .author-img img {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}
.comment .single-comment-area .comment-content .author-name-deg {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.comment .single-comment-area .comment-content .author-name-deg h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}
.comment .single-comment-area .comment-content .author-name-deg span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.comment .single-comment-area .comment-content p {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
}
.comment .single-comment-area .comment-content p a {
  color: var(--primary-color1);
}
.comment .single-comment-area .comment-content .replay-btn a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  transition: 0.35s;
}
.comment .single-comment-area .comment-content .replay-btn a svg {
  transition: 0.35s;
  fill: var(--text-color);
}
.comment .single-comment-area .comment-content .replay-btn a:hover {
  color: var(--primary-color1);
}
.comment .single-comment-area .comment-content .replay-btn a:hover svg {
  fill: var(--primary-color1);
}
.comment .comment-replay {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 70px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 24px 0;
}
.comment .comment-replay > li {
  margin-bottom: 30px;
}
.comment .comment-replay > li:last-child {
  margin-bottom: 0;
}

.car-details-sidebar .auction-info {
  border-radius: 10px;
  border: 1px dashed rgba(19, 20, 26, 0.16);
  padding: 30px 20px;
}
.car-details-sidebar .auction-info .bidding-price {
  border: 1px solid rgba(70, 217, 146, 0.4);
  margin-bottom: 20px;
  border-radius: 5px;
}
.car-details-sidebar .auction-info .bids-amount {
  padding-top: 50px;
  margin-bottom: 50px;
}
.car-details-sidebar .auction-info .bids-amount ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.car-details-sidebar .auction-info .bids-amount ul li {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 20px;
}
.car-details-sidebar .auction-info .bids-amount ul li:last-child {
  margin-bottom: 0;
}
.car-details-sidebar .auction-info .bids-amount ul li strong {
  color: rgba(19,20,26,0.9);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
}
.car-details-sidebar .auction-info .bids-amount ul li span {
  display: inline-block;
  position: relative;
  max-width: 70px;
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background: radial-gradient(50% 50% at 50% 50%, rgba(19,20,26,0.9) 0%, rgba(19, 20, 26, 0) 100%);
}
.car-details-sidebar .auction-info .primary-btn3 {
  width: 100%;
  justify-content: center;
}
.car-details-sidebar .contact-info {
  border-radius: 10px;
  border: 1px dashed rgba(19, 20, 26, 0.16);
  padding: 30px 20px;
}
.car-details-sidebar .contact-info .single-contact {
  margin-bottom: 20px;
}
.car-details-sidebar .contact-info .single-contact:last-child {
  margin-bottom: 0;
}
.car-details-sidebar .contact-info .single-contact a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  background: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 20px 20px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.car-details-sidebar .contact-info .single-contact .book {
  background: var(--black-color2);
  color:white
}
.car-details-sidebar .contact-info .single-contact .book:hover {
  color:black;
}

.car-details-sidebar .contact-info .single-contact a i {
  font-size: 17px;
}
.car-details-sidebar .contact-info .single-contact:nth-child(2) a {
  background-color: var(--title-color);
  color: var(--white-color);
}
.car-details-sidebar .contact-info .single-contact:last-child {
  margin-bottom: 0;
}
.car-details-sidebar .contact-info .single-contact:last-child a {
  background: #00D264;
}
.car-details-sidebar .contact-info .single-contact:last-child a i {
  color: var(--white-color);
}
.car-details-sidebar .inquiry-form {
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 45px 40px 50px;
}
@media (max-width: 767px) {
  .car-details-sidebar .inquiry-form {
    padding: 45px 20px 50px;
  }
}
.car-details-sidebar .inquiry-form .title {
  margin-bottom: 20px;
}
.car-details-sidebar .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
}
.car-details-sidebar .inquiry-form .title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.car-details-sidebar .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.car-details-sidebar .inquiry-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.car-details-sidebar .inquiry-form .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
}
.car-details-sidebar .inquiry-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.car-details-sidebar .inquiry-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.car-details-sidebar .inquiry-form .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border: none;
  min-height: 100px;
}
.car-details-sidebar .inquiry-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.car-details-sidebar .inquiry-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.car-details-sidebar .inquiry-form .form-inner .primary-btn3 {
  padding: 17px 28px;
  width: 100%;
  justify-content: center;
}
.car-details-sidebar .inquiry-form .form-inner .iti {
  width: 100%;
}
.car-details-sidebar .inquiry-form .form-inner .iti input {
  padding-left: 62px;
}
.car-details-sidebar .inquiry-form .form-inner .iti__selected-flag {
  padding-left: 20px;
}
.car-details-sidebar .inquiry-form .form-inner .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.car-details-sidebar .inquiry-form .form-inner .iti__country-list {
  max-width: 280px;
  box-shadow: unset;
  background-color: white;
  border: 1px solid var(--primary-color1);
}
.car-details-sidebar .inquiry-form .form-inner .iti__country-list .iti__country.iti__highlight {
  background-color: rgba(70, 217, 147, 0.5);
}
.car-details-sidebar .inquiry-form .form-inner .iti__country-list li {
  font-family: var(--font-montserrat);
  font-weight: 400;
}
.car-details-sidebar .inquiry-form .form-inner .iti__country-list li span {
  font-family: var(--font-montserrat);
  font-weight: 400;
}
.car-details-sidebar .recent-car-area .nav-tabs {
  border-bottom: 1px solid rgba(19, 20, 26, 0.1);
  margin-bottom: 40px;
}
.car-details-sidebar .recent-car-area .nav-tabs li {
  padding: 0;
}
.car-details-sidebar .recent-car-area .nav-tabs li button {
  padding: 9px 15px;
  border: none;
  font-family: var(--font-open-sans);
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  color: var(--title-color);
  border-radius: 0;
}
.car-details-sidebar .recent-car-area .nav-tabs li button.active {
  color: var(--white-color);
  background-color: var(--title-color);
}
.car-details-sidebar .side-banner a {
  display: block;
  margin-top: 20px;
}
.car-details-sidebar .side-banner a:first-child {
  margin-top: 0;
}

/*=====================================
   52. Product Details Area
========================================*/
.product-details-page .tab-content1 .tab-pane {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: #FFF;
  padding: 10px;
}
.product-details-page .tab-content1 .tab-pane img {
  border-radius: 5px;
}
@media (max-width: 991px) {
  .product-details-page .tab-content1 .tab-pane img {
    width: 100%;
  }
}
.product-details-page .nav1 {
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 30px;
}
@media (max-width: 576px) {
  .product-details-page .nav1 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
}
.product-details-page .nav1 .nav-link {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  padding: 5px;
}
.product-details-page .nav1 .nav-link img {
  border-radius: 5px;
}
@media (max-width: 576px) {
  .product-details-page .nav1 .nav-link {
    max-width: 130px;
    width: 100%;
  }
}
.product-details-page .nav1 .nav-link.active {
  background-color: transparent;
  border-color: var(--primary-color1);
}
.product-details-page .product-details-content .product-review2 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
  margin-bottom: 8px;
}
.product-details-page .product-details-content .product-review2 li {
  line-height: 1;
}
.product-details-page .product-details-content .product-review2 li i {
  color: #DDA701;
}
.product-details-page .product-details-content .product-review2 li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  transition: 0.35s;
  line-height: 1;
  padding-left: 8px;
}
.product-details-page .product-details-content .product-review2 li a:hover {
  color: var(--primary-color1);
}
.product-details-page .product-details-content h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 576px) {
  .product-details-page .product-details-content h4 {
    font-size: 20px;
  }
}
.product-details-page .product-details-content p {
  margin-bottom: 20px;
  color: var(--text-color);
  line-height: 30px;
}
.product-details-page .product-details-content .price-tag {
  line-height: 1;
  margin-bottom: 22px;
}
.product-details-page .product-details-content .price-tag h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}
.product-details-page .product-details-content .price-tag h5 del {
  color: var(--text-color);
  font-weight: 500;
  padding-left: 5px;
}
.product-details-page .product-details-content .product-quantity {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  gap: 24px;
}
@media (max-width: 576px) {
  .product-details-page .product-details-content .product-quantity {
    flex-wrap: wrap;
    gap: 15px;
  }
}
.product-details-page .product-details-content .product-quantity .quantity {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product-details-page .product-details-content .product-quantity .quantity .quantity__minus,
.product-details-page .product-details-content .product-quantity .quantity .quantity__plus {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: 1px solid #eee;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.35s;
}
.product-details-page .product-details-content .product-quantity .quantity .quantity__minus span i,
.product-details-page .product-details-content .product-quantity .quantity .quantity__plus span i {
  font-size: 20px;
  color: var(--title-color);
  transition: 0.35s;
}
.product-details-page .product-details-content .product-quantity .quantity .quantity__minus:hover,
.product-details-page .product-details-content .product-quantity .quantity .quantity__plus:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.product-details-page .product-details-content .product-quantity .quantity .quantity__input {
  height: 40px;
  width: 78px;
  border-radius: 5px;
  border: 1px solid rgba(19, 20, 26, 0.16);
  color: rgba(19,20,26,0.9);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
}
.product-details-page .product-details-content .product-quantity .primary-btn3 {
  padding: 5px 25px;
}
.product-details-page .product-details-content .product-quantity .fav {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #eee;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.35s;
}
.product-details-page .product-details-content .product-quantity .fav svg {
  fill: var(--title-color);
}
.product-details-page .product-details-content .product-quantity .fav:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.product-details-page .product-details-content .aditional-info {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
}
.product-details-page .product-details-content .aditional-info > li {
  line-height: 1;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 3px;
}
.product-details-page .product-details-content .aditional-info > li:last-child {
  margin-bottom: 0;
}
.product-details-page .product-details-content .aditional-info > li span {
  color: var(--title-color);
  font-weight: 600;
  display: inline-block;
  margin-right: 5px;
}
.product-details-page .product-details-content .aditional-info > li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  transition: 0.35s;
  margin-bottom: 0;
}
.product-details-page .product-details-content .aditional-info > li a:hover {
  color: var(--primary-color1);
}
.product-details-page .product-details-content .aditional-info > li .social-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 14px;
  padding-left: 5px;
}
.product-details-page .product-details-content .aditional-info > li .social-icons li {
  margin-bottom: 0;
  line-height: 1;
}
.product-details-page .product-details-content .aditional-info > li .social-icons li a {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #EEE;
  transition: 0.35s;
}
.product-details-page .product-details-content .aditional-info > li .social-icons li a i {
  color: var(--primary-color1);
  transition: 0.35s;
}
.product-details-page .product-details-content .aditional-info > li .social-icons li a:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.product-details-page .product-details-content .aditional-info > li .social-icons li a:hover i {
  color: var(--title-color);
}
.product-details-page .nav2 {
  gap: 45px;
  justify-content: start;
  width: 100%;
  line-height: 1;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: 20px 0;
  margin-bottom: 30px;
}
.product-details-page .nav2 .nav-link {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  line-height: 1;
  background-color: transparent;
}
.product-details-page .nav2 .nav-link.active {
  background-color: transparent;
  color: var(--title-color);
  position: relative;
}
.product-details-page .nav2 .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid rgba(19,20,26,0.9);
  width: 100%;
}
.product-details-page .nav2 .nav-link.active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -32px;
  height: 14px;
  width: 14px;
  background-color: var(--white-color);
  transform: rotate(45deg) translateX(-50%);
  border: 1px solid rgba(19,20,26,0.9);
  border-right: none;
  border-bottom: none;
}
.product-details-page .tab-content2 .description p {
  color: var(--text-color);
  line-height: 30px;
}
.product-details-page .tab-content2 .description p:last-child {
  margin-bottom: 0;
}
.product-details-page .tab-content2 .description ul {
  list-style: decimal;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding-left: 17px;
  margin-bottom: 10px;
}
.product-details-page .tab-content2 .description ul li {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 15px;
}
.product-details-page .tab-content2 .description ul li strong {
  font-weight: 600;
}
.product-details-page .tab-content2 .reviews-area .number-of-review {
  line-height: 1;
  margin-bottom: 40px;
}
.product-details-page .tab-content2 .reviews-area .number-of-review h4 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
}
.product-details-page .tab-content2 .reviews-area .comment > li .single-comment-area .comment-content .author-and-review {
  margin-bottom: 8px;
}
.product-details-page .tab-content2 .reviews-area .comment > li .single-comment-area .comment-content .author-and-review .author-name-deg {
  margin-bottom: 7px;
}
.product-details-page .tab-content2 .reviews-area .comment > li .single-comment-area .comment-content .author-and-review .review {
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 5px;
  line-height: 1;
}
.product-details-page .tab-content2 .reviews-area .comment > li .single-comment-area .comment-content .author-and-review .review li {
  line-height: 1;
}
.product-details-page .tab-content2 .reviews-area .comment > li .single-comment-area .comment-content .author-and-review .review li i {
  color: #DDA701;
  font-size: 12px;
}
.product-details-page .tab-content2 .review-form {
  border: 1px solid #eee;
  padding: 50px 40px;
  border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-page .tab-content2 .review-form {
    padding: 40px 30px;
  }
}
@media (max-width: 576px) {
  .product-details-page .tab-content2 .review-form {
    padding: 40px 25px;
  }
}
.product-details-page .tab-content2 .review-form .number-of-review {
  line-height: 1;
  margin-bottom: 30px;
}
.product-details-page .tab-content2 .review-form .number-of-review h4 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
}
.product-details-page .tab-content2 .review-form .form-inner input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
}
.product-details-page .tab-content2 .review-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.product-details-page .tab-content2 .review-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.product-details-page .tab-content2 .review-form .form-inner input :focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.product-details-page .tab-content2 .review-form .form-inner textarea {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  min-height: 150px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
  outline: none;
}
.product-details-page .tab-content2 .review-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.product-details-page .tab-content2 .review-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.product-details-page .tab-content2 .review-form .form-inner textarea :focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area {
  margin-bottom: 15px;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area p {
  margin-bottom: 5px;
  color: var(--title-color);
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate {
  float: left;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  color: #ccc;
  padding-right: 8px;
  display: inline-block;
  margin-right: 8px;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label::before {
  content: "\f586";
  font-family: bootstrap-icons !important;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate input:checked ~ label {
  color: #DDA701;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label:hover,
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label:hover ~ label {
  color: #DDA701;
}
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area input:checked + label:hover,
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area input:checked + label:hover ~ label,
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area input:checked ~ label:hover,
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area input:checked ~ label:hover ~ label,
.product-details-page .tab-content2 .review-form .form-inner2 .review-rate-area label:hover ~ input:checked ~ label {
  color: #DDA701;
}
.product-details-page .tab-content2 .review-form .primary-btn3 {
  padding: 7px 25px;
  margin-top: 30px;
  font-family: var(--font-montserrat);
}
.product-details-page .addithonal-information .total-table2 {
  margin-bottom: 0;
}
.product-details-page .addithonal-information .total-table2 tbody tr {
  border-width: 1px;
  border-color: #eee;
}
.product-details-page .addithonal-information .total-table2 tbody tr td {
  border-width: 1px;
  border-color: #eee;
  padding: 15px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  line-height: 30px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .product-details-page .addithonal-information .total-table2 tbody tr td {
    padding: 10px;
  }
}
.product-details-page .addithonal-information .total-table2 tbody tr td:nth-child(1) {
  font-weight: 600;
  font-size: 14px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  text-align: start;
  line-height: 26px;
}
@media (min-width: 992px) {
  .product-details-page .addithonal-information .total-table2 tbody tr td:nth-child(1) {
    width: 25%;
  }
}
.product-details-page .section-title {
  line-height: 1;
  margin-bottom: 40px;
}
.product-details-page .section-title h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.3;
}

.product-search-area {
  margin-top: -80px;
}
.product-search-area form {
  border-radius: 10px;
  margin-top: 40px;
  border: 1px solid silver;
  background: rgba(19,20,26,0.9);
  /* opacity:0.8; */
  padding: 22px 40px 30px;
}
.mobile-banner-text{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width:500px){
  .mobile-banner-text{
    width: 100%;
    top: 50%;
    transform: translate(-36%, -50%);
  }
  .single-banner-slide a {
    color: #000;
    background: #fff;
    /* padding: 10px 45px; */
    border-radius: 100px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    margin-top: 30px;
    transition: 0.3s;
    margin-left:-100px;
}

}
@media (max-width: 1199px) {
  .product-search-area form {
    margin-top: 40px;
    padding: 22px 20px 30px;
  }
}
.product-search-area form .form-inner {
  width: 100%;
}
.product-search-area form .form-inner button {
  width: 100%;
  justify-content: center;
  padding: 10px 28px;
}
.product-search-area form .form-inner label {
  color: var(--white-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
  margin-bottom: 12px;
}
.product-search-area form .form-inner .nice-select {
  padding: 0px 20px;
}
.product-search-area form .form-inner .nice-select::after {
  right: 23px;
}
.product-search-area form .form-inner .nice-select:focus {
  border-color: var(--primary-color1);
}
.product-search-area form .form-inner .nice-select .list option {
  padding-left: 20px;
  padding-right: 20px;
}

/*=====================================
   53. Product Page
========================================*/
.product-sidebar {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.93);
  background: #FFF;
  padding: 10px;
}
.product-sidebar .wp-block-categoris-cloud {
  margin: 0;
  padding: 0;
  list-style: none;
}
.product-sidebar .wp-block-categoris-cloud li {
  line-height: 1;
  margin-bottom: 18px;
}
.product-sidebar .wp-block-categoris-cloud li:last-child {
  margin-bottom: 0;
}
.product-sidebar .wp-block-categoris-cloud li a {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  position: relative;
  transition: 0.35s;
}
.product-sidebar .wp-block-categoris-cloud li a::before {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.product-sidebar .wp-block-categoris-cloud li a .number-of-categoris {
  color: var(--text-color);
  font-weight: 400;
  transition: 0.35s;
}
.product-sidebar .wp-block-categoris-cloud li a:hover {
  color: var(--primary-color1);
}
.product-sidebar .wp-block-categoris-cloud li a:hover .number-of-categoris {
  color: var(--primary-color1);
}
.product-sidebar .product-widget {
  padding: 30px 24px;
  border-radius: 5px;
  background: #F6F4Fa;
}
.product-sidebar .product-widget:last-child {
  margin-bottom: 0;
}
.product-sidebar .product-widget .check-box-item h3,
.product-sidebar .product-widget .check-box-item .product-widget-title {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  justify-content: space-between;
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner {
  margin-bottom: 25px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner input {
  width: 100%;
  border-radius: 5px;
  background: #FFF;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #fff;
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner input:focus {
  border-color: var(--primary-color1);
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner .nice-select {
  background: #fff;
  height: 40px;
  padding: 0px 30px 0 20px;
  border: 1px solid #fff;
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner .nice-select .current {
  font-size: 13px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner .nice-select::after {
  right: 21px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner .nice-select:focus {
  border-color: var(--primary-color1);
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li:last-child {
  margin-bottom: 0;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss {
  display: flex;
  gap: 5px;
  width: 100%;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss span.text {
  font-weight: 600;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss input:checked ~ .checkmark::after {
  content: url(img/inner-page/icon/checkbox-check.svg);
  left: 1.5px;
  top: -2px;
  position: absolute;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss input:checked ~ span {
  color: var(--title-color);
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid rgba(70, 217, 147, 0.4);
  border-radius: 2px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss .stars i {
  color: #DDA701;
  font-size: 13px;
}
.product-sidebar .product-widget .check-box-item .checkbox-container > ul > li .containerss .stars a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.product-sidebar .product-widget .recent-product-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.product-sidebar .product-widget .recent-product-list .recent-product {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(215, 215, 215, 0.6);
  margin-bottom: 15px;
}
.product-sidebar .product-widget .recent-product-list .recent-product:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0px;
}
.product-sidebar .product-widget .recent-product-list .recent-product .product-img {
  height: 70px;
  min-width: 80px;
  max-width: 80px;
  border-radius: 5px;
}
.product-sidebar .product-widget .recent-product-list .recent-product .product-img img {
  border-radius: 5px;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content {
  line-height: 1;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content .price {
  line-height: 1;
  margin-bottom: 7px;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content .price h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content .price h6 ins {
  text-decoration: none;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content .price h6 del {
  opacity: 0.5;
  font-size: 80%;
  margin-left: 5px;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content > h6 {
  margin-bottom: 0;
  line-height: 1;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content > h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.35s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-sidebar .product-widget .recent-product-list .recent-product .content > h6 a:hover {
  color: var(--primary-color1);
}

.range-wrapper .slider-wrapper {
  margin-bottom: 25px;
}
.range-wrapper .slider-wrapper .ui-slider-horizontal {
  height: 10px;
  border-radius: 10px;
}
.range-wrapper .slider-wrapper .ui-widget.ui-widget-content {
  border: none;
  background-color: rgba(70, 217, 147, 0.4);
}
.range-wrapper .slider-wrapper .ui-slider-range {
  background-color: var(--primary-color1);
}
.range-wrapper .slider-wrapper .ui-slider-handle {
  border: 5px solid #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: var(--primary-color1);
  top: -6px;
}
.range-wrapper .slider-wrapper .ui-slider-handle:focus-visible {
  outline: none;
}
.range-wrapper .valus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range-wrapper .valus .min-value,
.range-wrapper .valus .max-value {
  max-width: 45%;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
}
.range-wrapper .valus .min-value span,
.range-wrapper .valus .max-value span {
  line-height: 1;
  display: flex;
  margin-top: 3px;
}
.range-wrapper .valus .min-value input,
.range-wrapper .valus .max-value input {
  width: 100%;
  background-color: transparent;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 12px 5px;
}

.product-page .show-item-and-filte {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 576px) {
  .product-page .show-item-and-filte {
    justify-content: center;
  }
  .product-search-area {
    margin-top: 20px;
  }
}
.product-page .show-item-and-filte p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}
.product-page .show-item-and-filte p strong {
  font-weight: 600;
}
.product-page .show-item-and-filte .filter-view {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 767px) {
  .product-page .show-item-and-filte .filter-view .view {
    display: none;
    visibility: hidden;
  }
}
.product-page .show-item-and-filte .filter-view .view ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
.product-page .show-item-and-filte .filter-view .view ul li {
  line-height: 1;
  height: 26px;
  width: 26px;
  border-end-end-radius: 2px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product-page .show-item-and-filte .filter-view .view ul li svg {
  fill: var(--text-color);
}
.product-page .show-item-and-filte .filter-view .view ul li.active svg {
  fill: var(--primary-color1);
}
.product-page .show-item-and-filte .filter-atra {
  border-radius: 5px;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 6px 6px 20px;
}
.product-page .show-item-and-filte .filter-atra h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.product-page .show-item-and-filte .filter-atra form {
  min-width: 180px;
}
.product-page .show-item-and-filte .filter-atra .nice-select {
  height: 30px;
  background-color: var(--primary-color1);
  padding-left: 15px;
  padding-right: 35px;
}
.product-page .show-item-and-filte .filter-atra .nice-select::after {
  border-color: var(--title-color);
  right: 18px;
  height: 7px;
  width: 7px;
}
.product-page .show-item-and-filte .filter-atra .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.product-page .filter-area .title-and-close-btn {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 30px;
}
.product-page .filter-area .title-and-close-btn h6 {
  margin-bottom: 0;
  line-height: 1;
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.product-page .filter-area .title-and-close-btn .close-btn {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.product-page .filter-area .title-and-close-btn .close-btn svg {
  fill: var(--text-color);
}
.product-page .filter-area .tags ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-page .filter-area .tags ul li {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 14.5px;
  border: 1px solid #EEE;
  background: #F6F4Fa;
  padding: 5px 10px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
}
.product-page .filter-area .tags ul li svg {
  fill: var(--text-color);
}

.pagination-and-next-prev {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}
.pagination-and-next-prev .pagination {
  line-height: 1;
}
.pagination-and-next-prev .pagination.auction ul {
  margin-top: 50px;
}
.pagination-and-next-prev .pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
}
.pagination-and-next-prev .pagination ul li a {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  transition: 0.35s;
}
.pagination-and-next-prev .pagination ul li a:hover {
  border-color: var(--primary-color1);
  color: var(--primary-color1);
}
.pagination-and-next-prev .pagination ul li.active a {
  border-color: var(--primary-color1);
  color: var(--title-color);
  background-color: var(--primary-color1);
}
.pagination-and-next-prev .next-prev-btn ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 40px;
}
.pagination-and-next-prev .next-prev-btn ul li {
  position: relative;
  display: flex;
  gap: 10px;
}
.pagination-and-next-prev .next-prev-btn ul li::before {
  content: "";
  width: 1px;
  height: 14px;
  opacity: 0.4;
  background: rgba(19,20,26,0.9);
  position: absolute;
  left: -21px;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-and-next-prev .next-prev-btn ul li:first-child::before {
  display: none;
}
.pagination-and-next-prev .next-prev-btn ul li a {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  transition: 0.35s;
  display: flex;
  align-items: center;
  gap: 6px;
}
.pagination-and-next-prev .next-prev-btn ul li a svg {
  fill: var(--text-color);
  transition: 0.35s;
}
.pagination-and-next-prev .next-prev-btn ul li a:hover {
  color: var(--title-color);
}
.pagination-and-next-prev .next-prev-btn ul li a:hover svg {
  fill: var(--title-color);
}

/*=====================================
   54. Blog  Page
========================================*/
.blog-standard-page .news-card2 {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding: 10px;
}
.blog-standard-page .news-card2 .news-img {
  position: relative;
  border-radius: 5px;
}
.blog-standard-page .news-card2 .news-img img {
  border-radius: 5px;
}
.blog-standard-page .news-card2 .news-img .date {
  position: absolute;
  left: -10px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(100% 0, 93% 50%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 93% 50%, 100% 100%, 0 100%, 0 0);
  padding: 0px 22px;
  line-height: 1;
}
.blog-standard-page .news-card2 .news-img .date a {
  -webkit-clip-path: unset;
          clip-path: unset;
  background-color: transparent;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--title-color);
  position: relative;
}
.blog-standard-page .news-card2 .news-img .date a::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.5);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-standard-page .news-card2 .news-img .date a:first-child::after {
  display: none;
}
.blog-standard-page .news-card2 .content {
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.blog-standard-page .news-card2 .content h4 {
  margin-bottom: 10px;
  line-height: 1;
}
.blog-standard-page .news-card2 .content h4 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 600;
  line-height: 1.5;
  transition: 0.35s;
  word-break: break-all;
}
@media (max-width: 767px) {
  .blog-standard-page .news-card2 .content h4 a {
    font-size: 20px;
  }
}
.blog-standard-page .news-card2 .content h4 a:hover {
  color: var(--primary-color1);
}
.blog-standard-page .news-card2 .content p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
}
.blog-standard-page .news-card2 .content .news-btm {
  border-top: 1px solid #eee;
  padding-top: 25px;
  gap: 15px;
  flex-wrap: wrap;
}
.blog-standard-page .news-card2 .content .news-btm .author-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-standard-page .news-card2 .content .news-btm .author-area .author-img img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.blog-standard-page .news-card2 .content .news-btm .author-area .author-content {
  display: inline-grid;
}
.blog-standard-page .news-card2 .content .news-btm .author-area .author-content h6 {
  margin-bottom: 3px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: var(--title-color);
}
.blog-standard-page .news-card2 .content .news-btm .author-area .author-content a {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  transition: 0.35s;
}
.blog-standard-page .news-card2 .content .news-btm .author-area .author-content a:hover {
  color: var(--primary-color1);
}
.blog-standard-page .news-card2 .content .news-btm .author-area .author-content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
}

.blog-sidebar {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px;
}
.blog-sidebar .single-widgets {
  padding: 30px 24px;
  border-radius: 5px;
  background: #F6F4Fa;
  margin-top: 20px;
}
.blog-sidebar .single-widgets:first-child {
  margin-top: 0;
}
.blog-sidebar .single-widgets .widget-title {
  line-height: 1;
}
.blog-sidebar .single-widgets .widget-title h6 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.blog-sidebar .single-widgets .wp-block-categories {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-sidebar .single-widgets .wp-block-categories li {
  line-height: 1;
  margin-bottom: 18px;
}
.blog-sidebar .single-widgets .wp-block-categories li:last-child {
  margin-bottom: 0;
}
.blog-sidebar .single-widgets .wp-block-categories li a {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  position: relative;
  transition: 0.35s;
}
.blog-sidebar .single-widgets .wp-block-categories li a::before {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.blog-sidebar .single-widgets .wp-block-categories li a .number-of-categoris {
  color: var(--text-color);
  font-weight: 400;
  transition: 0.35s;
}
.blog-sidebar .single-widgets .wp-block-categories li a:hover {
  color: var(--primary-color1);
}
.blog-sidebar .single-widgets .wp-block-categories li a:hover .number-of-categoris {
  color: var(--primary-color1);
}
.blog-sidebar .single-widgets .blog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-sidebar .single-widgets .blog-title .slider-btn-group2 {
  max-width: 50px;
  min-width: 50px;
}
.blog-sidebar .single-widgets .blog-title .slider-btn-group2 .slider-btn {
  height: 20px;
  width: 20px;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wi {
  margin-bottom: 15px;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wi img {
  border-radius: 5px;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wc {
  line-height: 1;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wc .date {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wc .date::after {
  content: "";
  width: 30px;
  height: 1px;
  background: linear-gradient(90deg, #5E5E5E 0%, rgba(94, 94, 94, 0) 100%);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wc h6 {
  margin-bottom: 0;
  line-height: 1;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wc h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  transition: 0.35s;
}
.blog-sidebar .single-widgets .recent-post-wraper .widget-cnt .wc h6 a:hover {
  color: var(--primary-color1);
}
.blog-sidebar .single-widgets .wp-block-tag-cloud {
  margin-bottom: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.blog-sidebar .single-widgets .wp-block-tag-cloud a {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-open-sans);
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  line-height: 1;
  padding: 8px 18px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
@media (max-width: 1399px) {
  .blog-sidebar .single-widgets .wp-block-tag-cloud a {
    padding: 8px 14px;
  }
}
.blog-sidebar .single-widgets .wp-block-tag-cloud a::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #FFF;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.blog-sidebar .single-widgets .wp-block-tag-cloud a:hover {
  color: var(--title-color);
}
.blog-sidebar .single-widgets .wp-block-tag-cloud a:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.blog-sidebar .single-widgets.widget_media_image figure,
.blog-sidebar .single-widgets.widget_media_image .wp-block-image {
  margin: 0;
}

.sidebar-banner {
  background-image: url(img/inner-page/product-wd-bg.png), linear-gradient(#F6F4Fa, #F6F4Fa);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  min-height: 430px;
  position: relative;
  padding: 0;
}
.sidebar-banner .product-content {
  position: relative;
  z-index: 1;
}
.sidebar-banner .product-content .text {
  z-index: 9;
  position: relative;
  padding: 40px 30px;
  padding-bottom: 150px;
}
.sidebar-banner .product-content .text h4 {
  margin-bottom: 10px;
  line-height: 1;
}
.sidebar-banner .product-content .text h4 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
}
.sidebar-banner .product-content .text h4 a span {
  font-size: 16px;
  font-weight: 500;
}
.sidebar-banner .product-content .text h6 {
  margin: 0;
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.sidebar-banner .product-content .text h6::after {
  content: "";
  height: 1px;
  width: 40px;
  background-color: var(--title-color);
  position: absolute;
  right: -50px;
  top: 55%;
  transform: translateY(-50%);
}
.sidebar-banner .product-content .offer-batch {
  line-height: 1;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: var(--white-color);
  z-index: 9;
  position: absolute;
  display: flex;
  align-items: centre;
  justify-content: center;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
  text-align: center;
}
.sidebar-banner .product-content .offer-batch::after {
  content: "";
  height: 137px;
  width: 137px;
  border: 1px dashed var(--title-color);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.sidebar-banner .product-content .offer-batch h3 {
  margin-bottom: 10px;
  line-height: 1;
  color: rgba(19,20,26,0.9);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 28px;
  font-weight: 700;
  display: inline-block;
}
.sidebar-banner .product-content .offer-batch span {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
}
.sidebar-banner .product-content::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #1CBD71;
  -webkit-clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
          clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px 5px 0 0;
}
.sidebar-banner .product-content::after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(100% 0, 100% 68%, 50% 100%, 0 68%, 0 0);
          clip-path: polygon(100% 0, 100% 68%, 50% 100%, 0 68%, 0 0);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px 5px 0 0;
}
.sidebar-banner .product-img {
  z-index: 92;
  position: relative;
  padding: 0px 30px 20px;
}

.blog-details-page .post-thumb {
  position: relative;
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 10px;
  margin-bottom: 30px;
}
.blog-details-page .post-thumb img {
  border-radius: 5px;
  width: 100%;
}
.blog-details-page .post-thumb .date {
  position: absolute;
  left: 0px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--primary-color1);
  -webkit-clip-path: polygon(100% 0, 93% 50%, 100% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 93% 50%, 100% 100%, 0 100%, 0 0);
  padding: 0px 22px;
  line-height: 1;
}
.blog-details-page .post-thumb .date a {
  -webkit-clip-path: unset;
          clip-path: unset;
  background-color: transparent;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: var(--title-color);
  position: relative;
}
.blog-details-page .post-thumb .date a::after {
  content: "";
  height: 10px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.5);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-page .post-thumb .date a:first-child::after {
  display: none;
}
.blog-details-page .post-title {
  margin-bottom: 12px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5;
}
.blog-details-page .post-title span {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  transition: 0.35s;
}
.blog-details-page .post-title span:hover {
  color: var(--primary-color1);
}
@media (max-width: 767px) {
  .blog-details-page .post-title {
    font-size: 22px;
  }
  .blog-details-page .post-title a {
    font-size: 16px;
  }
}
.blog-details-page .author-area {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.blog-details-page .author-area .author-img img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.blog-details-page .author-area .author-content {
  display: inline-grid;
}
.blog-details-page .author-area .author-content h6 {
  margin-bottom: 3px;
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 18px;
  color: var(--title-color);
}
.blog-details-page .author-area .author-content a {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  transition: 0.35s;
}
.blog-details-page .author-area .author-content a:hover {
  color: var(--primary-color1);
}
.blog-details-page .author-area .author-content span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
}
.blog-details-page p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 12px;
}
.blog-details-page blockquote {
  position: relative;
  margin-top: 50px;
  margin-bottom: 45px;
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 30px 40px;
  border: 0;
}
@media (max-width: 767px) {
  .blog-details-page blockquote {
    padding: 30px 20px;
  }
}
.blog-details-page blockquote:before {
  position: absolute;
  content: url(img/qoute.svg);
  left: 58px;
  top: -23px;
  width: 46px;
  line-height: 0.8;
  height: 46px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-details-page blockquote p {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 10px;
}
.blog-details-page blockquote cite {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  padding-left: 80px;
  position: relative;
  display: inline-block;
}
.blog-details-page blockquote cite::before {
  content: "";
  width: 60px;
  height: 1px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color1);
}
.blog-details-page .blog-dt-img img {
  border-radius: 10px;
}
.blog-details-page .blog-tag-social-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  margin-top: 26px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.blog-details-page .blog-tag-social-area .bolg-tag {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-details-page .blog-tag-social-area .bolg-tag h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
}
.blog-details-page .blog-tag-social-area .bolg-tag ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.blog-details-page .blog-tag-social-area .bolg-tag ul li {
  line-height: 1;
}
.blog-details-page .blog-tag-social-area .bolg-tag ul li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  transition: 0.35s;
}
.blog-details-page .blog-tag-social-area .bolg-tag ul li a:hover {
  color: var(--primary-color1);
}
.blog-details-page .blog-tag-social-area .social-area {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-details-page .blog-tag-social-area .social-area h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
}
.blog-details-page .blog-tag-social-area .social-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-details-page .blog-tag-social-area .social-area ul li {
  line-height: 1;
}
.blog-details-page .blog-tag-social-area .social-area ul li a {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #eee;
  color: var(--primary-color1);
  transition: 0.35s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-details-page .blog-tag-social-area .social-area ul li a:hover {
  color: var(--title-color);
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.blog-details-page .details-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 576px) {
  .blog-details-page .details-navigation {
    justify-content: center;
  }
}
.blog-details-page .details-navigation .single-navigation {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: 0.35s;
}
.blog-details-page .details-navigation .single-navigation .arrow {
  max-width: 30px;
  min-width: 30px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid rgba(19, 20, 26, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.blog-details-page .details-navigation .single-navigation .arrow svg {
  fill: rgba(19, 20, 26, 0.16);
  transition: 0.35s;
}
.blog-details-page .details-navigation .single-navigation .arrow:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.blog-details-page .details-navigation .single-navigation .arrow:hover svg {
  fill: var(--title-color);
}
.blog-details-page .details-navigation .single-navigation .content {
  max-width: 250px;
  width: 100%;
  line-height: 1;
  transition: 0.35s;
}
.blog-details-page .details-navigation .single-navigation .content > a {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  transition: 0.35s;
  margin-bottom: 5px;
  display: inline-block;
  text-transform: uppercase;
}
.blog-details-page .details-navigation .single-navigation .content h6 {
  transition: 0.35s;
  margin-bottom: 0;
  line-height: 1;
}
.blog-details-page .details-navigation .single-navigation .content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.35s;
}

.comment-respond {
  border-radius: 10px;
  padding: 45px 40px 50px;
  background: #F6F4Fa;
  clear: both;
}
@media (max-width: 767px) {
  .comment-respond {
    padding: 45px 20px 50px;
  }
}
.comment-respond h3.comment-reply-title {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.comment-respond h3.comment-reply-title small a {
  color: var(--primary-color1);
  font-size: 14px;
}
.comment-respond p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.comment-respond p.logged-in-as {
  margin-bottom: 10px;
  display: block;
}
.comment-respond p.logged-in-as a {
  color: var(--primary-color1);
}
.comment-respond .form-inner {
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
}
.comment-respond .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.comment-respond .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.comment-respond .form-inner label.containerss span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1;
}
.comment-respond .form-inner label.containerss span.text {
  font-weight: 600;
}
.comment-respond .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.comment-respond .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.comment-respond .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(img/inner-page/icon/checkbox-check.svg);
  left: 1.5px;
  top: -2px;
  position: absolute;
}
.comment-respond .form-inner label.containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 2px;
}
.comment-respond .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.comment-respond .form-inner label.containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.comment-respond .form-inner label.containerss .stars li {
  line-height: 1;
}
.comment-respond .form-inner label.containerss .stars li i {
  color: #DDA701;
  font-size: 13px;
}
.comment-respond .form-inner label.containerss .stars li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.comment-respond .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
}
.comment-respond .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.comment-respond .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.comment-respond .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border: none;
  min-height: 180px;
}
.comment-respond .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.comment-respond .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.comment-respond .form-inner .primary-btn3 {
  padding: 17px 28px;
  justify-content: center;
}
.comment-respond .form-inner .iti {
  width: 100%;
}
.comment-respond .form-inner .iti input {
  padding-left: 62px;
}
.comment-respond .form-inner .iti__selected-flag {
  padding-left: 20px;
}
.comment-respond .form-inner .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.comment-respond .form-inner .iti__country-list {
  max-width: 280px;
  box-shadow: unset;
  background-color: white;
  border: 1px solid var(--primary-color1);
}
.comment-respond .form-inner .iti__country-list .iti__country.iti__highlight {
  background-color: rgba(70, 217, 147, 0.5);
}
.comment-respond .form-inner .iti__country-list li {
  font-family: var(--font-montserrat);
  font-weight: 400;
}
.comment-respond .form-inner .iti__country-list li span {
  font-family: var(--font-montserrat);
  font-weight: 400;
}
.comment-respond .comment-form-cookies-consent {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.comment-respond .comment-form-cookies-consent input {
  margin-right: 5px;
}

.comment-title {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
}
.comment-title h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
}
.comment-title .dash {
  position: relative;
  width: 100%;
}
.comment-title .dash::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(238, 238, 238, 0.93);
}

.post-quote blockquote {
  position: relative;
  margin-top: 25px;
  margin-bottom: 45px;
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 30px 40px;
  border: 0;
}
@media (max-width: 767px) {
  .post-quote blockquote {
    padding: 30px 20px;
  }
}
.post-quote blockquote:before {
  position: absolute;
  content: url(img/qoute.svg);
  left: 58px;
  top: -23px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-quote blockquote p {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0;
}
.post-quote blockquote cite {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  padding-left: 80px;
  position: relative;
  display: inline-block;
}
.post-quote blockquote cite::before {
  content: "";
  width: 60px;
  height: 1px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color1);
}

.post-video iframe,
.post-audio iframe {
  border: 0;
  min-height: 270px;
  width: 100%;
}

.post-gallery {
  position: relative;
}
.post-gallery .slider-arrows.arrows-style-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.post-gallery .slider-arrows.arrows-style-2 .blog1-prev,
.post-gallery .slider-arrows.arrows-style-2 .blog1-next {
  background-color: #fff;
  padding: 2px 10px;
}

.list-group-wrapper .item {
  float: none;
  width: 100%;
}
.list-group-wrapper .item .product-card {
  display: flex;
  align-items: center;
}
.list-group-wrapper .item .product-card .product-img {
  max-width: 395px;
  width: 100%;
}
@media (max-width: 1399px) {
  .list-group-wrapper .item .product-card .product-img {
    max-width: 350px;
  }
}
.list-group-wrapper .item .product-card .product-content {
  padding: 15px 40px;
  width: 100%;
}
@media (max-width: 1399px) {
  .list-group-wrapper .item .product-card .product-content {
    padding: 15px 30px;
  }
}

.no-sidebar .list-group-wrapper .item {
  float: none;
  width: 50%;
}
.no-sidebar .list-group-wrapper .item .product-card {
  display: flex;
  align-items: center;
}
.no-sidebar .list-group-wrapper .item .product-card .product-img {
  max-width: 250px;
  width: 100%;
}
.no-sidebar .list-group-wrapper .item .product-card .product-content {
  padding: 15px 25px;
  width: 100%;
}
.no-sidebar .list-group-wrapper .item .product-card4 {
  display: flex;
  align-items: center;
}
.no-sidebar .list-group-wrapper .item .product-card4 .product-img {
  max-width: 250px;
  width: 100%;
}
.no-sidebar .list-group-wrapper .item .product-card4 .product-img img {
  border-radius: 5px 0 0 5px;
}
.no-sidebar .list-group-wrapper .item .product-card4 .product-content {
  padding: 15px 25px;
  width: 100%;
}

.toprated-used-cars {
  background-image: url(img/inner-page/toprated-used-cars.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 80px;
}
.toprated-used-cars .title {
  line-height: 1;
  margin-bottom: 10px;
}
.toprated-used-cars .title h4 {
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.toprated-used-cars .brand-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  -moz-columns: 5;
       columns: 5;
}
@media (max-width: 991px) {
  .toprated-used-cars .brand-list ul {
    -moz-columns: 4;
         columns: 4;
  }
}
@media (max-width: 767px) {
  .toprated-used-cars .brand-list ul {
    -moz-columns: 3;
         columns: 3;
  }
}
@media (max-width: 576px) {
  .toprated-used-cars .brand-list ul {
    -moz-columns: 2;
         columns: 2;
  }
}
.toprated-used-cars .brand-list ul li {
  padding-top: 20px;
}
.toprated-used-cars .brand-list ul li a {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  transition: 0.35s;
}
.toprated-used-cars .brand-list ul li a span {
  transition: 0.35s;
  color: var(--text-color);
  font-weight: 400;
}
.toprated-used-cars .brand-list ul li a:hover {
  color: var(--primary-color1);
}
.toprated-used-cars .brand-list ul li a:hover span {
  color: var(--primary-color1);
}

.product-category-page .single-category-wrap .category-card {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.43);
  background: rgba(246, 244, 250, 0.6);
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.product-category-page .single-category-wrap .category-card .category-img {
  background-image: url(img/inner-page/category-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 146px;
  width: 146px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
.product-category-page .single-category-wrap .category-card .content p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
}
.product-category-page .single-category-wrap .category-card .content p a {
  font-weight: 600;
  color: var(--title-color);
  transition: 0.35s;
}
.product-category-page .single-category-wrap .category-card .content p a:hover {
  color: var(--primary-color1);
}
.product-category-page .single-category-wrap .product-card .product-content .price-location {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding-bottom: 17px;
}
.product-category-page .single-category-wrap .slider-btn-group {
  transition: 0.35s;
  opacity: 0;
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn svg {
  fill: var(--title-color);
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn:hover {
  background-color: var(--title-color);
  border-color: var(--title-color);
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn:hover svg {
  fill: var(--white-color);
}
.product-category-page .single-category-wrap .position-relative:hover .slider-btn-group {
  opacity: 1;
}

.single-category-page .product-card .product-content .price-location {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding-bottom: 17px;
}

.compare-page .product-upload-area {
  background-image: url(img/inner-page/compare-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 35px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.compare-page .product-upload-area .upload-area {
  height: 118px;
  width: 118px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.compare-page .product-upload-area .upload-area i {
  font-size: 30px;
  color: var(--title-color);
}
.compare-page .product-upload-area .upload-area::after {
  content: "";
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 1px dashed rgba(19,20,26,0.9);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.compare-page .product-upload-area .comparea-content {
  line-height: 1;
}
.compare-page .product-upload-area .comparea-content h6 {
  margin-bottom: 3px;
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.compare-page .product-upload-area .comparea-content p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.compare-page .product-upload-area .comparea-content p a {
  color: var(--title-color);
  font-weight: 600;
}
.compare-page .car-details-menu.sticky {
  position: fixed;
  top: 60px;
  z-index: 99;
  max-width: 1300px;
  width: 100%;
}
@media (max-width: 1399px) {
  .compare-page .car-details-menu.sticky {
    max-width: 1120px;
  }
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu.sticky {
    max-width: 940px;
  }
}
@media (max-width: 991px) {
  .compare-page .car-details-menu.sticky {
    max-width: 700px;
    top: 77px;
  }
}
@media (max-width: 767px) {
  .compare-page .car-details-menu.sticky {
    max-width: 520px;
  }
}
@media (max-width: 576px) {
  .compare-page .car-details-menu.sticky {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
  }
}
.compare-page .car-details-menu .navbar {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 20px 0;
}
.compare-page .car-details-menu .navbar .nav-pills {
  gap: 45px;
  justify-content: flex-start;
  width: 100%;
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu .navbar .nav-pills {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item {
  line-height: 1;
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  line-height: 1;
  background-color: transparent;
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a.active {
  background-color: transparent;
  color: var(--title-color);
  position: relative;
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid rgba(19,20,26,0.9);
  width: 100%;
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::before {
    bottom: -13px;
  }
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -32px;
  height: 14px;
  width: 14px;
  background-color: var(--white-color);
  transform: rotate(45deg) translateX(-50%);
  border: 1px solid rgba(19,20,26,0.9);
  border-right: none;
  border-bottom: none;
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::after {
    bottom: -24px;
  }
}
.compare-page .single-compare .section-title {
  line-height: 1;
  position: relative;
  display: inline-flex;
}
.compare-page .single-compare .section-title::after {
  content: "";
  width: 43px;
  height: 1px;
  background: linear-gradient(90deg, rgba(19,20,26,0.9) 0%, rgba(19, 20, 26, 0) 100%);
  position: absolute;
  right: -53px;
  top: 50%;
  transform: translateY(-50%);
}
.compare-page .single-compare .section-title h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.compare-page .car-colors .title-and-slider-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.compare-page .car-colors .title-and-slider-btn .slider-btn-group2 {
  max-width: 68px;
  min-width: 68px;
}
@media (max-width: 576px) {
  .compare-page .car-colors .title-and-slider-btn .slider-btn-group2 {
    display: none;
  }
}
.compare-page .car-colors .title-and-slider-btn .slider-btn-group2 .slider-btn {
  width: 26px;
  height: 26px;
}
.compare-page .car-colors .car-color-wrap {
  text-align: center;
}
.compare-page .car-colors .car-color-wrap .content {
  padding-top: 30px;
}
.compare-page .car-colors .car-color-wrap .content h6 {
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.compare-page .faq-wrap {
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 30px 20px;
}
@media (max-width: 576px) {
  .compare-page .faq-wrap {
    padding: 20px 10px;
  }
}
.compare-page .section-title-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .compare-page .section-title-and-filter {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.compare-page .section-title-and-filter .filter-atra {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 6px 6px 20px;
}
.compare-page .section-title-and-filter .filter-atra h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 500;
}
.compare-page .section-title-and-filter .filter-atra .nice-select {
  height: 30px;
  background-color: var(--primary-color1);
  padding-left: 15px;
  padding-right: 35px;
}
.compare-page .section-title-and-filter .filter-atra .nice-select::after {
  border-color: var(--title-color);
  right: 18px;
  height: 7px;
  width: 7px;
}
.compare-page .section-title-and-filter .filter-atra .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.compare-page .text-input-area .form-inner {
  border-radius: 10px;
  border: 1px solid var(--primary-color1);
  background: #FFF;
  display: flex;
  padding: 15px 25px;
}
.compare-page .text-input-area .form-inner textarea {
  border: none;
  outline: none;
  width: 100%;
}
.compare-page .text-input-area .form-inner button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.compare-page .text-input-area .form-inner button svg {
  fill: var(--title-color);
}
.compare-page .load-moer-btn {
  margin-top: 70px;
}
.compare-page .load-moer-btn .primary-btn3 {
  text-align: center;
  justify-content: center;
  padding: 11px 35px;
}

.checkout-page .login-and-coupon-area {
  border-radius: 10px;
  background: #F5F3F9;
  padding: 17px 75px;
  position: relative;
}
.checkout-page .login-and-coupon-area::after {
  content: "";
  height: 96.8%;
  width: 99.9%;
  border: 5px solid #fff;
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 10px;
}
.checkout-page .login-and-coupon-area::before {
  content: "";
  -webkit-clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
          clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  width: 3px;
  height: 34px;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  .checkout-page .login-and-coupon-area::before {
    display: none;
    visibility: hidden;
  }
}
.checkout-page .login-and-coupon-area p {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 99;
}
@media (max-width: 991px) {
  .checkout-page .login-and-coupon-area p {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.checkout-page .login-and-coupon-area p button {
  color: #000;
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: 0.35s;
}
.checkout-page .login-and-coupon-area p button:hover {
  color: var(--primary-color1);
}
.checkout-page .login-and-coupon-area .cupor-code-area {
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  flex-wrap: wrap;
  z-index: 99;
}
@media (max-width: 576px) {
  .checkout-page .login-and-coupon-area .cupor-code-area {
    justify-content: center;
    gap: 3px;
  }
}
.checkout-page .login-and-coupon-area .cupor-code-area p {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 99;
}
@media (max-width: 991px) {
  .checkout-page .login-and-coupon-area .cupor-code-area p {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon > button {
  color: #000;
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: 0.35s;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon > button:hover {
  color: var(--primary-color1);
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form {
  position: absolute;
  top: calc(100% + 15px);
  right: -32px;
  width: 300px;
  display: flex;
  align-items: center;
  background-color: var(--title-color1);
  border: 1px solid #eee;
  transition: 0.35s;
  opacity: 0;
  visibility: hidden;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  padding: 5px 15px;
  border-radius: 50px;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form .primary-btn3 {
  width: 88px;
  padding: 5px 16px;
  justify-content: center;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon:hover .nav__search-form {
  opacity: 1;
  visibility: visible;
}
.checkout-page .inquiry-form {
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 45px 35px 50px;
}
@media (max-width: 767px) {
  .checkout-page .inquiry-form {
    padding: 45px 20px 50px;
  }
}
.checkout-page .inquiry-form .title {
  margin-bottom: 25px;
}
.checkout-page .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 700;
}
.checkout-page .inquiry-form .title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.checkout-page .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.checkout-page .inquiry-form .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.checkout-page .inquiry-form .form-inner label.containerss span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label.containerss span.text {
  font-weight: 600;
}
.checkout-page .inquiry-form .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkout-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.checkout-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(img/inner-page/icon/checkbox-check.svg);
  left: 1.5px;
  top: -3px;
  position: absolute;
}
.checkout-page .inquiry-form .form-inner label.containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
}
.checkout-page .inquiry-form .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.checkout-page .inquiry-form .form-inner label.containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.checkout-page .inquiry-form .form-inner label.containerss .stars li {
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label.containerss .stars li i {
  color: #DDA701;
  font-size: 13px;
}
.checkout-page .inquiry-form .form-inner label.containerss .stars li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.checkout-page .inquiry-form .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
}
.checkout-page .inquiry-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  height: 48px;
  width: 100%;
  padding: 20px 20px;
  outline: none;
  border: none;
  min-height: 100px;
}
.checkout-page .inquiry-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner .primary-btn3 {
  padding: 17px 28px;
  justify-content: center;
  width: 100%;
}
.checkout-page .cart-menu {
  min-height: 10rem;
  z-index: 99;
  transform-origin: top;
}
.checkout-page .cart-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 50px;
}
.checkout-page .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.checkout-page .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
.checkout-page .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  width: 100%;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.5s;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
.checkout-page .cart-body ul .single-item .item-area .main-item {
  display: flex;
  gap: 15px;
  width: 100%;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content-and-quantity {
  width: 100%;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--title-color);
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
.checkout-page .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}
.checkout-page .cart-footer .pricing-area {
  width: 100%;
}
.checkout-page .cart-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 10px 0px 10px;
}
.checkout-page .cart-footer .pricing-area ul:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 30px;
}
.checkout-page .cart-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.checkout-page .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
.checkout-page .choose-payment-mathord {
  margin-bottom: 40px;
  line-height: 1;
}
.checkout-page .choose-payment-mathord h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.checkout-page .choose-payment-mathord .payment-option {
  line-height: 1;
}
.checkout-page .choose-payment-mathord .payment-option ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.checkout-page .choose-payment-mathord .payment-option ul li {
  width: 110px;
  height: 68px;
  background-color: var(--white-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkout-page .choose-payment-mathord .payment-option ul li .checked {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: #D2E7EF;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
          clip-path: polygon(0 0, 0% 100%, 100% 0);
  border-top-left-radius: 5px;
}
.checkout-page .choose-payment-mathord .payment-option ul li .checked i {
  opacity: 0;
  color: var(--title-color);
  margin-left: 2px;
}
.checkout-page .choose-payment-mathord .payment-option ul li.active .checked {
  background: var(--primary-color1);
}
.checkout-page .choose-payment-mathord .payment-option ul li.active .checked i {
  opacity: 1;
}

.recent-car-parts-area {
  background: #F6F4Fa;
}

.faq-page-wrap .faq-area .section-title-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .faq-page-wrap .faq-area .section-title-and-filter {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.faq-page-wrap .faq-area .section-title-and-filter .section-title h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 6px 6px 20px;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 500;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra .nice-select {
  height: 30px;
  background-color: var(--primary-color1);
  padding-left: 15px;
  padding-right: 35px;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra .nice-select::after {
  border-color: var(--title-color);
  right: 18px;
  height: 7px;
  width: 7px;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.faq-page-wrap .faq-wrap {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.61);
  padding: 20px;
}
@media (max-width: 576px) {
  .faq-page-wrap .faq-wrap {
    padding: 10px 10px;
  }
}

.faq-inquiery-form {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.faq-inquiery-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.faq-inquiery-form .form-inner input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
}
.faq-inquiery-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner input:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.faq-inquiery-form .form-inner textarea {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  min-height: 110px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
  outline: none;
}
.faq-inquiery-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner textarea:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.faq-inquiery-form .select-category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
}
.faq-inquiery-form .select-category .form-check {
  min-height: unset;
  margin-bottom: 0;
  line-height: 1;
  display: inline-flex;
  align-items: end;
  gap: 6px;
  cursor: pointer;
  margin-right: 15px;
}
.faq-inquiery-form .select-category .form-check:last-child {
  margin-right: 0;
}
.faq-inquiery-form .select-category label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}
.faq-inquiery-form .select-category .form-check-input {
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-color: rgba(19, 20, 26, 0.4);
  position: relative;
}
.faq-inquiery-form .select-category .form-check-input:focus {
  box-shadow: none;
}
.faq-inquiery-form .select-category .form-check-input::after {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #EEE;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.faq-inquiery-form .select-category .form-check-input:checked[type=radio] {
  background-image: none;
  border-color: var(--primary-color1);
}
.faq-inquiery-form .select-category .form-check-input:checked[type=radio]::after {
  background-color: var(--primary-color1);
}
.faq-inquiery-form .primary-btn3 {
  padding: 10px 35px;
}

.contact-page .single-location {
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 40px;
}
@media (max-width: 767px) {
  .contact-page .single-location {
    padding: 40px 25px;
  }
}
.contact-page .single-location .title-and-view-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.contact-page .single-location .title-and-view-btn h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}
.contact-page .single-location .title-and-view-btn a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-weight: 600;
  transition: 0.35s;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.contact-page .single-location .title-and-view-btn a svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.contact-page .single-location .title-and-view-btn a:hover {
  color: var(--primary-color1);
}
.contact-page .single-location .title-and-view-btn a:hover svg {
  fill: var(--primary-color1);
}
.contact-page .single-location ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact-page .single-location ul li {
  display: flex;
  align-items: center;
  gap: 14px;
  line-height: 1;
  margin-bottom: 10px;
  transition: 0.35s;
}
.contact-page .single-location ul li:last-child {
  margin-bottom: 0;
}
.contact-page .single-location ul li .icon {
  max-width: 34px;
  min-width: 34px;
  height: 34px;
  border: 1px solid #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.contact-page .single-location ul li .icon svg {
  transition: 0.35s;
  fill: var(--primary-color1);
}
.contact-page .single-location ul li .info {
  line-height: 1;
}
.contact-page .single-location ul li .info a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  display: block;
}
.contact-page .single-location ul li .info a:hover {
  color: var(--primary-color1);
}
.contact-page .single-location ul li:hover .icon {
  background-color: var(--primary-color1);
}
.contact-page .single-location ul li:hover .icon svg {
  fill: var(--title-color);
}
.contact-page .single-contact {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}
.contact-page .single-contact .title {
  line-height: 1;
  background-color: var(--white-color);
  position: absolute;
  left: 35px;
  top: -9px;
  padding: 0 20px;
}
.contact-page .single-contact .title h6 {
  margin-bottom: 0;
  line-height: 1;
  color: #5E5E5E;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contact-page .single-contact .icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-page .single-contact .icon svg {
  fill: var(--title-color);
}
.contact-page .single-contact .content {
  line-height: 1;
}
.contact-page .single-contact .content span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}
.contact-page .single-contact .content h6 {
  margin-bottom: 0;
}
.contact-page .single-contact .content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  transition: 0.35s;
}
.contact-page .single-contact .content h6 a:hover {
  color: var(--primary-color1);
}
.contact-page .service-available {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding-top: 15px;
}
.contact-page .service-available span {
  color: #FF4820;
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}
.contact-page .service-available p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-page .service-available p strong {
  font-weight: 600;
}
.contact-page .section-title h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 700;
}
.contact-page .inquiry-form {
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 45px 35px 50px;
}
@media (max-width: 767px) {
  .contact-page .inquiry-form {
    padding: 45px 20px 50px;
  }
}
.contact-page .inquiry-form .title {
  margin-bottom: 25px;
}
.contact-page .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 700;
}
.contact-page .inquiry-form .title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-page .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.contact-page .inquiry-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.contact-page .inquiry-form .form-inner label span {
  color: var(--text-color);
}
.contact-page .inquiry-form .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.contact-page .inquiry-form .form-inner label.containerss span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1;
}
.contact-page .inquiry-form .form-inner label.containerss span.text {
  font-weight: 600;
}
.contact-page .inquiry-form .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.contact-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.contact-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(img/inner-page/icon/checkbox-check.svg);
  left: 1.5px;
  top: -3px;
  position: absolute;
}
.contact-page .inquiry-form .form-inner label.containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
}
.contact-page .inquiry-form .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.contact-page .inquiry-form .form-inner label.containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.contact-page .inquiry-form .form-inner label.containerss .stars li {
  line-height: 1;
}
.contact-page .inquiry-form .form-inner label.containerss .stars li i {
  color: #DDA701;
  font-size: 13px;
}
.contact-page .inquiry-form .form-inner label.containerss .stars li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.contact-page .inquiry-form .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
}
.contact-page .inquiry-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  width: 100%;
  padding: 20px 20px;
  outline: none;
  border: none;
  min-height: 100px;
}
.contact-page .inquiry-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner .primary-btn3 {
  padding: 17px 28px;
  justify-content: center;
}

.contact-map {
  line-height: 0;
}
.contact-map iframe {
  width: 100%;
  height: 450px;
}

.error-page {
  background-color: #F6F4Fa;
  padding: 165px 0;
}
@media (max-width: 576px) {
  .error-page {
    padding: 130px 0;
  }
}
.error-page .error-wrapper {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}
.error-page .error-wrapper .error-content-area h1 {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 12px;
}
.error-page .error-wrapper .error-content-area p {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 35px;
}

.customer-feedback-pages .feedback-card {
  padding: 40px 30px;
  background: #F6F4Fa;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.35s;
}
.customer-feedback-pages .feedback-card .feedback-top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 15px;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area {
  display: flex;
  flex-direction: column;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area ul li {
  line-height: 1;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area ul li i {
  font-style: 13px;
  color: #DDA701;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area img {
  margin-bottom: 5px;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.customer-feedback-pages .feedback-card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-color);
}
.customer-feedback-pages .feedback-card .author-name {
  display: flex;
  align-items: center;
  gap: 6px;
}
.customer-feedback-pages .feedback-card .author-name h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  padding-left: 30px;
  position: relative;
}
.customer-feedback-pages .feedback-card .author-name h6::after {
  content: "";
  height: 1px;
  width: 20px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.customer-feedback-pages .feedback-card .author-name span {
  color: var(--text-color);
  font-size: 12px;
  font-family: var(--font-open-sans);
  font-weight: 600;
}
.customer-feedback-pages .feedback-card:hover {
  border-color: var(--primary-color1);
}
.customer-feedback-pages .primary-btn3 {
  padding: 10px 45px;
}

.return-and-exchange-pages .update-date {
  display: flex;
  align-items: center;
  gap: 48px;
  line-height: 1;
}
.return-and-exchange-pages .update-date h6 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  position: relative;
}
.return-and-exchange-pages .update-date h6 i {
  color: var(--primary-color1);
}
.return-and-exchange-pages .update-date h6::after {
  content: "";
  width: 28px;
  height: 1px;
  background-color: var(--title-color);
  position: absolute;
  right: -38px;
  top: 50%;
  transform: translateY(-50%);
}
.return-and-exchange-pages .update-date p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.return-and-exchange-pages .return-and-exchange h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
}
.return-and-exchange-pages .return-and-exchange p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.return-and-exchange-pages .return-and-exchange p:last-child {
  margin-bottom: 0;
}
.return-and-exchange-pages .return-and-exchange ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 50px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .return-and-exchange-pages .return-and-exchange ul {
    padding-left: 20px;
  }
}
.return-and-exchange-pages .return-and-exchange ul li {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 15px;
}
.return-and-exchange-pages .return-and-exchange ul li strong {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

.range-wrapper2 .slider-wrapper {
  margin-bottom: 25px;
}
.range-wrapper2 .slider-wrapper .ui-slider-horizontal {
  height: 10px;
  border-radius: 10px;
}
.range-wrapper2 .slider-wrapper .ui-widget.ui-widget-content {
  border: none;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
}
.range-wrapper2 .slider-wrapper .ui-slider-range {
  background-color: var(--primary-color1);
}
.range-wrapper2 .slider-wrapper .ui-slider-handle {
  border: 5px solid var(--primary-color1);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: var(--white-color);
  top: -7px;
  display: inline-block;
  position: relative;
}
.range-wrapper2 .slider-wrapper .ui-slider-handle:focus-visible {
  outline: none;
}
.range-wrapper2 .valus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range-wrapper2 .valus .min-value,
.range-wrapper2 .valus .max-value {
  max-width: 45%;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #F3F3F3;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
}
.range-wrapper2 .valus .min-value span,
.range-wrapper2 .valus .max-value span {
  line-height: 1;
  display: flex;
  margin-top: 3px;
}
.range-wrapper2 .valus .min-value input,
.range-wrapper2 .valus .max-value input {
  width: 100%;
  background-color: transparent;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.range-wrapper2 .valus .min-value input::-moz-placeholder, .range-wrapper2 .valus .max-value input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.range-wrapper2 .valus .min-value input::placeholder,
.range-wrapper2 .valus .max-value input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}

.product-category-page .single-category-wrap .category-card {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.43);
  background: rgba(246, 244, 250, 0.6);
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.product-category-page .single-category-wrap .category-card .category-img {
  background-image: url(img/inner-page/category-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 146px;
  width: 146px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
.product-category-page .single-category-wrap .category-card .content p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
}
.product-category-page .single-category-wrap .category-card .content p a {
  font-weight: 600;
  color: var(--title-color);
  transition: 0.35s;
}
.product-category-page .single-category-wrap .category-card .content p a:hover {
  color: var(--primary-color1);
}
.product-category-page .single-category-wrap .product-card .product-content .price-location {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding-bottom: 17px;
}
.product-category-page .single-category-wrap .slider-btn-group {
  transition: 0.35s;
  opacity: 0;
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn svg {
  fill: var(--title-color);
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn:hover {
  background-color: var(--title-color);
  border-color: var(--title-color);
}
.product-category-page .single-category-wrap .slider-btn-group .slider-btn:hover svg {
  fill: var(--white-color);
}
.product-category-page .single-category-wrap .position-relative:hover .slider-btn-group {
  opacity: 1;
}

.single-category-page .product-card .product-content .price-location {
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  padding-bottom: 17px;
}

.compare-page .product-upload-area {
  background-image: url(img/inner-page/compare-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 35px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.compare-page .product-upload-area .upload-area {
  height: 118px;
  width: 118px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.compare-page .product-upload-area .upload-area i {
  font-size: 30px;
  color: var(--title-color);
}
.compare-page .product-upload-area .upload-area::after {
  content: "";
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 1px dashed rgba(19,20,26,0.9);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.compare-page .product-upload-area .comparea-content {
  line-height: 1;
}
.compare-page .product-upload-area .comparea-content h6 {
  margin-bottom: 3px;
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.compare-page .product-upload-area .comparea-content p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.compare-page .product-upload-area .comparea-content p a {
  color: var(--title-color);
  font-weight: 600;
}
.compare-page .car-details-menu.sticky {
  position: fixed;
  top: 60px;
  z-index: 99;
  max-width: 1300px;
  width: 100%;
}
@media (max-width: 1399px) {
  .compare-page .car-details-menu.sticky {
    max-width: 1120px;
  }
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu.sticky {
    max-width: 940px;
  }
}
@media (max-width: 991px) {
  .compare-page .car-details-menu.sticky {
    max-width: 700px;
    top: 77px;
  }
}
@media (max-width: 767px) {
  .compare-page .car-details-menu.sticky {
    max-width: 520px;
  }
}
@media (max-width: 576px) {
  .compare-page .car-details-menu.sticky {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
  }
}
.compare-page .car-details-menu .navbar {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 20px 0;
}
.compare-page .car-details-menu .navbar .nav-pills {
  gap: 45px;
  justify-content: flex-start;
  width: 100%;
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu .navbar .nav-pills {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item {
  line-height: 1;
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a {
  color: var(--text-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  line-height: 1;
  background-color: transparent;
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a.active {
  background-color: transparent;
  color: var(--title-color);
  position: relative;
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid rgba(19,20,26,0.9);
  width: 100%;
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::before {
    bottom: -13px;
  }
}
.compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -32px;
  height: 14px;
  width: 14px;
  background-color: var(--white-color);
  transform: rotate(45deg) translateX(-50%);
  border: 1px solid rgba(19,20,26,0.9);
  border-right: none;
  border-bottom: none;
}
@media (max-width: 1199px) {
  .compare-page .car-details-menu .navbar .nav-pills .nav-item a.active::after {
    bottom: -24px;
  }
}
.compare-page .single-compare .section-title {
  line-height: 1;
  position: relative;
  display: inline-flex;
}
.compare-page .single-compare .section-title::after {
  content: "";
  width: 43px;
  height: 1px;
  background: linear-gradient(90deg, rgba(19,20,26,0.9) 0%, rgba(19, 20, 26, 0) 100%);
  position: absolute;
  right: -53px;
  top: 50%;
  transform: translateY(-50%);
}
.compare-page .single-compare .section-title h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.compare-page .car-colors .title-and-slider-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.compare-page .car-colors .title-and-slider-btn .slider-btn-group2 {
  max-width: 68px;
  min-width: 68px;
}
@media (max-width: 576px) {
  .compare-page .car-colors .title-and-slider-btn .slider-btn-group2 {
    display: none;
  }
}
.compare-page .car-colors .title-and-slider-btn .slider-btn-group2 .slider-btn {
  width: 26px;
  height: 26px;
}
.compare-page .car-colors .car-color-wrap {
  text-align: center;
}
.compare-page .car-colors .car-color-wrap .content {
  padding-top: 30px;
}
.compare-page .car-colors .car-color-wrap .content h6 {
  color: #000;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.compare-page .faq-wrap {
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 30px 20px;
}
@media (max-width: 576px) {
  .compare-page .faq-wrap {
    padding: 20px 10px;
  }
}
.compare-page .section-title-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .compare-page .section-title-and-filter {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.compare-page .section-title-and-filter .filter-atra {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 6px 6px 20px;
}
.compare-page .section-title-and-filter .filter-atra h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 500;
}
.compare-page .section-title-and-filter .filter-atra .nice-select {
  height: 30px;
  background-color: var(--primary-color1);
  padding-left: 15px;
  padding-right: 35px;
}
.compare-page .section-title-and-filter .filter-atra .nice-select::after {
  border-color: var(--title-color);
  right: 18px;
  height: 7px;
  width: 7px;
}
.compare-page .section-title-and-filter .filter-atra .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.compare-page .text-input-area .form-inner {
  border-radius: 10px;
  border: 1px solid var(--primary-color1);
  background: #FFF;
  display: flex;
  padding: 15px 25px;
}
.compare-page .text-input-area .form-inner textarea {
  border: none;
  outline: none;
  width: 100%;
}
.compare-page .text-input-area .form-inner button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.compare-page .text-input-area .form-inner button svg {
  fill: var(--title-color);
}
.compare-page .load-moer-btn {
  margin-top: 70px;
}
.compare-page .load-moer-btn .primary-btn3 {
  text-align: center;
  justify-content: center;
  padding: 11px 35px;
}

.checkout-page .login-and-coupon-area {
  border-radius: 10px;
  background: #F5F3F9;
  padding: 17px 75px;
  position: relative;
}
.checkout-page .login-and-coupon-area::after {
  content: "";
  height: 96.8%;
  width: 99.9%;
  border: 5px solid #fff;
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 10px;
}
.checkout-page .login-and-coupon-area::before {
  content: "";
  -webkit-clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
          clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  width: 3px;
  height: 34px;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  .checkout-page .login-and-coupon-area::before {
    display: none;
    visibility: hidden;
  }
}
.checkout-page .login-and-coupon-area p {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 99;
}
@media (max-width: 991px) {
  .checkout-page .login-and-coupon-area p {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.checkout-page .login-and-coupon-area p button {
  color: #000;
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: 0.35s;
}
.checkout-page .login-and-coupon-area p button:hover {
  color: var(--primary-color1);
}
.checkout-page .login-and-coupon-area .cupor-code-area {
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  flex-wrap: wrap;
  z-index: 99;
}
@media (max-width: 576px) {
  .checkout-page .login-and-coupon-area .cupor-code-area {
    justify-content: center;
    gap: 3px;
  }
}
.checkout-page .login-and-coupon-area .cupor-code-area p {
  margin-bottom: 0;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 99;
}
@media (max-width: 991px) {
  .checkout-page .login-and-coupon-area .cupor-code-area p {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon > button {
  color: #000;
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: 0.35s;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon > button:hover {
  color: var(--primary-color1);
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form {
  position: absolute;
  top: calc(100% + 15px);
  right: -32px;
  width: 300px;
  display: flex;
  align-items: center;
  background-color: var(--title-color1);
  border: 1px solid #eee;
  transition: 0.35s;
  opacity: 0;
  visibility: hidden;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  padding: 5px 15px;
  border-radius: 50px;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon .nav__search-form .primary-btn3 {
  width: 88px;
  padding: 5px 16px;
  justify-content: center;
}
.checkout-page .login-and-coupon-area .cupor-code-area .cupon:hover .nav__search-form {
  opacity: 1;
  visibility: visible;
}
.checkout-page .inquiry-form {
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 45px 35px 50px;
}
@media (max-width: 767px) {
  .checkout-page .inquiry-form {
    padding: 45px 20px 50px;
  }
}
.checkout-page .inquiry-form .title {
  margin-bottom: 25px;
}
.checkout-page .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 700;
}
.checkout-page .inquiry-form .title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.checkout-page .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.checkout-page .inquiry-form .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.checkout-page .inquiry-form .form-inner label.containerss span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label.containerss span.text {
  font-weight: 600;
}
.checkout-page .inquiry-form .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkout-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.checkout-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(img/inner-page/icon/checkbox-check.svg);
  left: 1.5px;
  top: -3px;
  position: absolute;
}
.checkout-page .inquiry-form .form-inner label.containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
}
.checkout-page .inquiry-form .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.checkout-page .inquiry-form .form-inner label.containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.checkout-page .inquiry-form .form-inner label.containerss .stars li {
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label.containerss .stars li i {
  color: #DDA701;
  font-size: 13px;
}
.checkout-page .inquiry-form .form-inner label.containerss .stars li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.checkout-page .inquiry-form .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 10px 20px;
}
.checkout-page .inquiry-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  height: 48px;
  width: 100%;
  padding: 20px 20px;
  outline: none;
  border: none;
  min-height: 100px;
  line-height: 1.6;
}
.checkout-page .inquiry-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.checkout-page .inquiry-form .form-inner .primary-btn3 {
  padding: 17px 28px;
  justify-content: center;
  width: 100%;
}
.checkout-page .cart-menu {
  min-height: 10rem;
  z-index: 99;
  transform-origin: top;
}
.checkout-page .cart-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 50px;
}
.checkout-page .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.checkout-page .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
.checkout-page .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  width: 100%;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.5s;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.5s;
}
.checkout-page .cart-body ul .single-item .item-area .main-item {
  display: flex;
  gap: 15px;
  width: 100%;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 60px;
  max-width: 60px;
  min-width: 60px;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content-and-quantity {
  width: 100%;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--title-color);
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #F3F3F3;
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
.checkout-page .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}
.checkout-page .cart-footer .pricing-area {
  width: 100%;
}
.checkout-page .cart-footer .pricing-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 10px 0px 10px;
}
.checkout-page .cart-footer .pricing-area ul:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 30px;
}
.checkout-page .cart-footer .pricing-area ul li {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.checkout-page .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
.checkout-page .choose-payment-mathord {
  margin-bottom: 40px;
  line-height: 1;
}
.checkout-page .choose-payment-mathord h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
}
.checkout-page .choose-payment-mathord .payment-option {
  line-height: 1;
}
.checkout-page .choose-payment-mathord .payment-option ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.checkout-page .choose-payment-mathord .payment-option ul li {
  width: 110px;
  height: 68px;
  background-color: var(--white-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkout-page .choose-payment-mathord .payment-option ul li .checked {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: #D2E7EF;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
          clip-path: polygon(0 0, 0% 100%, 100% 0);
  border-top-left-radius: 5px;
}
.checkout-page .choose-payment-mathord .payment-option ul li .checked i {
  opacity: 0;
  color: var(--title-color);
  margin-left: 2px;
}
.checkout-page .choose-payment-mathord .payment-option ul li.active .checked {
  background: var(--primary-color1);
}
.checkout-page .choose-payment-mathord .payment-option ul li.active .checked i {
  opacity: 1;
}

.recent-car-parts-area {
  background: #F6F4Fa;
}

.faq-page-wrap .faq-area .section-title-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .faq-page-wrap .faq-area .section-title-and-filter {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.faq-page-wrap .faq-area .section-title-and-filter .section-title h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 6px 6px 20px;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra h6 {
  margin-bottom: 0;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 500;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra .nice-select {
  height: 30px;
  background-color: var(--primary-color1);
  padding-left: 15px;
  padding-right: 35px;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra .nice-select::after {
  border-color: var(--title-color);
  right: 18px;
  height: 7px;
  width: 7px;
}
.faq-page-wrap .faq-area .section-title-and-filter .filter-atra .nice-select .current {
  color: var(--title-color);
  font-weight: 600;
  font-size: 13px;
}
.faq-page-wrap .faq-wrap {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, 0.61);
  padding: 20px;
}
@media (max-width: 576px) {
  .faq-page-wrap .faq-wrap {
    padding: 10px 10px;
  }
}

.faq-inquiery-form {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.faq-inquiery-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.faq-inquiery-form .form-inner input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
}
.faq-inquiery-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner input:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.faq-inquiery-form .form-inner textarea {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  min-height: 110px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
  padding: 10px 20px;
  width: 100%;
  height: 220px;
  outline: none;
}
.faq-inquiery-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.faq-inquiery-form .form-inner textarea:focus {
  border: 1px solid #D7B65D;
  background: #FFF;
}
.faq-inquiery-form .select-category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
}
.faq-inquiery-form .select-category .form-check {
  min-height: unset;
  margin-bottom: 0;
  line-height: 1;
  display: inline-flex;
  align-items: end;
  gap: 6px;
  cursor: pointer;
  margin-right: 15px;
}
.faq-inquiery-form .select-category .form-check:last-child {
  margin-right: 0;
}
.faq-inquiery-form .select-category label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}
.faq-inquiery-form .select-category .form-check-input {
  position: relative;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-color: rgba(19, 20, 26, 0.4);
  border-radius: 50%;
}
.faq-inquiery-form .select-category .form-check-input:focus {
  box-shadow: none;
}
.faq-inquiery-form .select-category .form-check-input::after {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #EEE;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.faq-inquiery-form .select-category .form-check-input:checked[type=radio] {
  background-image: none;
  border-color: var(--primary-color1);
}
.faq-inquiery-form .select-category .form-check-input:checked[type=radio]::after {
  background-color: var(--primary-color1);
}
.faq-inquiery-form .primary-btn3 {
  padding: 10px 35px;
}

.contact-page .single-location {
  border-radius: 10px;
  border: 1px solid #EEE;
  padding: 40px;
}
@media (max-width: 767px) {
  .contact-page .single-location {
    padding: 40px 25px;
  }
}
.contact-page .single-location .title-and-view-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.contact-page .single-location .title-and-view-btn h5 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}
.contact-page .single-location .title-and-view-btn a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 12px;
  font-weight: 600;
  transition: 0.35s;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}
.contact-page .single-location .title-and-view-btn a svg {
  fill: var(--title-color);
  transition: 0.35s;
}
.contact-page .single-location .title-and-view-btn a:hover {
  color: var(--primary-color1);
}
.contact-page .single-location .title-and-view-btn a:hover svg {
  fill: var(--primary-color1);
}
.contact-page .single-location ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contact-page .single-location ul li {
  display: flex;
  align-items: center;
  gap: 14px;
  line-height: 1;
  margin-bottom: 10px;
  transition: 0.35s;
}
.contact-page .single-location ul li:last-child {
  margin-bottom: 0;
}
.contact-page .single-location ul li .icon {
  max-width: 34px;
  min-width: 34px;
  height: 34px;
  border: 1px solid #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.contact-page .single-location ul li .icon i {
  transition: 0.35s;
  color: var(--primary-color1);
}
.contact-page .single-location ul li .info {
  line-height: 1;
}
.contact-page .single-location ul li .info a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  display: block;
}
.contact-page .single-location ul li .info a:hover {
  color: var(--primary-color1);
}
.contact-page .single-location ul li:hover .icon {
  background-color: var(--primary-color1);
}
.contact-page .single-location ul li:hover .icon i {
  color: var(--title-color);
}
.contact-page .single-contact {
  position: relative;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 40px;
}
.contact-page .single-contact:first-child {
  margin-top: 0;
}
.contact-page .single-contact .title {
  line-height: 1;
  background-color: var(--white-color);
  position: absolute;
  left: 35px;
  top: -9px;
  padding: 0 20px;
}
.contact-page .single-contact .title h6 {
  margin-bottom: 0;
  line-height: 1;
  color: #5E5E5E;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contact-page .single-contact .icon {
  min-width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-page .single-contact .icon i {
  color: var(--title-color);
}
.contact-page .single-contact .content {
  line-height: 1;
}
.contact-page .single-contact .content span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}
.contact-page .single-contact .content h6 {
  margin-bottom: 0;
}
.contact-page .single-contact .content h6 a {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  transition: 0.35s;
}
.contact-page .single-contact .content h6 a:hover {
  color: var(--primary-color1);
}
.contact-page .service-available {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding-top: 15px;
}
.contact-page .service-available span {
  color: #FF4820;
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}
.contact-page .service-available p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-page .service-available p strong {
  font-weight: 600;
}
.contact-page .section-title h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 700;
}
.contact-page .inquiry-form {
  border-radius: 10px;
  background: #F6F4Fa;
  padding: 45px 35px 50px;
}
@media (max-width: 991px) {
  .contact-page .inquiry-form {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .contact-page .inquiry-form {
    padding: 45px 20px 50px;
  }
}
.contact-page .inquiry-form .title {
  margin-bottom: 25px;
}
.contact-page .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 700;
}
.contact-page .inquiry-form .title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-page .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.contact-page .inquiry-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.contact-page .inquiry-form .form-inner label span {
  color: var(--text-color);
}
.contact-page .inquiry-form .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.contact-page .inquiry-form .form-inner label.containerss span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1;
}
.contact-page .inquiry-form .form-inner label.containerss span.text {
  font-weight: 600;
}
.contact-page .inquiry-form .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.contact-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.contact-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(img/inner-page/icon/checkbox-check.svg);
  left: 1.5px;
  top: -3px;
  position: absolute;
}
.contact-page .inquiry-form .form-inner label.containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
}
.contact-page .inquiry-form .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.contact-page .inquiry-form .form-inner label.containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.contact-page .inquiry-form .form-inner label.containerss .stars li {
  line-height: 1;
}
.contact-page .inquiry-form .form-inner label.containerss .stars li i {
  color: #DDA701;
  font-size: 13px;
}
.contact-page .inquiry-form .form-inner label.containerss .stars li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
}
.contact-page .inquiry-form .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
}
.contact-page .inquiry-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  width: 100%;
  padding: 20px 20px;
  outline: none;
  border: none;
  min-height: 100px;
}
.contact-page .inquiry-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.contact-page .inquiry-form .form-inner .primary-btn3 {
  padding: 17px 28px;
  justify-content: center;
}

.contact-map {
  line-height: 0;
}
.contact-map iframe {
  width: 100%;
  height: 450px;
}

.error-page {
  background-color: #F6F4Fa;
  padding: 165px 0;
}
@media (max-width: 576px) {
  .error-page {
    padding: 130px 0;
  }
}
.error-page .error-wrapper {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}
.error-page .error-wrapper .error-content-area h1 {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 12px;
}
.error-page .error-wrapper .error-content-area p {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 35px;
}

.customer-feedback-pages .feedback-card {
  padding: 40px 30px;
  background: #F6F4Fa;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.35s;
}
.customer-feedback-pages .feedback-card .feedback-top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 15px;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area {
  display: flex;
  flex-direction: column;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area ul li {
  line-height: 1;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area ul li i {
  font-style: 13px;
  color: #DDA701;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area img {
  margin-bottom: 5px;
}
.customer-feedback-pages .feedback-card .feedback-top .stat-area span {
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
}
.customer-feedback-pages .feedback-card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-color);
}
.customer-feedback-pages .feedback-card .author-name {
  display: flex;
  align-items: center;
  gap: 6px;
}
.customer-feedback-pages .feedback-card .author-name h6 {
  margin-bottom: 0;
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-color);
  padding-left: 30px;
  position: relative;
}
.customer-feedback-pages .feedback-card .author-name h6::after {
  content: "";
  height: 1px;
  width: 20px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.customer-feedback-pages .feedback-card .author-name span {
  color: var(--text-color);
  font-size: 12px;
  font-family: var(--font-open-sans);
  font-weight: 600;
}
.customer-feedback-pages .feedback-card:hover {
  border-color: var(--primary-color1);
}
.customer-feedback-pages .primary-btn3 {
  padding: 10px 45px;
}

.return-and-exchange-pages .update-date {
  display: flex;
  align-items: center;
  gap: 48px;
  line-height: 1;
}
.return-and-exchange-pages .update-date h6 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  position: relative;
}
.return-and-exchange-pages .update-date h6 i {
  color: var(--primary-color1);
}
.return-and-exchange-pages .update-date h6::after {
  content: "";
  width: 28px;
  height: 1px;
  background-color: var(--title-color);
  position: absolute;
  right: -38px;
  top: 50%;
  transform: translateY(-50%);
}
.return-and-exchange-pages .update-date p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.return-and-exchange-pages .return-and-exchange h4 {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
}
.return-and-exchange-pages .return-and-exchange p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.return-and-exchange-pages .return-and-exchange p:last-child {
  margin-bottom: 0;
}
.return-and-exchange-pages .return-and-exchange ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 50px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .return-and-exchange-pages .return-and-exchange ul {
    padding-left: 20px;
  }
}
.return-and-exchange-pages .return-and-exchange ul li {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 15px;
}
.return-and-exchange-pages .return-and-exchange ul li strong {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

.range-wrapper2 .slider-wrapper {
  margin-bottom: 25px;
}
.range-wrapper2 .slider-wrapper .ui-slider-horizontal {
  height: 10px;
  border-radius: 10px;
}
.range-wrapper2 .slider-wrapper .ui-widget.ui-widget-content {
  border: none;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
}
.range-wrapper2 .slider-wrapper .ui-slider-range {
  background-color: var(--primary-color1);
}
.range-wrapper2 .slider-wrapper .ui-slider-handle {
  border: 5px solid var(--primary-color1);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background-color: var(--white-color);
  top: -7px;
  display: inline-block;
  position: relative;
}
.range-wrapper2 .slider-wrapper .ui-slider-handle:focus-visible {
  outline: none;
}
.range-wrapper2 .valus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range-wrapper2 .valus .min-value,
.range-wrapper2 .valus .max-value {
  max-width: 48%;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #F3F3F3;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
}
.range-wrapper2 .valus .min-value span,
.range-wrapper2 .valus .max-value span {
  line-height: 1;
  display: flex;
  margin-top: 3px;
}
.range-wrapper2 .valus .min-value input,
.range-wrapper2 .valus .max-value input {
  width: 100%;
  background-color: transparent;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.range-wrapper2 .valus .min-value input::-moz-placeholder, .range-wrapper2 .valus .max-value input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.range-wrapper2 .valus .min-value input::placeholder,
.range-wrapper2 .valus .max-value input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}

.review-top .logo {
  max-width: 100%;
}

.customer-feedback-pages .feedback-card .feedback-top .stat-area ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.customer-feedback-pages .feedback-card .feedback-top .stat-area ul.trustpilot li i {
  color: #fff;
  font-size: 12px;
}

.home3-testimonial-area .testimonial-wrap .review .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
  justify-content: center;
}

.home3-testimonial-area .testimonial-wrap .review .star ul li {
  line-height: 1;
}

.home3-testimonial-area .testimonial-wrap .review .star ul li i {
  color: #DDA701;
}

.home3-testimonial-area .trustpilot-review .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
  justify-content: center;
}

.home3-testimonial-area .trustpilot-review .star ul.trustpilot {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}

.home3-testimonial-area .trustpilot-review .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home3-testimonial-area .trustpilot-review .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home3-testimonial-area .trustpilot-review .star ul li {
  line-height: 1;
}

.home3-testimonial-area .trustpilot-review .star ul li i {
  color: #DDA701;
}

.home3-testimonial-area .testimonial-wrap .review .star ul.trustpilot {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}

.home3-testimonial-area .testimonial-wrap .review .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home3-testimonial-area .testimonial-wrap .review .star ul.trustpilot li i {
  color: #fff;
  font-size: 12px;
}

.home2-testimonial-section .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home2-testimonial-section .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home2-testimonial-section .feedback-card .feedback-top .stat-area ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home2-testimonial-section .feedback-card .feedback-top .stat-area ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .stat-area ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}

.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  color: #DDA701;
}

.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.customar-feedback-area .customer-feedback-left .google .star ul li {
  line-height: 1;
}

.customar-feedback-area .customer-feedback-left .google .star ul li i {
  color: #DDA701;
}

.customar-feedback-area .customer-feedback-left .google .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.customar-feedback-area .customer-feedback-left .google .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.customar-feedback-area .customer-feedback-left .google .star ul.trustpilot {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}

.customar-feedback-area .customer-feedback-left .google h5 {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--title-color);
  margin-bottom: 10px;
}

.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}

.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  color: #DDA701;
}

.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}

.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  color: #DDA701;
}

.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul li {
  line-height: 1;
}

.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul li i {
  color: #DDA701;
}

.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.banner-section1 .banner-content .customar-review > ul > li a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .star ul li {
  line-height: 1;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .star ul li i {
  color: #DDA701;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .star ul.trustpilot {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul li {
  line-height: 1;
}

.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul li i {
  color: #DDA701;
}

.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.trustpilot-review img {
  max-width: 100px;
  height: auto;
}

.why-choose-area .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.why-choose-area .rating a .review-top .star ul li {
  line-height: 1;
}

.why-choose-area .rating a .review-top .star ul li i {
  color: #DDA701;
}

.why-choose-area .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.why-choose-area .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.why-choose-area.two .rating a .review-top .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.why-choose-area.two .rating a .review-top .star ul li {
  line-height: 1;
}

.why-choose-area.two .rating a .review-top .star ul li i {
  color: #DDA701;
}

.why-choose-area.two .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.why-choose-area.two .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.customar-feedback-area .partner-slider .marquee_text2 .js-marquee img {
  height: auto;
  max-width: 100%;
  border-radius: 0;
  box-shadow: none;
}

.banner-section1 .banner-content .customar-review > ul > li a .review-top .logo img {
  max-width: 200px;
  width: 100%;
}

.banner-section2 .banner-content .banner-content-bottom .rating a .review-top .logo img {
  max-width: 200px;
  width: 100%;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review .img {
  max-width: 200px;
  width: 100%;
}

.home4-banner-area .banner-content .banner-content-bottom .rating a .review-top .logo img {
  max-width: 200px;
  width: 100%;
}

.home5-banner-area .banner-content .banner-content-bottom .rating a .review-top .logo img {
  max-width: 200px;
  width: 100%;
}

.home6-banner-area .banner-content .banner-content-bottom .rating a .review-top .logo {
  max-width: 200px;
  width: 100%;
}

.why-choose-area.two .rating a .review-top .logo img {
  max-width: 200px;
  width: 100%;
}

.welcome-banner-section .welcome-wrap .author-area img {
  /* max-width: 200px; */
  width: 100%;
}

.recommended-car-section .recommended-left .think-img img {
  max-width: 300px;
  width: 100%;
}

.customar-feedback-area .partner-slider .marquee_text2 .js-marquee img {
  max-width: 300px;
  width: 100%;
}

.home5-why-drivco-area .drivco-img img {
  max-width: 100%;
}

.customar-feedback-area .customer-feedback-right .feedback-card .feedback-top .logo img {
  max-width: 200px;
  width: 100%;
}

.home2-testimonial-section .rating a .review-top .logo img {
  max-width: 200px;
  width: 100%;
}

.customar-feedback-area .customer-feedback-left img {
  max-width: 200px;
  width: 100%;
}

.how-it-work-section .trustpilot-review .star ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
  justify-content: center;
}

.how-it-work-section .trustpilot-review .star ul.trustpilot {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 7px;
  margin-bottom: 5px;
}

.how-it-work-section .trustpilot-review .star ul li {
  line-height: 1;
}

.how-it-work-section .trustpilot-review .star ul li i {
  color: #DDA701;
}

.how-it-work-section .trustpilot-review .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.how-it-work-section .trustpilot-review .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.how-it-work-section .trustpilot-review img {
  max-width: 200px;
}

.why-choose-area .trustpilot-review img {
  max-width: 200px;
}

.why-choose-area .trustpilot-review ul li {
  line-height: 1;
}

.why-choose-area .trustpilot-review ul li i {
  color: #DDA701;
}

.why-choose-area .trustpilot-review ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.why-choose-area .trustpilot-review ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.why-choose-area .trustpilot-review ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.home3-testimonial-area .trustpilot-review img {
  max-width: 200px;
}

.home3-banner-area .banner-wrapper .banner-content .trustpilot-review img {
  max-width: 200px;
}

.home5-testimonial-section .rating a .review-top .logo img {
  max-width: 200px;
}

.home5-testimonial-section .rating a .review-top .star ul li {
  line-height: 1;
}

.home5-testimonial-section .rating a .review-top .star ul li i {
  color: #DDA701;
}

.home5-testimonial-section .rating a .review-top .star ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home5-testimonial-section .rating a .review-top .star ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home5-testimonial-section .feedback-card .feedback-top .stat-area ul li {
  line-height: 1;
}

.home5-testimonial-section .feedback-card .feedback-top .stat-area ul li i {
  color: #DDA701;
}

.home5-testimonial-section .feedback-card .feedback-top .stat-area ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home5-testimonial-section .feedback-card .feedback-top .stat-area ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home5-testimonial-section .trustpilot-review ul li {
  line-height: 1;
}

.home5-testimonial-section .trustpilot-review ul li i {
  color: #DDA701;
}

.home5-testimonial-section .trustpilot-review ul.trustpilot li {
  background-color: #D7B65D;
  padding: 4px;
  line-height: 12px;
}

.home5-testimonial-section .trustpilot-review ul.trustpilot li i {
  color: #fff;
  font-size: 10px;
}

.home5-testimonial-section .trustpilot-review img {
  max-width: 200px;
}

.home5-testimonial-section .trustpilot-review ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.loaded {
  position: absolute;
  content: url(ajax-loader.gif);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.swiper-slide {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.hidden {
  overflow: hidden;
  display: none;
  visibility: hidden;
}

@media (min-width: 992px) {
  header .main-menu ul > li.has-mega-menu {
    position: relative;
  }
  header .main-menu ul > li.has-mega-menu i {
    display: none !important;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-4 > li > a,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-6 > li > a,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-3 > li > a {
    color: var(--title-color) !important;
    font-family: var(--font-montserrat) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    text-transform: capitalize !important;
    border-bottom: none !important;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-4 > li > a::before,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-6 > li > a::before,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-3 > li > a::before {
    display: none;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-4 li .sub-menu,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-6 li .sub-menu,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-3 li .sub-menu {
    display: block;
    position: static;
    max-width: 100%;
    background: transparent !important;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-4 li .sub-menu li a,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-6 li .sub-menu li a,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-3 li .sub-menu li a {
    border: none !important;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-4 li .sub-menu li a::before,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-6 li .sub-menu li a::before,
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-3 li .sub-menu li a::before {
    display: none !important;
  }
  header .main-menu ul > li.has-mega-menu:hover ul.sub-menu.row.mega-col-4 {
    display: flex;
    width: 800px;
    min-height: 200px;
    left: -75px;
  }
  header .main-menu ul > li.has-mega-menu:hover ul.sub-menu.row.mega-col-6 {
    display: flex;
    width: 550px;
    min-height: 200px;
    left: -75px;
  }
  header .main-menu ul > li.has-mega-menu:hover ul.sub-menu.row.mega-col-3 {
    display: flex;
    width: 950px;
    min-height: 200px;
    left: -75px;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-4 li:hover ul.sub-menu {
    animation: none;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-6 li:hover ul.sub-menu {
    animation: none;
  }
  header .main-menu ul > li.has-mega-menu ul.sub-menu.row.mega-col-3 li:hover ul.sub-menu {
    animation: none;
  }
}
@media (max-width: 991px) {
  ul.menu-list li.has-mega-menu ul.sub-menu li.col-4,
  ul.menu-list li.has-mega-menu ul.sub-menu li.col-3,
  ul.menu-list li.has-mega-menu ul.sub-menu li.col-6 {
    width: 100%;
  }
}
.sidebar-menu ul li.has-mega-menu ul.sub-menu li.col-4,
.sidebar-menu ul li.has-mega-menu ul.sub-menu li.col-3,
.sidebar-menu ul li.has-mega-menu ul.sub-menu li.col-6 {
  width: 100%;
}

.auction-customer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
}
.auction-customer .registration-form,
.auction-customer .login-form {
  width: 50%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.auction-customer .registration-form .title,
.auction-customer .login-form .title {
  color: #000;
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.auction-customer .registration-form label,
.auction-customer .login-form label {
  display: block;
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.auction-customer .registration-form input,
.auction-customer .login-form input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 20px;
}
.auction-customer .registration-form input[type=submit],
.auction-customer .login-form input[type=submit] {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-open-sans);
  font-weight: 600;
  font-size: 15px;
  color: rgba(19,20,26,0.9);
  white-space: nowrap;
  padding: 10px 20px;
  display: inline-flex;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  margin-bottom: 0;
  margin-top: 20px;
}
.auction-customer .registration-form .login-remember input,
.auction-customer .login-form .login-remember input {
  width: auto !important;
  height: auto !important;
  margin-right: 5px;
  margin-bottom: 0;
}

.auction-dashboard-layout .nav-pills {
  width: 25%;
}
@media (max-width: 767px) {
  .auction-dashboard-layout .nav-pills {
    width: 100%;
  }
}
.auction-dashboard-layout .nav-pills .nav-link {
  background-color: rgba(70, 217, 147, 0.3);
  margin-bottom: 10px;
  border-left: 5px solid #000;
  padding: 10px;
  text-align: left;
  display: block;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 3px;
}
.auction-dashboard-layout .nav-pills .nav-link.active {
  color: #fff;
  border-radius: 3px;
  border-left: 5px solid var(--primary-color1);
  background-color: #000;
}
.auction-dashboard-layout .tab-content {
  width: calc(75% - 3rem);
}
@media (max-width: 767px) {
  .auction-dashboard-layout .tab-content {
    width: 100%;
  }
}
.auction-dashboard-layout .tab-content .profile-data {
  padding: 30px;
  background: rgba(0, 0, 0, 0.03);
}
.auction-dashboard-layout .tab-content .profile-data form label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.auction-dashboard-layout .tab-content .profile-data form input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  padding: 10px 20px;
  border: none;
}

.bidding-modal {
  display: flex;
  align-items: start;
  gap: 10px;
}
@media (max-width: 576px) {
  .bidding-modal {
    display: block;
  }
}

@media (max-width: 576px) {
  .login-form {
    margin-top: 50px;
  }
}

.registration-form,
.login-form {
  width: 100%;
}
@media (max-width: 576px) {
  .registration-form,
  .login-form {
    width: 100%;
  }
}
.registration-form .title,
.login-form .title {
  text-align: center;
  margin-bottom: 30px;
}
.registration-form form label,
.login-form form label {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.registration-form form input,
.login-form form input {
  border-radius: 5px;
  border: 1px solid #EEE;
  background: #FBF7FB;
  height: 50px;
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 20px;
}
.registration-form form button,
.login-form form button {
  width: 100%;
  justify-content: center;
}

.car-details-sidebar .auction-time {
  color: rgba(19,20,26,0.9);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}
.car-details-sidebar .auction-time .countdown_row {
  clear: both;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  text-align: center;
}
.car-details-sidebar .auction-time .countdown_row .countdown_section {
  border-radius: 5px;
  border: 1px solid rgba(238, 238, 238, 0.4);
  background: #F6F4Fa;
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-montserrat);
  font-size: 11px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
  padding: 10px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .car-details-sidebar .auction-time .countdown_row .countdown_section {
    font-size: 10px;
  }
}
.car-details-sidebar .auction-time .countdown_row .countdown_amount {
  color: var(--title-color);
  font-family: var(--font-montserrat);
  font-weight: 700;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .car-details-sidebar .auction-time .countdown_row .countdown_amount {
    font-size: 20px;
  }
}
.car-details-sidebar .auction-time .countdown_show1 .countdown_section {
  width: 98%;
}
.car-details-sidebar .auction-time .countdown_show2 .countdown_section {
  width: 48% !important;
}
.car-details-sidebar .auction-time .countdown_show3 .countdown_section {
  width: 32.5% !important;
}
.car-details-sidebar .auction-time .countdown_show4 .countdown_section {
  width: 24.5% !important;
}
.car-details-sidebar .auction-time .countdown_show5 .countdown_section {
  width: 19.5% !important;
}
.car-details-sidebar .auction-time .countdown_show6 .countdown_section {
  width: 16.25% !important;
}
.car-details-sidebar .auction-time .countdown_show7 .countdown_section {
  width: 14% !important;
}
.car-details-sidebar .product.woocommerce.add_to_cart_inline {
  padding: 10px 30px !important;
  margin: 15px 0;
  border: 1px solid #ddd !important;
  display: flex;
  align-items: center;
  gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  justify-content: space-between;
}
.car-details-sidebar .auction_form.cart {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.car-details-sidebar .auction_form.cart .quantity.buttons_added {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.car-details-sidebar .auction_form.cart .quantity.buttons_added input {
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ececec;
  padding: 10px 20px;
  font-family: var(--font-montserrat);
}
.car-details-sidebar .auction_form.cart .quantity.buttons_added .plus,
.car-details-sidebar .auction_form.cart .quantity.buttons_added .minus {
  width: 100px !important;
  height: 100%;
  font-size: 26px;
  transition: 0.35s;
  border-radius: 5px;
  width: 50px;
  padding: 10px;
}
.car-details-sidebar .auction_form.cart .quantity.buttons_added .plus:hover,
.car-details-sidebar .auction_form.cart .quantity.buttons_added .minus:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.car-details-sidebar .auction_form.cart .bid_button.button {
  min-width: 120px;
  background-color: var(--primary-color1);
}
.car-details-sidebar .auction-price {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.car-details-sidebar .auction-price::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  max-width: 70px;
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background: radial-gradient(50% 50% at 50% 50%, rgba(19,20,26,0.9) 0%, rgba(19, 20, 26, 0) 100%);
}
.car-details-sidebar .auction-price > span {
  color: rgba(19,20,26,0.9);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
}
.car-details-sidebar .auction-price .woocommerce-Price-amount.amount {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1;
}
.car-details-sidebar .auction-end {
  color: rgba(19,20,26,0.9);
  font-family: var(--font-montserrat);
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}
.car-details-sidebar .auction-end span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1;
}
.car-details-sidebar .auction-condition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(19,20,26,0.9);
  font-family: var(--font-montserrat);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.car-details-sidebar .auction-condition span {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 48px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 400;
  line-height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: var(--text-color);
  font-weight: 400;
}/*# sourceMappingURL=style.css.map */